import React from 'react';
import { userService } from '../_services/user.service';
import { history, locKeyStatic } from '../_helpers'
import { connect } from 'react-redux'
import { userConstants } from '../_constants/user.constants'
import { Link } from 'react-router-dom'
import { LoginButton } from '../_components/BasicElements';
import './login.scss'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rememberMe: localStorage.getItem('rememberMe') === 'true' && localStorage.getItem('email'),
            email: localStorage.getItem('email') || '',
            password: '',
            code: '',
            submitted: false,
            error: false,
            lock: false,
            twoFactor: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value, checked } = e.target;
        if (name === "rememberMe") {
            localStorage.setItem('rememberMe', checked);
            this.setState({ [name]: checked });
        } else {
            this.setState({ [name]: value });
        }
    }

    selectFirstPage(user) {
        if (user.rights.indexOf('admin') !== -1 && user.rights.indexOf('userManagement') === -1) {
            history.push('/replies')
        } else {
            history.push('/')
        }
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email, password, rememberMe } = this.state;
        if (email && password) {
            userService.login(email, password).then((user) => {
                if (user.twoFactorEnabled === true) {
                    this.setState({ submitted: false, twoFactor: true })
                } else {
                    sessionStorage.clear()
                    localStorage.setItem('email', rememberMe ? email : '');
                    this.props.dispatch({ type: userConstants.LOGIN_SUCCESS, user })
                    this.selectFirstPage(user)
                }
            }).catch((err) => {
                if (err === 429) {
                    this.setState({ lock: true, error: false })
                } else {
                    this.setState({ error: true, lock: false })
                }
            })
        }
    }

    handleSecondFactorSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { code, email, rememberMe } = this.state;
        if (code) {
            userService.twoFactorCheck(code).then(() => {
                sessionStorage.clear()
                localStorage.setItem('email', rememberMe ? email : '');
                userService.getUser().then((user) => {
                    this.props.dispatch({ type: userConstants.LOGIN_SUCCESS, user })
                    this.selectFirstPage(user)
                })
            }).catch(() => {
                window.location.reload()
            })
        }
    }

    render() {
        const { email, password, submitted, error, lock,
            rememberMe, code, twoFactor } = this.state;
        return (
            <div className="Screen">
                <div className="Background">
                    <h2>{locKeyStatic("login")}</h2>
                    <img className="Logo" src="images/cauhacaresolutions.png" alt="Cauhacare solutions logo"></img>
                    {twoFactor ?
                        <form name="form" onSubmit={this.handleSecondFactorSubmit.bind(this)}>
                            <div className={'form-group'}>
                                <label htmlFor="code">{locKeyStatic("changingCode")}</label>
                                <input id="code" autoFocus type="text" className="form-control" name="code" value={code} onChange={this.handleChange} />
                            </div>
                            <LoginButton title={locKeyStatic("login")} />
                        </form> :
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                                <label htmlFor="email">{locKeyStatic("email")}</label>
                                <input id="email" type="email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                                {submitted && !email &&
                                    <div className="Help">{locKeyStatic("inputEmail")}</div>
                                }
                            </div>
                            <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password">{locKeyStatic("password")}</label>
                                <input id="password" type="password" className="form-control" name="password" value={password} onChange={this.handleChange} />
                                <div className="RememberMe">
                                    <input className="Checkbox" type="checkbox" id="rememberMe"
                                        name="rememberMe" checked={rememberMe} onChange={this.handleChange} />
                                    <label htmlFor="rememberMe">{locKeyStatic("rememberMe")}</label>
                                </div>
                                {submitted && !password &&
                                    <div className="Help">{locKeyStatic("inputPassword")}</div>
                                }
                                {error &&
                                    <div className="Error">{locKeyStatic("loginFailed")}</div>
                                }
                                {lock &&
                                    <div className="Error">{locKeyStatic("userLocked")}</div>
                                }
                            </div>
                            <div className="Controls">
                                <Link className="ResetPasswordLink" to="/sendpasswordreset">{locKeyStatic("forgotPassword")}</Link>
                                <LoginButton title={locKeyStatic("login")} />
                            </div>
                        </form>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage }; 