import * as React from 'karet';
import * as U from 'karet.util';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import './DropDown.scss';

export const DropDown = (props) => {
    const open = U.atom(props.initial)
    return (<React.Fragment>
        <div className={props.className ? props.className : 'DropDown'}
            style={props.style}
            onClick={() => open.set(!open.get())}>
            {U.mapValue((openValue) => openValue ?
                <FaMinusCircle className="Open" /> :
                <FaPlusCircle className="Open" />, open)}
            {props.title}
        </div>
        <div className={props.className ? props.className + "Pane" : "DropDownPane"}>
            {U.mapValue((openValue) => {
                return openValue ? props.children : ''
            }, open)}
        </div>
    </React.Fragment>)
}

export const DropDownTitle = (props) => {
    const open = U.atom(props.initial)
    return (<React.Fragment>
        <div className={props.className ? props.className : 'DropDown'}
            style={props.style}
            onClick={() => open.set(!open.get())}>
            <div className="Title">{props.title}</div>
        </div>
        <div className={props.className ? props.className + "Pane" : "DropDownPane"}>
            {U.mapValue((openValue) => {
                return openValue ? props.children : ''
            }, open)}
        </div>
    </React.Fragment>)
}
