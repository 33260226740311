import * as React from 'karet'
import * as U from 'karet.util'
import { clone } from '../_helpers/ui'

const setOption = (checked, options, value) => {
    const optionsValue = clone(options.get())
    if (optionsValue.indexOf(value) !== -1 && !checked) {
        optionsValue.splice(optionsValue.indexOf(value), 1)
    } else if (optionsValue.indexOf(value) === -1 && checked) {
        optionsValue.push(value)
    }
    options.set(optionsValue)
}

export const Checkbox = ({ selectedOptions, value }) => {
    return (<input type='checkbox'
        checked={U.mapValue((options) => options.indexOf(value) !== -1, selectedOptions)}
        onChange={(evt) => setOption(evt.target.checked, selectedOptions, value)} />)
}

export const BooleanCheckbox = ({ value }) => {
    return (<input type='checkbox'
        checked={U.mapValue((value) => value, value)}
        onChange={(evt) => evt.target.checked ? value.set(true) : value.set(false) }/>)
}