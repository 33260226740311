import { apiUrl } from './common';

function getUserItems(organization, start, count) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ start, count })
    };
    return fetch(`${apiUrl}/useritem/organization/${organization}`, requestOptions)
        .then(handleResponse)
}

function updateUserItem(userItemValue) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(userItemValue)
    };
    return fetch(`${apiUrl}/useritem/${userItemValue.id}`, requestOptions)
        .then(handleResponse)
}

function deleteUserItem(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/useritem/${id}`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = (text && text.length > 0) && JSON.parse(text);
        if (response.status !== 200) {
            throw response.status
        }
        return data;
    });
}

export const userItemService = {
    getUserItems,
    updateUserItem,
    deleteUserItem
};
