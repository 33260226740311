import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { TextEdit } from './TextEdit';
import { libraryService } from '../_services/library.service'
import { pushAlert } from '../_reducers/alert.reducer';
import { LanguageTextEdit } from './LanguageTextEdit'
import { EditableTextField } from './EditableTextField'
import { OpenToggle } from './OpenToggle'
import { ReplyEditor } from './ReplyEditor';
import { TaskEditor } from './editors/TaskEditor';
import { NormMaterialEditor } from './editors/NormMaterialEditor';
import { FaTrash, FaTasks, FaQuestionCircle } from 'react-icons/fa';
import { showYesNoDialog } from '../_ui/Dialog';
import { CircleSelector } from './CircleSelector';
import { ActionButton, ActionButtonWide } from './BasicElements';
import { locKeyStatic, getDefaultLanguage, locTextDyn } from '../_helpers/ui';
import "./CircleList.scss"

const {v4: uuidv4} = require('uuid')

const createCircle = (organization, key, parent, circles) => {
    libraryService.createCircle(organization.id, uuidv4(), key, parent).then((response) => {
        circles.modify(R.append(response))
    }).catch(() => {
        pushAlert(locKeyStatic("creationFailed"), 3000)
    })
}

const deleteCircle = (circle) => {
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: locKeyStatic("conceptRemovalConfirmation", { circle: circle.get().key }),
        parameter: circle,
        onYes: () => {
            libraryService.removeCircle(circle.get().uuid).then(() => {
                circle.remove()
            }).catch(() => {
                pushAlert(locKeyStatic("removeFailed"), 3000)
            })
        }
    })
}

const deleteLibraryItem = (item, libraryItems) => {
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: locKeyStatic("areYouSureRemoveQuestion"),
        parameter: item,
        onYes: () => {
            const uuid = item.get().uuid
            libraryService.removeLibraryItem(uuid).then(() => {
                const items = libraryItems.get()
                libraryItems.set([])
                libraryItems.set(items.filter(c => c.uuid !== uuid))
            }).catch(() => {
                pushAlert(locKeyStatic("removeFailed"), 3000)
            })
        }
    })
}

const addQuestion = (organization, parentCircle, libraryItems) => {
    libraryService.createLibraryItem("Question", organization,
        {
            fi: "<kysymys>", en: "<question>", sv: "<fråga>", id: uuidv4(),
            reply: 'agreeNotAgreeSelect6', replyParameters: {}
        },
        parentCircle
    ).then((response) => {
        response.parentCircle = response.parentCircle.uuid

        const items = libraryItems.get()
        libraryItems.set([])
        libraryItems.set([response].concat(items))
    })
}

const addTraining = (organization, parentCircle, libraryItems) => {
    libraryService.createLibraryItem("Exercise", organization,
        { fi: "<harjoitus>", en: "<exercise>", sv: "<träning>", id: uuidv4(), type: "TaskList", items: [] },
        parentCircle
    ).then((response) => {
        response.parentCircle = response.parentCircle.uuid
        const items = libraryItems.get()
        libraryItems.set([])
        libraryItems.set([response].concat(items))
    })
}

const addNormData = (organization, parentCircle, libraryItems) => {
    libraryService.createLibraryItem("NormMaterial", organization,
        { fi: "<normiaineisto>", en: "<norm data>", sv: "<normative data>", id: uuidv4(), organizations: [] },
        parentCircle
    ).then((response) => {
        response.parentCircle = response.parentCircle.uuid
        const items = libraryItems.get()
        libraryItems.set([])
        libraryItems.set([response].concat(items))
    })
}

const renderLibraryItem = (item, libraryItems, circle, circles) => {
    item.skip(1).skipDuplicates().debounce(1000).observe((itemValue) => {
        if (itemValue) {
            libraryService.updateLibraryItem(itemValue).then(() => {
                if (itemValue.parentCircle !== circle.get().uuid) {
                    item.remove()
                }
            }).catch(() => {
                pushAlert(locKeyStatic("updatedFailed"), 300)
            })
        }
    })
    const description = U.view('description')(item)
    const state = U.view('state')(item)
    const type = U.view('type')(item)
    const childCircle = U.view('childCircle')(item)
    const parentCircle = U.view('parentCircle')(item)
    const organization = U.view('organization')(item)
    const open = U.atom(false)
    return (<div className="LibraryItem">
        <h4>
            <OpenToggle open={open} defaultOpen={false} />
            <span className="ItemType">
                {locTextDyn(description)}
                <sup>&nbsp;{U.mapValue((typeValue) => typeValue === 'Exercise' ?
                    <FaTasks /> :
                    typeValue === 'Question' ? <FaQuestionCircle /> : null, type)}</sup>
            </span>
            <FaTrash className="Remove" onClick={() => deleteLibraryItem(item, libraryItems)} />
        </h4>
        {U.mapValue(openValue => openValue ? <>
            {type.get() === 'Question' ? <>
                <LanguageTextEdit values={description} rows={2} />
                <ReplyEditor question={description} /></> :
                type.get() === 'TaskList' ?
                    <TaskEditor task={description}/> :
                    <NormMaterialEditor material={description} state={state} />}
            <div className="Title">{locKeyStatic("umbrellaTerm")}</div>
            <CircleSelector circle={parentCircle} circles={circles} organization={organization.get()} />
            {type.get() === 'Question' ? <>
                <div className="Title">{locKeyStatic("subConcept")}</div>
                <CircleSelector allowEmpty={true} circle={childCircle} circles={circles} organization={organization.get()} />
            </> : null}
        </> : null, open)}

    </div>)
}

const renderCircle = (organization, circle, circles, level) => {
    const open = U.atom(false)
    const circleId = U.view("uuid")(circle)
    const children = U.view("children")(circle)
    const newKey = U.atom(null)
    const libraryOpen = U.atom(false)
    const libraryItems = U.atom([])
    const searchText = U.atom("")
    const libraryChange = U.molecule({ libraryOpen, searchText })
    const showLoading = U.atom(true)
    libraryChange.debounce(1000).observe(({ libraryOpen, searchText }) => {
        showLoading.set(true)
        libraryItems.set([])
        if (libraryOpen) {
            libraryService.getCircleLibraryItems(circle.get().uuid).then((items) => {
                showLoading.set(false)
                libraryItems.set(items.filter(i =>
                    i.description[getDefaultLanguage()].toLowerCase().indexOf(searchText.toLowerCase()) !== -1))
            }).catch(() => showLoading.set(false))
        }
    })
    circle.skip(1).skipDuplicates().debounce(1000).observe((value) => {
        libraryService.updateCircle(value).then(() => {

        }).catch(() => {
            pushAlert(locKeyStatic("updateFailed"), 3000)
        })
    })
    return (<div className="Circle" key={circleId.get()} >
        <div className={`Heading Level${level}`}>
            <OpenToggle open={open} href="#open" defaultOpen={false} />
            <EditableTextField text={U.view("key")(circle)} />
            <FaTrash className="Remove" onClick={() => deleteCircle(circle)} />
        </div>
        {
            U.mapValue(openValue =>
                openValue ?
                    <div className="Content">
                        <h4>{locKeyStatic("description")}</h4>
                        <LanguageTextEdit values={U.view("description")(circle)} />
                        <div className={`Heading Level${level + 1}`}>
                            <OpenToggle open={libraryOpen} href="#open" defaultOpen={false} />
                            <span>{locKeyStatic("libraryText")}</span>
                        </div>
                        {U.mapValue((open) => open ? <div className="LibraryContent">
                            <h4>{locKeyStatic("questions")}</h4>
                            <div className="Search">
                                <div>{locKeyStatic("search")}</div>
                                <input type="text" value={searchText} className="Search"
                                    onChange={(evt) => searchText.set(evt.target.value)} />
                            </div>
                            <button className="NewQuestionButton" onClick={() =>
                                addQuestion(organization, circleId.get(), libraryItems)}>{locKeyStatic("addQuestion")}</button>
                            <button className="NewQuestionButton" onClick={() =>
                                addTraining(organization, circleId.get(), libraryItems)}>{locKeyStatic("addExercise")}</button>
                            <button className="NewQuestionButton" onClick={() =>
                                addNormData(organization, circleId.get(), libraryItems)}>{locKeyStatic("addNormData")}</button>
                            {U.mapValue(showValue => showValue ?
                                <div className="Loader" /> : null, showLoading)}
                            {U.mapElems((item) => renderLibraryItem(item, libraryItems, circle, circles), libraryItems)}
                        </div> : "", libraryOpen)}
                        {U.mapElems((child) => renderCircle(organization, child, circles, level + 1), children)}
                        <div className="NewCircle">
                            <TextEdit value={newKey} placeHolder={locKeyStatic("key")} />
                            <ActionButtonWide
                                onClick={() => createCircle(organization.get(),
                                    newKey.get(), circleId.get())}
                                title={locKeyStatic("addConcept")} />
                        </div>
                    </div> : '', open)
        }
    </div>)
}

export const CircleList = ({ organization }) => {
    const newKey = U.atom("")
    const circles = U.atom([])
    organization.observe((orgValue) => {
        if (orgValue) {
            libraryService.getOrganizationCircles(orgValue.id).then((circlesValue) => {
                circles.set(circlesValue)
            })
        }
    })

    return (<div className="CircleList">
        <div className="NewCircle">
            <TextEdit value={newKey} placeHolder={locKeyStatic("key")} />
            <ActionButton onClick={() => createCircle(organization.get(), newKey.get(), null, circles)}
                title={locKeyStatic("addConcept")} />
        </div>
        <br />
        {U.mapElems(circle => renderCircle(organization, circle, circles, 1), circles)}
    </div>)
}