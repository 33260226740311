import * as React from 'karet';
import * as U from 'karet.util';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { userItemService } from '../_services/userItem.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { timeFromNow, userItemStatusToIcon, userItemTypeToIcon, locKeyStatic, locKey } from '../_helpers/ui';
import { PagingTotal } from '../_ui/Paging';
import './UserItemList.scss';
import { userService } from '../_services/user.service';
import { EditUserContact, StaticUserContact } from './UserList';
import { UserItemStatusSelector } from './Selectors';

const updateUserItem = (userItemValue) => {
    userItemService.updateUserItem(userItemValue).catch(() => {
        pushAlert(locKeyStatic("eventUpdateFailed"), 5)
    });
}

const deleteUserItem = (userItem) => {
    userItemService.deleteUserItem(userItem.get().id).then(() => {
        userItem.remove()
    }).catch(() => {
        pushAlert(locKeyStatic("eventRemovalFailed"), 5)
    });
}

const StaticUserItem = ({ userItem, edit }) => {
    const id = U.view('id')(userItem);
    const type = U.view('type')(userItem);
    const status = U.view('status')(userItem);
    const description = U.view('description')(userItem);
    const createdAt = U.view('createdAt')(userItem);
    return (<div key={id.get()} className='UserItem'>
        <div className='CreatedAt'>{U.mapValue((value) => timeFromNow(value, 'Ei aikaa'), createdAt)}</div>
        <div className='Type'>{U.mapValue((typeValue) => userItemTypeToIcon(typeValue), type)}</div>
        <div className='Status'>{U.mapValue((statusValue) => userItemStatusToIcon(statusValue), status)}</div>
        <div className='Description'>{U.view('fi')(description)}</div>
        <div className='Operations'>
            <div className='Remove' onClick={() => deleteUserItem(userItem)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

const EditUserItem = ({ userItem, edit }) => {
    const id = U.view('id')(userItem);
    const type = U.view('type')(userItem);
    const status = U.view('status')(userItem);
    const description = U.view('description')(userItem);
    const createdAt = U.view('createdAt')(userItem);
    const user = U.atom(null)
    userService.getUserWithId(userItem.get().userId).then((userValue) => {
        user.set(userValue)
    })
    return (<div key={id.get()} className='UserItem'>
        <div className='CreatedAt'>{U.mapValue((value) => timeFromNow(value, '-'), createdAt)}</div>
        <div className='Type'>{U.mapValue((typeValue) => userItemTypeToIcon(typeValue), type)}</div>
        <div className='Status'><UserItemStatusSelector value={status} /></div>
        <div className='Description'>
            {U.view('fi')(description)}
            {U.mapValue((userValue) => userValue ?
                type.get() !== 'ContactRecommendation' ?
                    <EditUserContact user={user} edit={edit} /> :
                    <StaticUserContact user={userValue} />
                : '', user)}
        </div>
        <div className='Operations'>
            <div className='Remove' onClick={() => deleteUserItem(userItem)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

export const UserItem = ({ userItem, organization }) => {
    const edit = U.atom(false);
    userItem.skip(1).debounce(1000).observe((userItemValue) => {
        if (edit.get()) {
            updateUserItem(userItemValue)
        }
    });
    return (<div>
        {U.mapValue((editValue) => {
            return editValue ?
                <EditUserItem userItem={userItem} organization={organization} edit={edit} /> :
                <StaticUserItem userItem={userItem} edit={edit} />
        }, edit)}
    </div>)
}

const fetch = (organizationId, start, count, total, target) => {
    userItemService.getUserItems(organizationId, start, count).then((result) => {
        total.set(result.total)
        target.set(result.items)
    })
}

export const UserItemList = ({ organization }) => {
    const start = U.atom(0)
    const count = U.atom(10)
    const userItems = U.atom([])
    return (<div className='UserItemList'>
        <div className='UserItem'>
            <div className='CreatedAtHeading'>{locKeyStatic("time")}</div>
            <div className='TypeHeading'>{locKeyStatic("type")}</div>
            <div className='StatusHeading'>{locKeyStatic("status")}</div>
            <div className='DescriptionHeading'>{locKeyStatic("description")}</div>
            <div className='OperationsHeading'>{locKey("functions")}</div>
        </div>
        {U.mapElems((s) => <UserItem key={s.get().id} userItem={s}
            organization={organization} />, userItems)}
        <PagingTotal start={start} count={count} fetch={fetch.bind(this, organization.id)} target={userItems} />
    </div>)
}