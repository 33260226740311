import * as React from 'react';
import { getLanguage, history, locKeyLang } from '../_helpers';
import { orgService } from '../_services/organization.service';
import { OrganizationTree } from '../_tcomponents/OrganizationTree';
import styled from 'styled-components'
import { NumberEdit } from '../_tcomponents/TextEdit';
import * as calendarService from '../_services/calendar.service'
import { Button, FormControl, MenuItem, Paper, Select, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from '@material-ui/core';
import moment from 'moment';
import { debounce } from '../_tcomponents/Selectors';
import { FaTrash } from 'react-icons/fa';
import { pushAlert } from '../_reducers/alert.reducer';
import './CalendarPage.css'
import { useSelector } from 'react-redux';

const CalendarContainer = styled.div`
    padding-left:2.0em;
    padding-right:2.0em;
    width:100%;
    min-height:100%;
    margin-bottom:5em;
    flex:70%;
    background-color:#f0f0f0;
`

const SearchContainer = styled.div`
    display:grid;
    grid-template-columns:20em 6em 8em;
    column-gap:5px;
    margin-top:1.0em;
    margin-bottom:2.0em;
`

const ResultContainer = styled.div`
    display:grid;
    grid-template-columns:10em 20em 8em 10em 5em 1fr;
    margin:0.5em;
    row-gap:5px;
    column-gap:5px;
    align-items:center;
`

const RowHead = styled.div`
    font-weight:bold;
`


const Participants = styled.div`
    display:grid;
    grid-template-columns: 20em 5em;
    row-gap:10px;
    column-gap:10px;
    align-items:center;
    margin-bottom:2.0em;
`

const Participant = styled.div`
    margin-top:5px;
    margin-bottom:5px;
    padding:10px;
    border-radius:15px;
    border:1px solid lightgray;
`

const ParticipantRemove = styled.div`
    color:red;
    text-align:center;
    cursor:pointer;
`

export default function ReserveDialog({ open, setOpen, organization, offer, setOffer }) {
    const [reservation, setReservation] = React.useState({ participants: [] })
    const [email, setEmail] = React.useState("")
    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Varaa aika</DialogTitle>
            <DialogContent>
                <h5>Päivämäärä ja aika</h5>
                <div>{moment(offer?.dateTime).local().format("D.M.y")} klo {moment(offer?.dateTime).local().format("HH:mm")}</div>
                <h5>Järjestäjä</h5>
                <div>{offer?.organizer}</div>
                <h5>Osallistujat</h5>
                <Participants>
                    <TextField label="Sähköposti" variant="outlined" size="small"
                        value={email} onChange={(evt) => { setEmail(evt.target.value) }} />
                    <Button variant="contained" color="primary" onClick={() => {
                        setEmail("")
                        setReservation({ ...reservation, participants: [email].concat(reservation.participants) })
                    }}>Lisää</Button>
                    {reservation.participants.map(p => <>
                        <Participant>{p}</Participant>
                        <ParticipantRemove onClick={() => {
                            setReservation({
                                ...reservation, participants: reservation.participants
                                    .filter(sp => sp !== p)
                            })
                        }}><FaTrash /></ParticipantRemove>
                    </>)}
                </Participants>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>Peruuta</Button>
                <Button variant="contained" color="primary" onClick={() => {
                    setOpen(false)
                    calendarService.reserveEvent(organization, offer.id, reservation.participants).then(() => {
                        setOffer({...offer, reserved: 'reserved'})
                    }).catch(() => {
                        pushAlert("Varaus epäonnistui")
                    })
                }}>Vahvista</Button>
            </DialogActions>
        </Dialog>
    );
}

export const CalendarPage = () => {
    const [search, setSearch] = React.useState({ calendarId: null, duration: 30 })
    const [home, setHome] = React.useState(null)
    const [selected, setSelected] = React.useState(null)
    const [calendars, setCalendars] = React.useState([])
    const [results, setResults] = React.useState([])
    const [reserveOpen, setReserveOpen] = React.useState(false)
    const [reservation, setReservation] = React.useState(null)

    const findFreeEventsDebounced = debounce(() => {
        if (search.calendarId) {
            calendarService.findFreeEvents(search.calendarId, search.duration).then((results) => setResults(results))
        }
    }, 1000)

    const user = useSelector((state) => state.authentication?.user)
    if (!user) {
        history.push("/")
    }

    React.useEffect(() => {
        orgService.getHome().then((home) => {
            setHome(home)
            setSelected(home.id)
            calendarService.getCalendars().then((calendars) => {
                setCalendars(calendars)
                setSearch({ ...search, calendarId: calendars[0]?.id })
                findFreeEventsDebounced()
            })
        }).catch((_) => history.push("/login"))
    }, [])

    React.useEffect(() => {
        findFreeEventsDebounced()
    }, [calendars, search])

    return (<div className="CalendarPage" >
        <OrganizationTree className='OrganizationTree'
            heading={locKeyLang('Organizations', getLanguage())}
            root={home}
            selected={selected} setSelected={setSelected} />
        <CalendarContainer>
            <h3>{selected?.name}</h3>
            <Paper style={{ padding: '1.0em' }}>
                <h4>Etsi aikoja</h4>
                <SearchContainer>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select variant={"outlined"} value={search.calendarId} 
                            onChange={(evt) => setSearch({...search, calendarId: evt.target.value})} size={"small"}>
                            {calendars.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <NumberEdit value={search} setValue={setSearch} member={'duration'} step={15} />
                    <Button variant="contained" size="small" color="primary" onClick={() => {
                        findFreeEventsDebounced()
                    }}>Etsi</Button>
                </SearchContainer>
                <h4>Tulokset</h4>
                <ResultContainer>
                    <RowHead>Tyyppi</RowHead>
                    <RowHead>Järjestäjä</RowHead>
                    <RowHead>Aika</RowHead>
                    <RowHead>Päivämäärä</RowHead>
                    <RowHead>Kesto</RowHead>
                    <RowHead></RowHead>
                    {results.length === 0 ? "Ei aikoja" : results.map((r) => <>
                        <div>{locKeyLang(r.serviceType, getLanguage())}</div>
                        <div>{r.organizer}</div>
                        <div>{moment(r.dateTime).local().format("HH:mm")}</div>
                        <div>{moment(r.dateTime).local().format("D.M.y")}</div>
                        <div>{r.duration}&nbsp;min</div>
                        <div>
                         <Button variant="contained" color="primary" size="small"
                            disabled={r.reserved === 'reserved'}
                            onClick={() => {
                                setReserveOpen(true);
                                setReservation(r)
                            }}>{r.reserved === 'reserved' ? 'Varattu' : 'Varaa'}</Button>
                        </div>
                    </>)}
                </ResultContainer>
            </Paper>
            <ReserveDialog open={reserveOpen} setOpen={setReserveOpen} offer={reservation}
                organization={selected?.id} 
                setOffer={(offer) => {
                    setResults(results.map(r => r.id === offer.id ? offer : r))
                }} />
        </CalendarContainer>
    </div>)
}