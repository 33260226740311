import * as React from 'karet';
import * as U from 'karet.util';
import { range } from 'underscore';
import { replyToColor, questionRange, getColors } from '../_helpers/questions.js';
import { renderQuestionHeader, replyCountFromReplies } from './Question';
import { splitBy, getDefaultLanguage, locKeyStatic } from '../_helpers/ui';
import { TotalMetrics } from '../_ui/TotalMetrics.jsx';
import { FaMinus, FaCheckCircle } from 'react-icons/fa';
import "./QuestionareReplies.scss";

const renderSummaryReply = (question, replies) => {
    const minMax = questionRange(question)
    const values = range(minMax[0], minMax[1] + 1).map(() => 0)
    let total = 0;
    if (Array.isArray(replies)) {
        replies.forEach((r) => {
            values[r - 1]++;
            total += r;
        })
    } else {
        for (let key = 0; key < values.length; key++) {
            const replyKey = (key + 1).toString()
            if (replies[replyKey]) {
                values[key] = replies[replyKey];
                total += replies[replyKey] * (key + 1);
            }
        }
    }
    let sum = 0;
    values.forEach((v) => sum += v);
    const ka = (total / replyCountFromReplies(replies)).toFixed(2)
    return (<div key={question.id} className="ReplyContainer">
        {values.map((v, index) => (<div key={index} className="ReplyValue"
            style={{
                backgroundColor: replyToColor(question, index + 1, v / sum)
            }}><span>{v !== 0 ? (v * 100 / sum).toFixed(0) + '%' : ''}</span></div>))}
        <div className="Ka"><span>{ka}</span></div>
    </div>)
}

const renderReply = (question, reply, average, index) => {
    const minMax = questionRange(question)
    const pos = ((average - 0.5) * 100.0) / minMax[1]
    const values = range(minMax[0], minMax[1] + 1).map((v) => v)
    return (<div key={question.id} className="ReplyContainer" >
        {
            average ?
                <div className="Average" style={{ zIndex: 1, position: 'absolute', width: pos + "%" }
                } ></ div> : null}
        {values.map((v, index) => (<div key={index} className="ReplyValue"
            style={{
                backgroundColor: replyToColor(question, index + 1, v === reply ? 1.0 : 0.0)
            }}>&nbsp;</div>))}
    </div>)
}

const replyObjectToValue = (replies) => {
    if (Array.isArray(replies)) {
        return replies[0]
    }
    for (const key in replies) {
        if (replies[key] > 0) {
            return parseInt(key)
        }
    }
    return null
}

const renderSummary = (question, replies, average, index) => {
    return replyCountFromReplies(replies) > 1 ?
        renderSummaryReply(question, replies) :
        renderReply(question, replyObjectToValue(replies), average, index)
}

const renderQuestion = (question, average, index) => {
    const replies = question.replies;
    const text = question[getDefaultLanguage()] || question["fi"]
    const key = `${question.id}`
    switch (question.reply) {
        case "text":
            const filtered = replies.filter(f => f && f !== "")
            return (<>
                <div key={key + "full"} className="FullText">
                    <div>{text[getDefaultLanguage()] || text["fi"]}</div>
                    {filtered.length > 0 ? filtered.map((r) => <div className="ResponseText">- {r}</div>) :
                        locKeyStatic("noAnswers")}
                </div>
            </>)
        case "optionPoints":
            const options = question.replyParameters.options
            return (<div className="OptionPoints">
                {text.length > 0 ? <div className="FullText">{text}</div> : <div className="FullText"></div>}
                {options.map((o) => {
                    const count = replies ? replies[o.value] : 0
                    return (<div className="OptionRow">
                        <div className={count >= 1.0 ? "OptionSelected" : "Option"}>
                            {o.text[getDefaultLanguage()] || o.text["fi"]}
                        </div>
                        <div className={count >= 1.0 ? "OptionSelected" : "Option"}>
                            {o.value}&nbsp;{o.value > 1 || o.value === 0 ? locKeyStatic("points") :
                                locKeyStatic("point")}</div>
                        <div className="OptionValue">{count >= 1.0 ? <FaCheckCircle /> :
                            count > 0.0 ? (count * 100).toFixed(0) + '%' : <FaMinus />}</div>
                    </div>)
                })}</div>)
        case "enumerationText": {
            return <div></div>
        }
        default:
            return (<>
                <div key={key + "text"} className={index % 2 == 1 ? "Text" : "TextOdd"}>{text}</div>
                {replies && replyCountFromReplies(replies) > 0 ?
                    renderSummary(question, replies, average, index) :
                    <div key={key} className="EmptyReply">{question.count ? (question.sum / question.count).toFixed(1) : '-'}</div>}
            </>)
    }
}

export const QuestionareReplies = ({ questionMap, averages }) => {
    const both = U.molecule({ questionMap, averages })
    return (<div className="QuestionareReplies">
        {U.mapValue(({ questionMap, averages }) => {
            const groups = splitBy(questionMap.questions, (i) => i.id ? i.reply : i.subId)
            return (<React.Fragment>
                <div className="TotalMetricsContainer">
                    {questionMap.totalMetrics && questionMap.totalMetrics.length > 0 ?
                        <TotalMetrics metrics={questionMap.totalMetrics}
                            thresholds={[]}
                            colors={getColors()} />
                        : ''}
                </div>
                {groups.map((group, index) => group.length > 0 ? <React.Fragment>
                    {renderQuestionHeader(group[0], index === 0)}
                    {group.map((q, index) => {
                        if (q.subId) {
                            return (<QuestionareReplies questionMap={U.atom(q)} averages={averages} />)
                        } else {
                            return renderQuestion(q, averages ? averages[q.id] : null, index)
                        }
                    })}
                </React.Fragment> : '')}
            </React.Fragment>)
        }, both)}
    </div>)
}