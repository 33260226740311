import * as React from 'karet';
import * as U from 'karet.util';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import './Paging.css'

export const Paging = ({ start, count, fetch, target, sort, other }) => {
    const total = U.view("length", target)
    const allLoaders = U.molecule({ cstart: start, ccount: count, csort: sort, other })
    allLoaders.observe(({ cstart, ccount, csort, other }) => {
        fetch(cstart, ccount, target, csort)
    })
    const startCountTotal = U.molecule({ cstart: start, ccount: count, ctotal: total })
    return (<React.Fragment>
        {U.mapValue(({ cstart, ccount, ctotal }) =>
            ctotal > ccount ? <div className="Paging">
                <FaAngleLeft className="PagingButton"
                    onClick={() => start.set(Math.max(0, cstart - ccount))} />
                {`${cstart}-${Math.min(cstart + ccount, ctotal)}`}&nbsp;/{cstart + ccount < ctotal ? `${ctotal}+` : ctotal}
                <FaAngleRight className="PagingButton"
                    onClick={() => start.set(
                        Math.min(cstart + ccount, Math.max(0, ctotal - ccount)))} />
            </div> : '', startCountTotal)
        }
    </React.Fragment>)
}

export const PagingTotal = ({ start, count, fetch, target, loading }) => {
    const total = U.atom(0);
    const startCount = U.molecule({ start, count, total })
    startCount.observe(({ start, count }) => {
        if (loading) {
            loading.set(true)
        }
        fetch(start, count, total, target, loading)
    })
    const startCountTotal = U.molecule({ start, count, total })
    return (<div className="Paging">
        <FaAngleLeft className="PagingButton"
            onClick={() => start.set(Math.max(0, start.get() - count.get()))} />
        {U.mapValue(({ start, count, total }) =>
            `${start}-${Math.min(start + count, total)}`, startCountTotal)}&nbsp;/{start + count < total ? `${total}+` : total}
        <FaAngleRight className="PagingButton"
            onClick={() => start.set(
                Math.min(start.get() + count.get(), Math.max(0, total.get() - count.get())))} />
    </div>)
}