import * as React from 'karet';
import * as U from 'karet.util';
import { connect } from 'react-redux';
import { orgService } from '../_services/organization.service';
import { OrganizationTree } from '../_components/OrganizationTree';
import { ProtectedPage } from '../_components/ProtectedPage';
import { CircleList } from '../_components/CircleList';
import './LibraryPage.css';
import { locKey, locKeyStatic } from '../_helpers';

class LibraryPage extends ProtectedPage {
    constructor() {
        super()
        this.state = {
            home: U.atom(null),
            selected: U.atom(null)
        }
    }

    UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount()
        if (!this.state.home.get()) {
            this.getHome();
        }
    }

    UNSAFE_componentWillUpdate() {
        super.UNSAFE_componentWillUpdate()
        if (!this.state.home.get()) {
            this.getHome();
        }
    }

    getHome() {
        orgService.getHome().then((home) => {
            this.state.home.set(home)
            return orgService.getOrganization(home.id)
        }).then((org) => {
            if (!this.state.selected.get()) {
                this.state.selected.set(org);
            }
        }).catch(() => {
        })
    }

    render() {
        const { home, selected } = this.state;
        return (
            <div className="LibraryPage">
                {
                    U.mapValue((value) => value ? <OrganizationTree className='OrganizationTree'
                        heading={locKey('Organizations')}
                        root={home}
                        selected={selected} /> : '', home)
                }
                <div className="Library">
                    <h3>{locKeyStatic("concepts")}</h3>
                    <CircleList organization={selected} />
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return { user: state.authentication.user }
}

const connectedLibraryPage = connect(mapStateToProps)(LibraryPage);
export { connectedLibraryPage as LibraryPage }; 