import * as React from 'karet';
import * as R from 'ramda';
import * as U from 'karet.util';
import { ActionButton, CloseButton, AddButton } from '../BasicElements';
import { locKey, locKeyStatic } from '../../_helpers/ui';
import { QuestionareTimelineSelect } from '../QuestionareSelector';
import { Bar, YAxis, CartesianGrid, BarChart, LabelList, Cell } from 'recharts';
import { OrganizationSelector } from '../OrganizationSelector';
import { GroupMultiSelector } from '../GroupSelector';
import { runService } from '../../_services/run.service';
import moment from 'moment';
import { TextEdit } from '../TextEdit';
import { pushAlert } from '../../_reducers/alert.reducer';
import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';
import "./CompareResults.scss"
const {v4: uuidv4}=require('uuid');

let _visible = U.atom(false)

let _hostRunId = U.atom(null)
let _runId = U.atom(null)
let _subId = U.atom(null)
let _selectedOrganizationId = U.atom(null)
let _organizationName = U.atom(null)
let _groups = U.atom([])
let _selectedGroups = U.atom([])
let _width = U.atom(800)
const _data = U.atom([]);
let _reports = null
let _report = U.atom({
    name: "",
    reportId: uuidv4(),
    items: []
})

let _reportName = U.view("name")(_report)
let _reportItems = U.view("items")(_report)

export const showCompareResults = ({ organizationId, hostRunId, report, reports, create }) => {
    _hostRunId.set(hostRunId.get())
    _selectedOrganizationId.set(organizationId)
    _runId.set(null)
    _subId.set(null)
    _reports = reports

    _report = report
    _reportName = U.view("name")(_report)
    _reportItems = U.view("items")(_report)
    _data.set([])
    if (create) {
        _report.set({
            name: "",
            reportId: uuidv4(),
            items: []
        })
    }

    const origItems = _report.get().items
    Promise.all(origItems.map(item => {
        return runService.getOrganizationRunTotal(item.organizationId, item.runId, item.subId,
            item.selectedGroups)
    })).then((items) => {
        _data.set(items.map((result, index) => ({
            name: resultName(result),
            orgName: result.organizationName,
            value: result.avgResponse,
            color: origItems[index].color || nameToColor(result.organizationName)
        })))
        _visible.set(true)
    })
};

function stringToHex(str) {
    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var color = '#';
    for (var j = 0; j < 3; j++) {
        var value = (hash >> (j * 8)) & 255;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : { r: 0, g: 0, b: 0 };
}

function resultName(result) {
    const date = moment(result.createdAt).format("D.M.Y")
    let name = result.name
    if (result.groups) {
        const groups = result.groups.map(g => g.value).join(',')
        if (groups.length > 0) {
            name += " / \n" + groups
        }
    }
    name += `\n(${date})`
    return name
}

function nameToColor(name) {
    const rgb = hexToRgb(stringToHex(name))
    return `rgb(${Math.floor(rgb.r * 0.8)},${Math.floor(rgb.g * 0.8)},${Math.floor(rgb.b * 0.8)})`
}

function setDataColor(orgName, color) {
    const data = _data.get().map((entry) => ({
        ...entry,
        color: entry.orgName === orgName ? color.color : entry.color
    }))
    _report.set({
        ..._report.get(), items: _report.get().items.map((r, index) => (
            {
                ...r,
                color: data[index].orgName === orgName ? color.color : r.color
            }))
    })
    _data.set(data)
}

function removeItem(index) {
    _data.set(_data.get().filter((_, i) => i !== index))
    _report.set({
        ..._report.get(), items: R.remove(index, 1, _report.get().items)
    })
}

export function CompareResults() {
    _data.observe((data) => {
        _width.set(Math.max(800, data.length * 100 + 150))
    })
    return <>{U.mapValue(visibleValue => visibleValue ?
        <div className="CompareResultsContainer">
            <div className="CompareResults" style={{ width: _width }}>
                <div className="TopBar">
                    <h3>{locKey("makeReport")}</h3>
                </div>
                <div className="ContentBar">
                    <h4>{locKey("reportName")}</h4>
                    <TextEdit value={_reportName} placeHolder={locKeyStatic("reportName")} />
                    <h4>{locKey("organization")}</h4>
                    <OrganizationSelector organization={_selectedOrganizationId}
                        organizationName={_organizationName} />
                    {U.mapValue(organizationId => <>
                        <div className="AddOrganization">
                            <h4>{locKey("selectRun")}</h4>
                            <h4>{locKey("groups")}</h4>
                            <div></div>
                        </div>
                        <div className="AddOrganization">
                            <QuestionareTimelineSelect organization={organizationId}
                                onSelect={(selected) => {
                                    _groups.set([])
                                    _selectedGroups.set([])
                                    if (selected.runId) {
                                        _runId.set(selected.runId)
                                        _subId.set(selected.value)
                                        runService.getOrganizationRun(organizationId, selected.runId).then((run) => {
                                            _groups.set(run.groups)
                                        })
                                    } else {
                                        _subId.set(null)
                                        _runId.set(selected.value)
                                        runService.getOrganizationRun(organizationId, selected.value).then((run) => {
                                            _groups.set(run.groups)
                                        })
                                    }
                                }} subLevels={true} />
                            <GroupMultiSelector groups={_groups} selectedGroups={_selectedGroups} />
                            <AddButton onClick={() => {
                                const item = {
                                    type: "bar",
                                    organizationId: _selectedOrganizationId.get(),
                                    runId: _runId.get(),
                                    subId: _subId.get(),
                                    selectedGroups: _selectedGroups.get()
                                }
                                runService.getOrganizationRunTotal(_selectedOrganizationId.get(),
                                    _runId.get(), _subId.get(), _selectedGroups.get()).then((result) => {
                                        _data.modify(R.append({
                                            name: resultName(result),
                                            orgName: result.organizationName,
                                            value: result.avgResponse,
                                            color: nameToColor(result.organizationName)
                                        }))
                                        item.color = nameToColor(result.organizationName)
                                        _reportItems.modify(R.append(item))
                                    })
                            }} />
                        </div>
                    </>, _selectedOrganizationId)}

                    <h4>{locKey("resultList")}</h4>
                    {
                        U.mapValue(data => data.length > 0 ?
                            <div className="Results" id="compareResults">
                                <BarChart
                                    data={data}
                                    margin={{
                                        top: 10, right: 10, left: 0, bottom: 70,
                                    }}
                                    barGap={Math.max(data.length * 100 + 100, 500) / (data.length + 1)}
                                    barSize={80}
                                    width={Math.max(500, data.length * 100 + 100)} height={400}
                                >
                                    <CartesianGrid strokeDasharray={`10 6`} />
                                    <YAxis orientation={"right"} domain={[2, 6]} />
                                    <Bar dataKey="value" radius={[15, 15, 0, 0]} maxBarSize="80">
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color}
                                                onClick={() => removeItem(index)}>
                                            </Cell>
                                        ))}
                                        <LabelList dataKey="name" position="bottom" />
                                    </Bar>
                                </BarChart>
                                <div className="Legend">
                                    {R.uniqBy(t => t.orgName, data).map((entry) => (
                                        <>
                                            <ColorPicker
                                                color={entry.color}
                                                onChange={(color) => setDataColor(entry.orgName, color)}
                                                style={{ zIndex: 10000 }}
                                            >
                                                <div className="Marker" style={{ backgroundColor: entry.color }} />
                                            </ColorPicker>
                                            <div className="Text" style={{ color: entry.color }}>{entry.orgName}</div>
                                        </>
                                    ))}
                                </div>
                            </div> : null, _data)
                    }
                </div >
                <div className="BottomBar">
                    <ActionButton onClick={() => {
                        _visible.set(false)
                        runService.saveRunReport(_hostRunId.get(), _report.get()).then(() => {
                            if (_reports) {
                                _reports.modify(R.filter(r => r.reportId !== _report.get().reportId))
                                _reports.modify(R.append(_report.get()))
                            }
                            pushAlert(locKeyStatic("reportSaved"), 5, "Info")
                        }).catch(() => {
                            pushAlert(locKeyStatic("reportSaveFailed", 5))
                        })
                    }} title={locKey("save")} />
                    <CloseButton onClick={() => _visible.set(false)} title={locKey("close")} />
                </div>
            </div>
        </div> : null, _visible)
    }</>
}

export function CompareResultsCreate({ organizationId, hostRunId, report, reports }) {
    return <ActionButton title={locKey("createReport")}
        onClick={() => showCompareResults({ organizationId, hostRunId, report, reports, create: true })} />
}

export function CompareResultsShow({ organizationId, hostRunId, report, reports, disabled }) {
    return <ActionButton title={locKey("showReport")} disabled={disabled}
        onClick={() => showCompareResults({ organizationId, hostRunId, report, reports })} />
}