import { Button, Dialog, FormControl, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField } from '@material-ui/core'
import * as React from 'react'
import { useEffect } from 'react'
import { FaArrowLeft, FaArrowRight, FaTrash } from 'react-icons/fa'
import styled from 'styled-components'
import { getLanguage, locKeyLang } from '../_helpers'
import { DateTime } from './DateTime'
import { NumberEdit, TextEdit } from './TextEdit'
import uuid from 'react-uuid';
import { ReservationStateSelector, TimeSelector } from './Selectors'
import * as calendarService from '../_services/calendar.service'
import { momentLocal } from '../Utils'
import moment from 'moment'
import { QuestionareList } from '../_components/QuestionareList'
import { qService } from '../_services/questionare.service'
import { QuestionareReplies } from '../_components/QuestionareReplies'

const CalendarContainer = styled.div`
    position:relative;
    display:grid;
    @media (max-width: 800px) {
        grid-template-columns:60px repeat(7, 60px);
        grid-template-rows: repeat(19, 25px);    
    }
    @media (min-width: 800px) {
        grid-template-columns:60px repeat(7, 80px);
        grid-template-rows: repeat(19, 25px);    
    }
    overflow:scroll;
`


const ReservationContainer = styled.div`
    margin-top:0.5em;
    margin-left:1.0em;
    @media (min-width: 800px) {
        display:grid;
        grid-template-columns:1fr 1fr;
    }
    margin-bottom:1.0em;
`


const WeekHeading = styled.div`
    align-self:center;
    justify-self:center;
    grid-column-start:1;
    grid-column-end:9;
    text-align:center;
    font-weight:bold;
    display:grid;
    grid-template-columns:1.0em 10em 1.0em;
    align-items:center;
    min-height:30px;
    max-height:30px;
`

const CellHeading = styled.div`
    min-height:25px;
    max-height:25px;
    text-align:center;
    border-bottom:1px solid lightgray;
`

const Cell = styled.div`
    min-height:25px;
    max-height:25px;
    border-bottom:1px solid lightgray;
    border-right:1px solid lightgray;
    cursor:pointer;
    &:hover {
        background-color:#dfdfdf;
    }
`

const CellRow = styled.div`
    min-height:1.5em;
    padding-top:2px;
    padding-bottom:2px;
    padding-right:10px;
    text-align:right;
    border-right:1px solid lightgray;
`


const TopBar = styled.div`
    min-height:3px;
    min-width:78px;
    cursor:pointer;
    top:0px;
    position:absolute;
    &:hover {
        background-color:#000000;
    }
`

const BottomBar = styled.div`
    min-height:3px;
    min-width:78px;
    cursor:pointer;
    bottom:0px;
    position:absolute;
    &:hover {
        background-color:#000000;
    }
`

const ReservationItem = styled.div`
    margin:1.0em;
    
`

const Items = styled.div`
    @media (min-width: 800px) {
        display:grid;
        grid-template-columns: 10em 1fr;
    }
    row-gap:10px;
    align-items:center;
`

const Heading = styled.h4``

const Label = styled.div`
`

const Value = styled.div``

const ValueControls = styled.div`
    grid-column-start:2;
    align-items:right;
    padding-top:10px;
    margin-left:auto;
    margin-right:0px;
`


const reservationStyle = (r) => {  
    const weekday = (r.dateTime.weekday() + 6)%7
    const hour = r.dateTime.hour() + r.dateTime.minute() / 60.0 
    const width = window.innerWidth >= 800 ? 80 : 60
    return {
        position:'absolute',
        left:60 + weekday*width,
        top:(hour - 4) * 25,
        userSelect:'none',
        minWidth:width,
        maxWidth:width,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        textAlign:'center',
        minHeight:(r.duration * 25) / 60,
        maxHeight:(r.duration * 25) / 60,
        borderRadius: r.state === 'initial' ? '7px' : r.reserved === 'reserved' ? '4px' : '0px',
        border: r.state === 'initial' ? '1px dotted black' : r.reserved === 'reserved' ? '2px solid black' : '1px solid black',
        backgroundColor: r.state === 'initial' ? 'rgba(128,128,255,0.5)' : 
            r.reserved === 'free' ? 'rgba(128,128,255,1.0)' : 'rgba(255,128,128,1.0)',
        color: 'black'
    }
}

const cloneReservation = (r) => {
    return {...JSON.parse(JSON.stringify(r)), dateTime: r.dateTime.clone()}
}


export const Reservation = ({organization}) => {
    const [currentDate, setCurrentDate] = React.useState(momentLocal().startOfWeek().format('YYYY-MM-DD'))
    const [startOfWeek, setStartOfWeek] = React.useState(momentLocal().startOfWeek().format("D.M"))
    const [endOfWeek, setEndOfWeek] = React.useState(momentLocal().endOfWeek().format("D.M"))
    const [drag, setDrag] = React.useState(null)
    const [selected, setSelected] = React.useState(null)
    const [calendars, setCalendars] = React.useState("")
    const [reservations, setReservations] = React.useState([])
    const [saved, setSaved] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [questionares, setQuestionares] = React.useState([])
    const [questionareReplies, setQuestionareReplies] = React.useState([])
    
    const dayMinutes = moment().hour() * 60 + moment().minute() - 360
    useEffect(() => {
        calendarService.getCalendars().then((calendars) => setCalendars(calendars))
        calendarService.getEvents().then((events) => 
            setReservations(events.map(e => ({...e, dateTime: momentLocal(e.dateTime)}))))
            qService.getAll(organization).then((response) => {
                setQuestionares(response)
            })
    }, [])

    useEffect(() => {
        setStartOfWeek(momentLocal(currentDate).format("D.M"))
        setEndOfWeek(momentLocal(currentDate).endOfWeek().format("D.M"))
    }, [currentDate])

    useEffect(() => {
        if (selected && selected.id) {
            const found = reservations.find(r => r.id === selected.id)
            if (found && JSON.stringify(selected) !== JSON.stringify(found)) {
                setSaved(false)
                setReservations(reservations.map((r) => r.id === selected.id ? selected : r))                
            }
            calendarService.getEventRun(selected.id).then((replies) => setQuestionareReplies(replies))
                .catch(() => setQuestionareReplies([]))
        }
    }, [selected])

    function addReservation(weekday, hour) {
        const newReservation = {
            id: uuid(),
            duration: 45,
            calendarId: calendars[0].id,
            state: 'initial',
            description: '',
            dateTime: momentLocal(currentDate).weekday((weekday + 1)%8).hour(hour),
            repeat: 'none',
            repeatTimes: 1,
            reminders: [-1440,-120],
            participants: []
        }
        setSelected(newReservation)
        setReservations([newReservation].concat(reservations))
        setSaved(false)
        setOpen(true)
    }

    function removeReservation(id) {
        setSelected(null)
        setReservations(reservations.filter(r => r.id !== id))
    }

    const today = momentLocal().format("YYYY-MM-DD")
    const isToday = [0,1,2,3,4,5,6].map((index) => 
        momentLocal(currentDate).add({days: index}).format("YYYY-MM-DD") === today)
    const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    return (
        <ReservationContainer onMouseMove={(event) => {
            if (drag) {
                const r = {...JSON.parse(JSON.stringify(reservations[drag.index]))}
                const mdt = drag.r.dateTime.clone()
                if (drag.type == 'center') {
                    const weekday = drag.r.dateTime.weekday() + Math.round((event.clientX - drag.x) / 80)
                    r.dateTime = mdt.weekday(weekday)
                }
                if (drag.type == 'top' || drag.type == 'center') {
                    const newHour = Math.round((drag.r.dateTime.hour() + (event.clientY - drag.y) / 25) * 4.0) / 4.0
                    r.dateTime = mdt.hour(Math.round(newHour)).minute((newHour - Math.round(newHour)) * 60).clone()
                }
                if (drag.type == 'top' || drag.type == 'bottom') {
                    const diff = drag.type === 'top' ? (drag.y - event.clientY) : (event.clientY - drag.y)
                    const newDuration = Math.round((drag.r.duration + diff * 60 / 25) / 15.0) * 15
                    if (newDuration >= 15) {
                        r.duration = newDuration
                    }
                    r.dateTime = mdt
                }
                reservations[drag.index] = r
                if (r.id === selected?.id) {
                    setSelected({...r, dateTime: r.dateTime})
                }
                setReservations(reservations.map(r => ({...r, dateTime: r.dateTime})))
                event.preventDefault()
            }
        }} onMouseUp={() => setDrag(null)}>
            <CalendarContainer>
                <WeekHeading>
                    <FaArrowLeft style={{cursor:'pointer'}} onClick={() => 
                        setCurrentDate(momentLocal(currentDate).subtract({days: 7}).format("YYYY-MM-DD"))}/>
                    {locKeyLang("week", getLanguage())} {momentLocal(currentDate).week()}&nbsp;&nbsp;{startOfWeek}&nbsp;-&nbsp;{endOfWeek}
                    <FaArrowRight style={{cursor:'pointer'}} onClick={() =>
                        setCurrentDate(momentLocal(currentDate).add({days: 7}).format("YYYY-MM-DD"))}/>
                </WeekHeading>
                <CellHeading style={{borderBottom:'0px'}} />
                {days.map((d, index) => 
                    <CellHeading style={{fontWeight: isToday[index] ? "bold" : "normal"}}>
                        {locKeyLang(d, getLanguage())}
                    </CellHeading>)}
                {[6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(hour => <>
                    <CellRow>{hour}-{hour + 1}</CellRow>
                    {[0,1,2,3,4,5,6].map(d => <Cell style={{backgroundColor: isToday[d] ? '#ffffff' : 'transparent'}} onClick={() => addReservation(d, hour)}></Cell>)}
                </>)}
                {reservations.map((r, index) => 
                (r.dateTime.isSameOrAfter(currentDate) && 
                r.dateTime.isSameOrBefore(momentLocal(currentDate).add({weeks: 1})) ?
                <Paper id={`reservation${index}`} style={reservationStyle(r)}>
                    <TopBar onMouseDown={(event) => {
                        setDrag({index, 
                            r: cloneReservation(index),
                            x: event.clientX, 
                            y: event.clientY, 
                            type: 'top'})
                    }} />
                    <div style={{fontSize: `${Math.min(r.duration, 60) / 80.0}em`}}
                        onClick={() => {setSelected(reservations[index]); setOpen(true)}} 
                        onMouseDown={(event) => {
                        if (!drag) {
                            setDrag({index, x: event.clientX, y: event.clientY, r: cloneReservation(reservations[index]), type: 'center'})
                        }
                    }}>{r.description || ('(alustava)')}</div>
                    <BottomBar onMouseDown={(event) => {
                        setDrag({index, r: cloneReservation(reservations[index]), y: event.clientY, type: 'bottom'})
                    }} />
                </Paper> : undefined))}
                {dayMinutes > 0 && <div style={{left:60,top:50 + (dayMinutes * 25.0) / 60.0,width:7*80,borderTop:'1px dashed gray',position:'absolute',minHeight:1}}/>}
            </CalendarContainer>
            <Dialog open={open} onClose={() => setOpen(false)}>
                {selected && selected.reserved !== 'reserved' && <ReservationItem>
                    <Heading>Varaus</Heading>
                    <Items>
                        <Label>Kalenteri</Label>
                        <Value>
                            <Select variant={"outlined"} value={selected.calendarId}
                                onChange={(evt) => setSelected({...selected, calendarId: evt.target.value})}>
                                {calendars.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                            </Select>
                        </Value>
                        <Label>Aika</Label>
                        <Value>
                            <DateTime value={selected.dateTime}
                                setValue={(value) => {
                                const newDateTime = momentLocal(value)
                                setSelected({...selected, dateTime: newDateTime})
                            }}/>
                        </Value>
                        <Label>Kuvaus</Label>
                        <Value>
                            <TextEdit value={selected} setValue={setSelected} multiline rows={4} columns={25}
                                member={"description"}/>
                        </Value>
                        <Label>Kesto</Label>
                        <Value><NumberEdit value={selected} setValue={setSelected} step={15} member={"duration"}/></Value>
                        <Label>Toisto</Label>
                        <Value>
                            <RadioGroup value={selected.repeat} row onChange={(ev) => {
                                setSelected({...selected, repeat: ev.target.value})
                            }}>
                                <FormControlLabel value="none" control={<Radio  />} label="Ei toistoa" />
                                <FormControlLabel value="weekday" control={<Radio />} label="Arkipäivisin" />
                                <FormControlLabel value="weekly" control={<Radio />} label="Viikottain" />
                            </RadioGroup>
                            {selected.repeat !== "none" && <TextField label="Kertaa" variant="outlined" size="small" 
                                value={selected.repeatTimes} type="number" min="1" max="20"
                                defaultValue={1} style={{maxWidth:'6em'}}
                                onChange={(ev) => {
                                    const value = Math.min(20, Math.max(1, parseInt(ev.target.value)))
                                    setSelected({...selected, repeatTimes: value})
                                }}/>}
                        </Value>
                        <Label>Kysely</Label>
                        <Value>
                            <FormControl size="small">
                                <Select variant="outlined" value={selected.questionareId || "none"} autoWidth={true}
                                    style={{margin:'auto', minWidth:'15em'}}
                                    onChange={(ev) => setSelected({...selected, questionareId: ev.target.value})}>
                                    <MenuItem key="noValue" value={"none"}>Ei kyselyä</MenuItem>
                                    {questionares.map(q => <MenuItem value={q.id}>{q.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Value>
                        <Label>1. muistutus</Label>
                        <Value>
                            <TimeSelector value={selected.reminders[0]} setValue={(value) => {
                                setSelected({...selected, reminders: [value, selected.reminders[1]]})
                            }}/>
                        </Value>
                        <Label>2. muistutus</Label>
                        <Value>
                            <TimeSelector value={selected.reminders[1]} setValue={(value) => {
                                setSelected({...selected, reminders: [selected.reminders[0], value]})
                            }}/>
                        </Value>
                        <Label></Label>
                        <ValueControls>
                            <Button variant="contained" color="secondary" onClick={() => {
                                if (selected.state !== 'initial') {
                                    calendarService.deleteCalendarEvent(selected.id).then(() => {
                                        removeReservation(selected.id)
                                    })
                                } else {
                                    removeReservation(selected.id)
                                }
                                setOpen(false)
                                setSelected(null)
                        }}>Poista</Button>
                            <Button disabled={saved || selected.description.length <= 0} variant="contained" color="primary" onClick={()=> {
                                calendarService.saveCalendarEvent(selected).then((newReservations) => {
                                    const state = selected.reserved === "free" ? "offer" : "reserved"
                                    setSaved(true)
                                    const allReservations = reservations.filter(r => r.id !== selected.id)
                                        .concat(newReservations.map(r => ({...r, state, dateTime: momentLocal(r.dateTime)})))
                                    setReservations(allReservations)
                                    setSelected({...selected, state})
                                    setOpen(false)
                                })
                            }}>Tallenna</Button>
                        </ValueControls>
                    </Items>
                </ReservationItem>}
                {selected && selected.reserved === 'reserved' && <ReservationItem>
                    <Heading>Varattu</Heading>
                    <Items>
                        <Label>Kalenteri</Label>
                        <Value>
                            {calendars.find(c => c.id === selected.calendarId).name}
                        </Value>
                        <Label>Aika</Label>
                        <Value>
                            {selected.dateTime.format("d.M.Y HH:mm")}
                        </Value>
                        <Label>Kysely</Label>
                        <Value>{selected.questionareId ? questionares.find(q => q.id === selected.questionareId).name : "-"}</Value>
                        <Label>Kuvaus</Label>
                        <Value>
                            {selected.description}
                        </Value>
                        <Label>Kesto</Label>
                        <Value>{selected.duration} min</Value>
                        <Label>Osallistujat</Label>
                        <Value>
                            {selected.participants.map(p => 
                            <>
                                {questionareReplies.length > 0 ? <h4>{p.email}</h4> : <div>{p.email}</div>}
                                {questionareReplies.find(q => q.email === p.email) ? <QuestionareReplies questionMap={questionareReplies.find(q => q.email === p.email).questions[0]}/> : ''}
                            </>)}
                        </Value>
                        <ValueControls>
                            <div></div>
                            <Button disabled={saved || selected.description.length <= 0} variant="contained" color="primary" onClick={()=> {
                                    setOpen(false)
                            }}>Sulje</Button>
                        </ValueControls>
                    </Items>
                </ReservationItem>}
            </Dialog>
        </ReservationContainer>)
}