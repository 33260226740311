import { apiUrl, deleteApi, getApi, postApi } from './common';

function getResources() {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(`${apiUrl}/system/resources`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        if (response.status !== 200) {
            throw response.status
        }
        const data = text && JSON.parse(text);
        return data;
    });
}

async function createCalendar({name, description}) {
    return await postApi('/system/calendar', {name,description})
}

async function deleteCalendar(calendarId) {
    return await deleteApi(`/system/calendar/${calendarId}`) === 200
}

async function getCalendars() {
    return await getApi('/system/calendar')
}

export const systemService = {
    getResources,
    createCalendar,
    deleteCalendar,
    getCalendars
};