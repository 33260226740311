import * as React from 'karet';
import * as U from 'karet.util';
import Select from 'react-select';

const customStyles = {
    control: (base, state) => ({
        ...base,
        width: '18em'
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

export const TemplateSelector = ({ template, templates }) => {
    const list = templates ? templates.map((t) => ({ value: t.templateId, label: t.name })) : []
    return (<div>{U.mapValue((templateValue) =>
        <Select styles={customStyles} value={list.find((option) => templateValue === option.value)}
            options={list} onChange={(newSelection) => {
                template.set(newSelection.value);
            }} />, template)}</div>);
}