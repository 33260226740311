import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { LanguageTextEdit } from '../LanguageTextEdit';
import { FaTrash } from 'react-icons/fa';
import { showYesNoDialog } from '../../_ui/Dialog';
import './NormMaterialEditor.scss';
import { OrganizationSelector } from '../OrganizationSelector';

const Organization = ({ organization, index }) => {
    const name = U.view('name')(organization)
    return (<div className={index % 2 === 1 ? "ItemOdd" : "ItemEven"}>
        <h5>{name}</h5>
        <FaTrash className="Delete" onClick={() => deleteOrganization(organization)} />
    </div>)
}

const addOrganization = ({ organizations, id, name }) => {
    organizations.modify(R.append({ id, name }))
}

const deleteOrganization = (item) => {
    showYesNoDialog({
        title: 'Poiston varmistus',
        message: `Haluatko varmasti poistaa organisaation?`,
        parameter: item,
        onYes: (item) => {
            item.remove()
        }
    })
}

export const NormMaterialEditor = ({ material, state }) => {
    const organizations = U.view("organizations")(material)
    const selectedOrganization = U.atom(null)
    const selectedName = U.atom(null)
    const totalReplies = U.view("totalReplies")(state)
    return (<div className="NormMaterialEditor">
        <div className="BasicInfo">
            <div className="Label">Otsikko</div>
            <LanguageTextEdit values={material} rows={2} />
            <div className="Label">Organisaatiot</div>
            <div className="Organization">
                <OrganizationSelector organization={selectedOrganization} organizationName={selectedName}/>
                <button className="AddOrganization" onClick={() => addOrganization({
                    organizations, id: selectedOrganization.get(), name: selectedName.get() })}>Lisää</button>
            </div>
            <div className="Label">Vastauksia</div>
            <div className="Label">
                {U.mapValue(value => (value || "-") + " kpl", totalReplies)}
            </div>
        </div>
        <h5>Organisaatiot</h5>
        {U.mapElems((organization, index) =>
            <Organization organization={organization} index={index} />, organizations)}
    </div >)
}