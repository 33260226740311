import * as React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { evtTarget, getLanguage } from '../_helpers';
import { mapRightsToRole, roleToRights } from '../_helpers/role';
import { locKeyLang, locKeyPlain } from '../_helpers/ui';
import './Selectors.css';

export const LanguageSelector = ({ value, setValue }) => {
    return (<FormControl size="small">
        <Select value={value} variant="outlined" onChange={(evt) => {
            setValue(evtTarget(evt).value)
        }}>
            <MenuItem value="fi">{locKeyLang("finnish", value)}</MenuItem>
            <MenuItem value="en">{locKeyLang("english", value)}</MenuItem>
            <MenuItem value="sv">{locKeyLang("swedish", value)}</MenuItem>
            <MenuItem value="ee">{locKeyLang("estonia", value)}</MenuItem>
        </Select>
    </FormControl>)
}

export const ReservationStateSelector = ({ value, setValue }) => {
    return (<FormControl size="small">
        <Select value={value} variant="outlined" onChange={(evt) => {
            setValue(evtTarget(evt).value)
        }}>
            <MenuItem value="free">Vapaa</MenuItem>
            <MenuItem value="reserved">Varattu</MenuItem>
        </Select>
    </FormControl>)
}

export const TimeSelector = ({ value, setValue }) => {
    return (<FormControl size="small">
        <Select value={value} variant="outlined" onChange={(evt) => {
            setValue(evtTarget(evt).value)
        }}>
            <MenuItem value={0}>Ei muistutusta</MenuItem>
            <MenuItem value={-15}>15 min ennen</MenuItem>
            <MenuItem value={-30}>30 min ennen</MenuItem>
            <MenuItem value={-60}>1 tunti ennen</MenuItem>
            <MenuItem value={-120}>2 tuntia ennen</MenuItem>
            <MenuItem value={-240}>4 tuntia ennen</MenuItem>
            <MenuItem value={-1440}>1 vuorokausi ennen</MenuItem>
        </Select>
    </FormControl>)
}


export const debounce = (callback, timeout) => {
    let id = 0
    return (...args) => {
       clearTimeout(id)
        id = setTimeout(() => callback(...args), timeout)
    }
}

const skipFunctions = {}

export const useEffectSkip = (callback, objects, skips) => {
    const key = callback.toString()
    let count = (skipFunctions[key] || 0)
    React.useEffect(() => {
        skipFunctions[key] = count + 1
        if (count >= skips) {
            callback()
        }
    }, objects)
}

const updateObjects = {}

export const useEffectUpdate = (callback, objects) => {
    const key = callback.toString()
    if (!updateObjects[key]) {
        updateObjects[key] = JSON.stringify(objects)
    }
    React.useEffect(() => {
        if (JSON.stringify(objects) !== updateObjects[key]) {
            updateObjects[key] = JSON.stringify(objects)
            callback()
        }
    }, [objects])
}

export const RoleSelector = ({ setCustom, rights, setRights }) => {
    const [role, setRole] = React.useState(mapRightsToRole(rights))
    return (<select className='SourceSelector' onChange={(evt) => {
        setCustom(evtTarget(evt).value === "custom")
        setRole(roleToRights(evtTarget(evt).value))
        setRights(roleToRights(evtTarget(evt).value, rights))
    }}>
        <option key="custom" value="custom" selected={role === "custom"}>{locKeyLang("customRole", getLanguage())}</option>
        <option key="userManager" value="userManager" selected={role === "userManager"}>{locKeyLang("userManagerRole", getLanguage())}</option>
        <option key="totalResultViewer" value="totalResultViewer" selected={role === "totalResultViewer"}>{locKeyLang("totalResultViewerRole", getLanguage())}</option>
        <option key="individualResultViewer" value="individualResultViewer" selected={role === "individualResultViewer"}>{locKeyLang("individualResultViewerRole", getLanguage())}</option>
        <option key="all" value="all" selected={role === "all"}>{locKeyLang("allRole", getLanguage())}</option>
    </select>)
}