import * as React from 'karet';
import * as U from 'karet.util';
import { parseAlertMessage, parseEndtime, parseClassName } from '../_reducers/alert.reducer';

export const Alert = ({ message }) => {
    const opacity = U.atom(0.0);
    const endTime = parseEndtime(message);
    setInterval(function () {
        if (opacity.get() < 1.0) {
            opacity.set(opacity.get() + 0.2)
        }
        const currTime = new Date().getTime();
        if (endTime - currTime <= 500.0) {
            opacity.set((endTime - currTime) / 500.0)
        }
    }, 50)
    return <div className={parseClassName(message)} style={{ opacity: opacity }}> {parseAlertMessage(message)}</div >
}
