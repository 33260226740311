import * as React from 'karet';
import * as U from 'karet.util';
import { ColumnSelector } from '../Selectors';
import { locKey, locText, locKeyStatic } from '../../_helpers';
import { CloseButton, ActionButtonWide } from '../BasicElements';
import "./ImportUsers.scss"

const _visible = U.atom(false)
const _onImport = U.atom(null)
const _rows = U.atom([])
const _columns = U.atom([])
const _users = U.atom([])
const _failedUsers = U.atom([])

export const ImportUsers = () => {
    _columns.observe((columns) => {
        convertUsers(columns, _rows.get())
    })
    _rows.observe((rows) => {
        convertUsers(_columns.get(), rows)
    })
    return (<React.Fragment>
        {U.mapValue(visibleValue => visibleValue ?
            <div className="ImportUsers">
                <div className="TopBar">
                    <h3>{locKey("importInterviewees")}</h3>
                </div>
                <div className="ContentBar">
                    <div className="ContentColumn">
                        <div className="Columns">
                            <div className="HeaderName">{locText("column")}</div>
                            <div className="HeaderName">{locText("infoType")}</div>
                            {U.mapElems((column) =>
                                <React.Fragment>
                                    <div className="ColumnName">
                                        {U.view("name")(column)}
                                    </div>
                                    <div className="ColumnValue">
                                        <ColumnSelector value={U.view("value")(column)} />
                                    </div>
                                </React.Fragment>, _columns)}
                        </div>

                        <h4>{locKey("toImport")} ({U.mapValue((users) => users.length, _users)} {locKeyStatic("pcs")})</h4>
                        <div className="Users">
                            <div className="HeaderName">{locText("firstName")}</div>
                            <div className="HeaderName">{locText("lastName")}</div>
                            <div className="HeaderName">{locText("phoneNumber")}</div>
                            <div className="HeaderName">{locText("email")}</div>
                            <div className="HeaderName">{locText("suborganization")}</div>
                            <div className="HeaderName">{locText("language")}</div>
                            {U.mapElems(u =>
                                <>
                                    <div>{U.view("firstName")(u)}</div>
                                    <div>{U.view("lastName")(u)}</div>
                                    <div>{U.view("phoneNumber")(u)}</div>
                                    <div>{U.view("email")(u)}</div>
                                    <div>{U.view("unit")(u)}</div>
                                    <div>{U.view("language")(u)}</div>
                                </>, _users)}
                        </div>
                        <h4>{locKey("rejectImport")} ({U.mapValue((failed) => failed.length, _failedUsers)} {locKeyStatic("pcs")})</h4>
                        <div className="Users">
                            <div className="HeaderName">{locText("firstName")}</div>
                            <div className="HeaderName">{locText("lastName")}</div>
                            <div className="HeaderName">{locText("phoneNumber")}</div>
                            <div className="HeaderName">{locText("email")}</div>
                            <div className="HeaderName">{locText("suborganization")}</div>
                            <div className="HeaderName">{locText("language")}</div>
                            {U.mapElems(u =>
                                <>
                                    <div>{U.view("firstName")(u)}</div>
                                    <div>{U.view("lastName")(u)}</div>
                                    <div>{U.view("phoneNumber")(u)}</div>
                                    <div>{U.view("email")(u)}</div>
                                    <div>{U.view("unit")(u)}</div>
                                    <div>{U.view("language")(u)}</div>
                                </>, _failedUsers)}
                        </div>
                    </div>
                </div>
                <div className="BottomBar">
                    <ActionButtonWide onClick={() => {
                        _visible.set(false)
                        _onImport.get()(_users.get())
                    }} title={locKey("doImport")} />
                    <CloseButton onClick={() => _visible.set(false)} title={locKey("cancel")} />
                </div>
            </div>
            : null, _visible)}
    </React.Fragment>)
}

const mapNameToValue = (name) => {
    const mappings = [
        ["etu", "firstName"], ["suku", "lastName"],
        ["nimi", "name"], ["puh", "phoneNumber"],
        ["sähkö", "email"], ["posti", "email"], ["mail", "email"],
        ["ryhmä", "unit"], ["kieli", "language"], ["Kieli", "language"],
        ["ali", "unit"], ["Ali", "unit"]
    ]
    const nameVal = mappings.find(nameVal => name.indexOf(nameVal[0]) !== -1)
    if (nameVal) {
        return nameVal[1]
    }
    return "group"
}

const isValidUser = (u) => {
    return u.firstName && u.firstName.length > 0 &&
        u.lastName && u.lastName.length > 0 &&
        u.email && u.email.length > 0 &&
        (!u.language || ['fi', 'en', 'sv', 'ee'].indexOf(u.language) !== -1) &&
        /^[\w-\+\d\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(u.email) &&
        (!u.phoneNumber || /^[0-9|\+|\-].*$/.test(u.phoneNumber));
}

const convertUsers = (columns, rows) => {
    const allUsers = rows.map((row) => {
        return columns.reduce((cols, col, index) => {
            if (col.value === "name") {
                if (!row[index] || row[index].indexOf(" ") === -1) {
                    row[index] = "<puuttuu> <puuttuu>"
                }
                cols["firstName"] = (row[index] || " ").split(" ")[0]
                cols["lastName"] = (row[index] || " ").split(" ")[1]
            } else if (col.value === "language") {
                cols["language"] = (row[index] || "fi").toLowerCase()
            }
            else if (col.value === "group") {
                if (!("groups" in cols)) {
                    cols["groups"] = []
                }
                if (row[index]) {
                    cols["groups"].push({ name: col.name, value: row[index].toString() })
                }
            } else {
                cols[col.value] = (row[index] || "").toString()
            }
            return cols
        }, {})
    })
    _users.set(allUsers.filter(u => isValidUser(u)))
    _failedUsers.set(allUsers.filter(u => !isValidUser(u)))
}

export const showImportUsers = (columns, rows, onImport) => {
    _rows.set(rows)
    _visible.set(true)
    _onImport.set(onImport)
    if (_columns.get().length === 0) {
        const mappedCols = columns.map((name) => {
            return { name, value: mapNameToValue(name) }
        })
        _columns.set(mappedCols)
    } else {
        _columns.set(_columns.get())
    }
}