
import * as React from 'react';
import { FaTrash } from 'react-icons/fa';
import { GroupSelector } from './GroupSelector';
import { locKey } from '../_helpers/ui';
import './ValueList.css';
import { AddButton } from '../_components/BasicElements';
import { useState } from 'react';

export const ValueList = ({ groups, values, setValues }) => {
    const [newName, setNewName] = useState("")
    const [newValue, setNewValue] = useState("")
    return (<div className='ValueNameList'>
        <div className='NameHeading'>{locKey("name")}</div>
        <div className='ValueHeading'>{locKey("value")}</div>
        <div className='FunctionsHeading'></div>
        {values.map((value) => <React.Fragment>
            <div className='Name'>{value.name}</div>
            <div className='Value'>{value.value}</div>
            <div><FaTrash className='Remove' onClick={() => setValues(values.filter((v => v.value !== value.value)))} /></div>
        </React.Fragment>)}
        <GroupSelector groups={groups || []} selectedGroup={newName} setSelectedGroup={setNewName} />
        <div className='Value'>
            <input onChange={(evt) => setNewValue(evt.target.value)} value={newValue} />
        </div>
        <AddButton onClick={() =>
            setValues([{name: newName, value: newValue}].concat(values))}/>
    </div >)
}