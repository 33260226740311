import * as React from 'react';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { TextField } from '@material-ui/core';
import './DateTime.scss';
import moment from 'moment';

export const WeekDayToName = (value) => {
    switch (value) {
        case 0: return ['Sunnuntai', 'Su']
        case 1: return ['Maanantai', 'Ma']
        case 2: return ['Tiistai', 'Ti']
        case 3: return ['Keskiviikko', 'Ke']
        case 4: return ['Torstai', 'To']
        case 5: return ['Perjantai', 'Pe']
        case 6: return ['Lauantai', 'La']
        default: return ['N/A', 'N/A']
    }
}

export const DigitEdit = ({ value, onIncrement, onDecrement }) => {
    return (<div className="DigitEdit">
        <FaCaretUp onClick={() => onIncrement()} className="Tick" />
        <div className="Value">{value}</div>
        <FaCaretDown onClick={() => onDecrement()} className="Tick" />
    </div>)
}

export const DateTime = ({ value, setValue }) => {
    return (<TextField
        type="datetime-local"
        value={moment(value).format("YYYY-MM-DDTHH:mm")}
        onChange={(evt) => {
            setValue(evt.target.value)
        }}
        variant="outlined"
        margin="dense"
    />)
}