import * as React from 'karet';
import * as U from 'karet.util';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { TextField } from '@material-ui/core';
import './DateTime.scss';

export const WeekDayToName = (value) => {
    switch (value) {
        case 0: return ['Sunnuntai', 'Su']
        case 1: return ['Maanantai', 'Ma']
        case 2: return ['Tiistai', 'Ti']
        case 3: return ['Keskiviikko', 'Ke']
        case 4: return ['Torstai', 'To']
        case 5: return ['Perjantai', 'Pe']
        case 6: return ['Lauantai', 'La']
        default: return ['N/A', 'N/A']
    }
}

export const DigitEdit = ({ value, onIncrement, onDecrement }) => {
    return (<div className="DigitEdit">
        <FaCaretUp onClick={() => onIncrement()} className="Tick" />
        <div className="Value">{value}</div>
        <FaCaretDown onClick={() => onDecrement()} className="Tick" />
    </div>)
}

export const DateTime = ({ value }) => {
    const interValue = U.atom()
    if (!value.get()) {
        interValue.set(toDateTimeString(new Date()))
    } else {
        const date = new Date(value.get())
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
        interValue.set(toUTCDateTimeString(date))
    }
    return (<>{U.mapValue(dateStr => <TextField
        type="datetime-local"
        value={dateStr.substr(0, 16)}
        onChange={(evt) => {
            interValue.set(evt.target.value);
            value.set(evt.target.value + getTimeZone())
        }}
        variant="outlined"
        margin="dense"
    />, interValue)}</>)
}


function getTimeZone() {
    const minutes = new Date().getTimezoneOffset()
    return (minutes < 0 ? "+" : "-") + Math.abs(minutes / 60).toString().padStart(2, "0") + ":00"
}

function toUTCDateTimeString(date) {
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}T` +
        date.getUTCHours().toString().padStart(2, "0") + ":" +
        date.getUTCMinutes().toString().padStart(2, "0")
}

function toDateTimeString(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T` +
        date.getHours().toString().padStart(2, "0") + ":" +
        date.getMinutes().toString().padStart(2, "0")
}