import * as React from 'karet';
import * as regular from 'react';
import * as U from 'karet.util';
import * as R from 'ramda';
import * as L from 'partial.lenses';
import { ActionButton, AddButton } from '../BasicElements';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { ConditionTypeSelector, OperatorSelector, TimeSelector } from '../Selectors';
import { QuestionareItemSelector } from '../QuestionareSelector';
import { NumberEdit } from '../TextEdit';
import { MdDelete } from 'react-icons/md';
import { FaQuestionCircle, FaBuffer } from 'react-icons/fa';
import { locKeyPlain } from '../../_helpers/ui';
import "./ConditionEditor.scss"

let open, setOpen
let scroll, setScroll

let _condition = null
let _questionare = null

export const showConditionEditor = ({ parent, questionare }) => {
    _condition = U.view(['condition', L.defaults({ type: 'None', conditions: [] })])(parent);
    _questionare = questionare
    setOpen(true);
    setScroll('paper');
};

const IfCondition = ({ condition, questionare, showRemove, showTitle, showHeader }) => {
    const source = U.view('source')(condition)
    const operator = U.view('operator')(condition)
    const parameter = U.view('parameter')(condition)
    const time = U.view('time')(source)

    operator.skip(1).observe((value) => {
        if (value === 'Between') {
            parameter.set({ min: 0, max: 0 })
        } else {
            parameter.set(0)
        }
    })
    return (<div className="ConditionEditor">
        {showTitle ? <div className="Heading">{locKeyPlain("followingCondition")}</div> : null}
        {showHeader ? <>
            <div>{locKeyPlain("source")}</div>
            <div>{locKeyPlain("condition")}</div>
            <div>{locKeyPlain("value")}</div>
            <div></div>
            <div></div>
        </> : null}
        <QuestionareItemSelector questionare={questionare} value={source} onSelect={(value) => source.set(value)} />
        <TimeSelector value={time} />
        <OperatorSelector value={operator} />
        {U.mapValue(operator =>
            operator === 'Between' ? <>
                <NumberEdit value={U.view([L.removable('min'), 'min', L.defaults(0)], parameter)} />
                <NumberEdit value={U.view([L.removable('max'), 'max', L.defaults(0)], parameter)} />
            </> : <NumberEdit value={parameter} />, operator)}
        {U.mapValue(show => show ? <MdDelete className="Remove" onClick={() => condition.remove()} /> : <div></div>, showRemove)}
    </div>)
}

const ListCondition = ({ condition, questionare, title }) => {
    const conditions = U.view('conditions')(condition)
    return (<>
        <div className="Heading">{title}</div>
        {U.mapElems((condition, index) =>
            <Condition key={index} condition={condition} showHeader={index === 0}
                topMost={false} questionare={questionare}
                showRemove={index > 0} />, conditions)}
        <div className="Add">
            <AddButton onClick={() =>
                conditions.modify(R.append({ type: "If", source: { time: "Current", type: "Questionare", value: questionare.get() ? questionare.get().id : null }, operator: "GreaterThan", parameter: 0 }))} />
        </div>
    </>)
}

const Condition = ({ condition, questionare, showRemove, topMost }) => {
    const type = U.view('type')(condition)
    return (<>{
        U.mapValue((value) => {
            switch (value) {
                case "If":
                    return IfCondition({ condition, questionare, showRemove, showHeader: topMost, showTitle: topMost })
                case "And":
                    return ListCondition({ condition, questionare, showHeader: topMost, showRemove, title: "Kaikki seuraavat ehdot täyttyvät" })
                case "Or":
                    return ListCondition({ condition, questionare, showHeader: topMost, showRemove, title: "Joku seuraavista ehdoista täyttyy" })
                default:
                    return <></>
            }
        }, type)
    }</>)
}

export function ConditionEditor() {
    [open, setOpen] = regular.useState(false);
    [scroll, setScroll] = regular.useState('paper');

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className="ConditionEditor">
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">
                    <span className="ExerciseTitle">{locKeyPlain("modifyConditions")}</span>
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div className="ConditionHeader">
                        <div className="Name">{locKeyPlain("conditionTemplate")}</div>
                        <ConditionTypeSelector value={_condition} />
                    </div>
                    <div className="Condition">
                        <Condition condition={_condition} questionare={_questionare} topMost={true} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ActionButton onClick={handleClose} title={"Valmis"} />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function ConditionEditorOpen({ parent, questionare }) {
    return <>{U.mapValue((value) =>
        value.condition && value.condition.type !== "None" ?
            <FaBuffer className="Condition"
                onClick={() => showConditionEditor({ parent, questionare })} /> :
            <FaQuestionCircle className="Condition"
                onClick={() => showConditionEditor({ parent, questionare })} />, parent)}</>
}