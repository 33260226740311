import * as React from 'react'
import { orgService } from '../_services/organization.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { UserList } from '../_components/UserList';
import { QuestionareList } from '../_components/QuestionareList';
import { QuestionareSender } from '../_components/QuestionareSender';
import { ScheduleList } from '../_components/ScheduleList';
import { TemplateList } from '../Templates/TemplateList';
import { DropDown } from '../_components/DropDown';
import { pick } from 'underscore';
import loc from './Localizations.json'
import { getLanguage, locKeyLang, locKeyStatic, locText } from '../_helpers/ui';
import './OrganizationDetails.scss';
import { CalendarSelector, OrganizationSelector } from '../_tcomponents/OrganizationSelector';
import styled from 'styled-components'
import { ActionButton } from '../_components/BasicElements';
import { useState } from 'react';
import { debounce, useEffectUpdate } from '../_tcomponents/Selectors';
import { EditableTextField } from '../_tcomponents/TextEdit';
import { Select } from '@material-ui/core';

const updateOrganization = ((organizationValue, onChange) => {
    orgService.updateOrganization(organizationValue).then(() => {
        onChange(organizationValue)
    }).catch((error) => {
        if (error.data && loc[error.data.errorCode]) {
            pushAlert(locText(loc[error.data.errorCode]), 5)
        } else {
            pushAlert('Päivitys epäonnistui.', 5);
        }
    })
})

const updateOrganizationDebounced = debounce(updateOrganization, 1000)

const RegisterInfo = styled.div`
    font-weight:bold;
    background-color:#ffffff;
    border-radius:0.5em;
    padding:1.0em;
    display:grid;
    grid-template-columns: 1fr 10em;
    align-items:center;
`

export const OrganizationDetails = ({ onChange, organizationInitial, rights }) => {
    const [allowRegisterFrom, setAllowRegisterFrom] = useState(organizationInitial.allowRegisterFrom)
    const [organization, setOrganization] = useState(organizationInitial)
    const [recursive, setRecursive] = useState(false)
    const registerEnabled = allowRegisterFrom !== null && allowRegisterFrom !== undefined

    useEffectUpdate(() => {
        updateOrganizationDebounced(pick(organization, ["parentId", "name", "id", "type", "calendarIds"]), onChange);
    }, [organization])

    React.useEffect(() => {
        setOrganization(organizationInitial)
    }, [organizationInitial])

    return (<div className='OrganizationDetails'>
        <h3><EditableTextField value={organization} setValue={setOrganization} member={'name'} /></h3>

        <DropDown title={locKeyLang("parentOrganization", getLanguage())} initial={false}>
            { organization.parentId && <RegisterInfo>
                <OrganizationSelector organization={organization.parentId} 
                    setOrganization={(value) => setOrganization({...organization, parentId: value})} />
            </RegisterInfo>}
        </DropDown>

        <DropDown title={locKeyLang("registering", getLanguage())} initial={false}>
        <RegisterInfo>            
            {registerEnabled && <>
                    <div>
                        <a target="_blank" href={`/register?hash=${organization.hash}`}>{locKeyStatic("selfRegisterLinkInfo")}</a>
                    </div>
                    <ActionButton title={locKeyStatic("block")}
                        onClick={() => {
                            orgService.setAllowRegisterFrom(organization.id, null)
                            setAllowRegisterFrom(null)
                        }}/>
                    </>}
                {!registerEnabled &&
                    <>
                        <div>{locKeyStatic("registerNotAllowed")}</div>
                        <ActionButton title={locKeyStatic("allow")}
                            onClick={() => {
                                orgService.setAllowRegisterFrom(organization.id, "")
                                setAllowRegisterFrom("")
                            }}/>
                    </>} 
            </RegisterInfo>         
        </DropDown>

        <DropDown title={locKeyLang("calendars", getLanguage())} initial={false}>
            <RegisterInfo>
                <CalendarSelector organization={organization} setCalendars={(calendarIds) => {
                    updateOrganizationDebounced(pick({...organization, calendarIds}, 
                        ["parentId", "name", "id", "type", "calendarIds"]), onChange);
                }} />
            </RegisterInfo>
        </DropDown>

        <DropDown title={locText(loc.users)} initial={true}>
            {rights.indexOf('userManagement') !== -1 ? <div className='Functions'>
                <UserList groups={organization.groups} showRights={true} showWelcome={true}
                    adminOrganization={organization.id} />
            </div> : locText(loc.userRightsNeeded)
            }
        </DropDown>

        <DropDown title={locText(loc.respondents)} initial={true}>
            {rights.indexOf('userManagement') !== -1 ? <div className='Functions'>
                <div className="ShowRecursive">
                    <input type="checkbox" checked={recursive} onChange={() => setRecursive(!recursive)} />
                    <span>{locKeyStatic("showSuborganizations")}</span>
                </div>
                <UserList groups={organization.groups} recursive={recursive}
                    intervieweeOrganization={organization.id}
                    />
            </div> : locText(loc.userRightsNeeded)}
        </DropDown>

        <DropDown title={locText(loc.messageTemplates)}>
            {rights.indexOf('templateManagement') !== -1 ? <div className='Functions'>
                <TemplateList templatesValue={organization.templates} organization={organization.id} />
            </div> : locText(loc.userRightsNeeded)}
        </DropDown>

        <DropDown title={locText(loc.questionares)}>
            {rights.indexOf('questionareManagement') !== -1 ? <div className='Functions'>
                <QuestionareList questionaresValue={organization.questionares} organization={organization.id} />
            </div> : locText(loc.userRightsNeeded)}
        </DropDown>

        {organization.type !== 'Root' && organization.type !== 'System' ?
            <DropDown title={locText(loc.sendQuestionare)}>
                <QuestionareSender organization={organization.id} templatesValue={organization.templates} />
            </DropDown> : null}

        {organization.type !== 'Root' && organization.type !== 'System' ?
            <DropDown title={locText(loc.scheduleQuestionare)}>
                {rights.indexOf('scheduleManagement') !== -1 ? <div className='Functions'>
                    <ScheduleList schedulesValue={organization.schedules} templatesValue={organization.templates}
                        organization={organization.id} />
                </div> : locText(loc.userRightsNeeded)}
            </DropDown> : ''}
    </div>)
}
