import React from 'react';
import { userService } from '../_services/user.service';
import { locKeyStatic } from '../_helpers'
import { connect } from 'react-redux'
import { LoginButton, ActionButton } from '../_components/BasicElements';
import './RegisterPage.scss'
import styled from 'styled-components';
const queryString = require('query-string');

const HelpText = styled.div`
    margin-top:1.0em;
    margin-bottom:1.0em;
`


class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            phase: 'register',
            firstName: '',
            lastName: '',
            password: '',
            passwordAgain: '',
            phoneNumber: '',
            smsCode: '',
            mailCode: '',
            error: false
        };

    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const hash = queryString.parse(this.props.location.search)["hash"];
        this.setState({ phase: 'registerSubmitted' });
        const { email, firstName, lastName, password, passwordAgain, phoneNumber } = this.state;
        if (email && firstName && lastName && phoneNumber && password === passwordAgain) {
            userService.register(email, firstName, lastName, phoneNumber, password, hash).then(() => {
                this.setState({ phase: 'verify', error: false})
            }).catch(() => {
                this.setState({ error: true, phase: 'register' })
            })
        }
    }

    handleSubmitCodes(e) {
        e.preventDefault();
        this.setState({ phase: 'verifySubmitted' });
        const { smsCode, mailCode } = this.state;
        if (smsCode, mailCode) {
            userService.registerVerify(smsCode, mailCode).then(() => {
                this.setState({ phase: 'done'})
            }).catch(() => {
                this.setState({ error: true, phase: 'register', smsCode: '', mailCode: '' })
            })
        }
    }

    isValidPassword(password) {
        return password.length >= 8 &&
            /[0-9]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[a-z]/.test(password)
    }

    render() {
        const { phase, email, firstName, lastName, password, passwordAgain, error,
            phoneNumber, smsCode, mailCode } = this.state;
        return (
            <div className="ScreenRegister">
                <div className="BackgroundRegister">
                    <h2>{locKeyStatic(phase === "register" ? "register" : 
                        (phase === "verify" ? "verifyRegistration" : "registrationDone"))}</h2>
                    <img className="Logo" src="images/cauhacaresolutions.png" alt="Cauhacare solutions logo"></img>
                   
                    {phase === "register" && <>
                        <form name="form" onSubmit={this.handleSubmit.bind(this)}>
                            <div className={'form-group'}>
                                <label htmlFor="email">{locKeyStatic("email")}</label>
                                <input id="email" type="email" className="form-control" name="email" value={email} onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className={'form-group'}>
                                <label htmlFor="firstName">{locKeyStatic("firstName")}</label>
                                <input id="firstName" type="text" className="form-control" name="firstName" value={firstName} onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className={'form-group'}>
                                <label htmlFor="lastName">{locKeyStatic("lastName")}</label>
                                <input id="lastName" type="text" className="form-control" name="lastName" value={lastName} onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className={'form-group'}>
                                <label htmlFor="phoneNumber">{locKeyStatic("phoneNumber")}</label>
                                <input id="phoneNumber" type="text" className="form-control" name="phoneNumber" value={phoneNumber} onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className={'form-group'}>
                                <label htmlFor="password">{locKeyStatic("password")}</label>
                                <input id="password" type="password" className="form-control" name="password" value={password} onChange={this.handleChange.bind(this)} />
                            </div>
                            {!this.isValidPassword(password) && <div className="Error">{locKeyStatic("passwordHelp")}</div>}
                            <div className={'form-group'}>
                                <label htmlFor="passwordAgain">{locKeyStatic("passwordAgain")}</label>
                                <input id="passwordAgain" type="password" className="form-control" name="passwordAgain" value={passwordAgain} onChange={this.handleChange.bind(this)} />
                            </div>
                            {password !== passwordAgain && password.length > 0 && <div className="Error">{locKeyStatic("samePasswordAgain")}</div>}
                            <div className="Controls">
                                <LoginButton title={locKeyStatic("register")} />
                            </div>
                            {error && <div className="Error">{locKeyStatic("registerFailed")}</div>}
                            <div className="Controls"></div>
                        </form>
                    </>}

                    {phase === "verify" && <>
                        <HelpText>{locKeyStatic("verificationHelp")}</HelpText>
                        <form name="form" onSubmit={this.handleSubmitCodes.bind(this)}>
                            <div className={'form-group'}>
                                <label htmlFor="mailCode">{locKeyStatic("mailCode")}</label>
                                <input id="mailCode" type="text" className="form-control" name="mailCode" value={mailCode} onChange={this.handleChange.bind(this)} />
                            </div>
                            <div className={'form-group'}>
                                <label htmlFor="smsCode">{locKeyStatic("smsCode")}</label>
                                <input id="smsCode" type="text" className="form-control" name="smsCode" value={smsCode} onChange={this.handleChange.bind(this)} />
                            </div>
                            {password !== passwordAgain && password.length > 0 && <div className="Error">{locKeyStatic("samePasswordAgain")}</div>}
                            <div className="Controls">
                                <LoginButton title={locKeyStatic("verify")} />
                            </div>
                            {error && <div className="Error">{locKeyStatic("registerFailed")}</div>}
                        </form>
                    </>}

                    {phase === "done" && <>
                        <HelpText>{locKeyStatic("registrationDoneInfo")}</HelpText>                            
                        <div className="Controls">
                            <ActionButton title={locKeyStatic("login")} onClick={() => window.location.href="/login"} />
                        </div>
                    </>}

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage }; 