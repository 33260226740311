
import moment from 'moment-timezone'
import { useEffect, useRef } from 'react'

export const slideTopColorDark = '#418875'
export const slideBottomColorDark = '#366f5f'

export const momentLocal = (date) => {
    const m = moment(date)
    m.startOfWeek = () => {
        return m.startOf("week").add({days: 1})
    }
    m.endOfWeek = () => {
        return m.endOf("week").add({days: 1})
    }
    return m
}

export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
}