import * as React from 'karet';
import { FaSchool, FaFolder, FaChair, FaBuilding, FaSitemap, FaBusinessTime } from "react-icons/fa";

export const typeToLogo = (type) => {
    switch (type) {
        case 'School': return <FaSchool style={{ cursor: 'pointer' }} />
        case 'Company': return <FaBuilding style={{ cursor: 'pointer' }} />
        case 'Class': return <FaChair style={{ cursor: 'pointer' }} />
        case 'Site': return <FaSitemap style={{ cursor: 'pointer' }} />
        case 'Consulting': return <FaBusinessTime style={{ cursor: 'pointer' }} />
        default: return <FaFolder style={{ cursor: 'pointer' }} />
    }
}

export const typeToChildName = (type) => {
    switch (type) {
        case 'School': return 'Luokat'
        case 'Company': return 'Osat'
        case 'Class': return ''
        case 'Site': return ''
        case 'Consulting': return 'Konsultointi'
        case 'System': return 'Osat'
        default: return 'Organisaatiot'
    }
}

export const typeToName = (type) => {
    switch (type) {
        case 'School': return 'Koulu'
        case 'Company': return 'Yritys'
        case 'Class': return 'Ryhmä'
        case 'Site': return 'Yksikkö'
        case 'Consulting': return 'Konsultointi'
        case 'System': return 'Järjestely'
        default: return 'Kaikki';
    }
}

export const parentTypeToSubTypes = (parentType) => {
    switch (parentType) {
        case 'School': return ['Class']
        case 'Company': return ['Company', 'Site']
        case 'Site': return ['Company', 'Site']
        case 'Consulting': return ['Company', 'Site', 'School', 'Class']
        case 'System': return ['Company', 'School', 'System']
        default: return ['Company', 'Site', 'School', 'Class', 'Consulting', 'System']
    }
}

export const typeHasChildren = (type) => {
    return type === 'Root' || type === 'Company' || type === 'School' || type === 'Site' ||
        type === 'Consulting' || type === 'System';
}