import * as React from 'karet';
import * as U from 'karet.util';
import './RadioButtons.css'

export const RadioButtons = ({ selected, defaultTitle, buttonNames }) => {
    return (<React.Fragment>
        <button className={U.mapValue(selectedValue =>
            selectedValue === null ? 'SelectedRadioButton' : 'RadioButton',
            selected)} onClick={() => selected.set(null)}>{defaultTitle}</button>
        {
            U.mapValue((buttonNamesValue) => {
                return buttonNamesValue.map((buttonName) => <button key={buttonName} className={U.mapValue(selected =>
                    selected === buttonName ? 'SelectedRadioButton' : 'RadioButton',
                    selected)}
                    onClick={() => selected.set(buttonName)}>
                    {buttonName}
                </button>
                )
            }, buttonNames)
        }
    </React.Fragment>)
}