import * as React from 'karet';
import * as U from 'karet.util';
import { NumberEdit, TextEdit } from './TextEdit';
import { FaEdit, FaTrash, FaSyncAlt, FaStop } from 'react-icons/fa';
import { scheduleService } from '../_services/schedule.service';
import { addItemToAtomArray, locKey, locText, locKeyStatic } from '../_helpers/ui';
import { pushAlert } from '../_reducers/alert.reducer';
import { QuestionareSelector } from './QuestionareSelector';
import { WeekdaySelector, IntervalSelector } from './Selectors';
import { TemplateSelector } from './TemplateSelector';
import { Checkbox } from './Checkbox';
import { formatTime } from '../_helpers/ui';
import * as R from 'ramda';
import { DateTime } from './DateTime';
import { ParameterTemplateSelector } from './ParameterTemplateSelector';
import { AddButton, ActionButton } from './BasicElements';
import { ConditionEditorOpen } from './editors/ConditionEditor';
import { MdDelete } from 'react-icons/md';
import './ScheduleList.scss';
import { qService } from '../_services/questionare.service';
import { NormMaterialSelector } from './CircleSelector';
import { libraryService } from '../_services/library.service';

const createSchedule = ({ active, schedules, type, organization, parameters, done }) => {
    scheduleService.createSchedule(type, active, organization, parameters)
        .then((schedule) => {
            addItemToAtomArray(schedules, schedule);
            done();
        });
}

const removeSchedule = (schedule) => {
    scheduleService.removeSchedule(schedule.get().id)
        .then(() => schedule.remove())
        .catch(() => pushAlert('Ajastuksen poistaminen epäonnistui.', 5))
}

const updateSchedule = (scheduleValue) => {
    scheduleService.updateSchedule(scheduleValue).catch((error) => {
        pushAlert('Ajastuksen päivitys epäonnistui.', 5)
    });
}

const StaticSchedule = ({ schedule, edit }) => {
    const id = U.view('id')(schedule);
    const parameters = U.view('parameters')(schedule);
    const active = U.view('active')(schedule);
    const firstTime = U.view('firstTime')(parameters);
    const lastTime = U.view('lastTime')(parameters);
    const questionareName = U.view('questionareName')(parameters);
    return (<div key={id.get()} className='Schedule'>
        <div className='Questionare'>
            {questionareName}&nbsp;-&nbsp;
            {U.mapValue((value) => value ?
                <React.Fragment>
                    <span className='Running'>{locKey("running")}</span>
                    <FaSyncAlt className='Spinning' />
                </React.Fragment> :
                <React.Fragment>
                    <span className='Stopped'>{locKey("stopped")}</span>
                    <FaStop className='NotSpinning' />
                </React.Fragment>, active)}
        </div>
        <div className='FirstTime'>{formatTime(firstTime, locKeyStatic("noTime"))}</div>
        <div className='LastTime'>{formatTime(lastTime, locKeyStatic("noRepeat"))}</div>
        <div className='Operations'>
            <div className='Remove' onClick={() => removeSchedule(schedule)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

const EditSchedule = ({ organization, schedule, edit, templates }) => {
    const parameters = U.view('parameters')(schedule);
    const active = U.view('active')(schedule);
    const plan = U.view('plan')(schedule);
    const planTitle = U.view('title')(plan)
    return <div className='EditSchedule'>
        <div className='Label'>{locKey("type")}</div>
        <div>{locText(planTitle)}</div>
        <div className='Label'>{locKey("running")}</div>
        <Parameter parameter={{ 'type': 'boolean' }} target={active} />
        <div className='Operations'>
            <div className='Remove' onClick={() => removeSchedule(schedule)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
        <Plan organization={organization} plan={plan} templates={templates} parameterTarget={parameters} />
    </div>
}

const TimeInterval = ({ parameter, parentTarget, target }) => {
    const intervalLength = U.view(parameter.numberName)(parentTarget)
    if (!target.get()) {
        intervalLength.set(parameter.default || 1)
        target.set(parameter.defaultInterval)
    }
    return <div className='Interval'>
        <NumberEdit value={intervalLength} />
        <IntervalSelector length={intervalLength} value={target} />
    </div>
}

const Parameter = ({ organization, templates, parameter, parentTarget, target }) => {
    switch (parameter.type) {
        case 'questionareId':
            return <QuestionareSelector questionareId={target} questionareName={U.atom('')} organization={organization} />
        case 'templateId':
            return <TemplateSelector template={target} templates={templates.get()} />
        case 'mediums':
            if (!target.get()) {
                target.set([parameter.default])
            }
            return <div className='Mediums'>
                <Checkbox selectedOptions={target} value='email' /><span>{locKey("email")}</span>
                <Checkbox selectedOptions={target} value='sms' /><span>{locKey("SMS")}</span>
            </div>
        case 'dateTime':
            return <DateTime value={target} />
        case 'weekDay':
            return <WeekdaySelector value={target} />
        case 'timeInterval':
            return <TimeInterval parameter={parameter} parentTarget={parentTarget} target={target} />
        case 'percentage':
            target.set(parameter.default)
            return <div>
                <NumberEdit value={target} /> %
            </div>
        case 'dateTimeList':
            if (!target.get()) {
                target.set([])
            }
            return (<div>
                {U.mapElems(dtValue => <div className="DateTimeList">
                    <DateTime value={dtValue} />
                    <MdDelete className="Remove" onClick={() => dtValue.remove()} />
                </div>, target)}
                <AddButton title={"lisää"} onClick={() => target.modify(R.append(null))} />
            </div>)
        case 'numberList':
            const currentValue = U.atom(1)
            if (!target.get()) {
                target.set([])
            }
            return <div>
                {U.mapValue((value) => <React.Fragment>
                    {value.length > 0 ? value.map((i, index) => <div className='NumberList'>
                        <div>{locKeyStatic("inXDays", { days: i })}</div>
                        <FaTrash className='ReminderRemove'
                            onClick={() => target.modify(R.remove(index, 1))} />
                    </div>) : <div>{locKeyStatic("noReminders")}</div>}
                </React.Fragment>, target)}
                <div>
                    <NumberEdit value={currentValue} />
                    <AddButton onClick={() => target.modify(R.append(currentValue.get()))} title={locKeyStatic("addReminder")} />
                </div>
            </div>
        case 'additionalQueries':
            if (!target.get()) {
                target.set([])
            }
            const questionare = U.atom(null)
            const questionareId = U.view(parameter.questionareId)(parentTarget)
            questionareId.observe((id) => {
                qService.getQuestionare(id).then((questionareValue) => {
                    questionare.set(questionareValue)
                })
            })
            return (<div>
                {U.mapElems(item => {
                    const tag = U.view("tag")(item)
                    const interval = U.view("interval")(item)
                    return (<div className="AdditionalQueries">
                        <MdDelete className="Remove" onClick={() => item.remove()} />
                        <div className="Label">{locKey("tag")}</div>
                        <div><TextEdit value={tag} /><ConditionEditorOpen parent={item} questionare={questionare} /></div>
                        <div className="Label">{locKey("interval")}</div><TimeInterval parameter={{ numberName: "intervalLength", default: 1, defaultInterval: "Months" }}
                            parentTarget={item} target={interval} /></div>)
                }, target)}
                <AddButton title={locKey("add")} onClick={() => target.modify(
                    R.append({ tag: "", interval: "Months", intervalLength: 1 }))} />
            </div >)
        case 'boolean':
            const id = Math.random().toString()
            return <div className='Repeating'>
                <input type="radio" name={`boolean-${id}`}
                    checked={target}
                    onClick={() => target.set(true)} />
                <span>{locKey("yes")}</span>
                <input type="radio" name={`boolean-${id}`}
                    checked={U.mapValue((target) => !target, target)}
                    onClick={() => target.set(false)} />
                <span>{locKey("no")}</span>
            </div>
        case 'normMaterial':
            let circles = U.atom([])
            libraryService.searchLibraryItems(organization, "", "NormMaterial").then((circlesValue) => {
                circles.set(circlesValue)
            })
            return <NormMaterialSelector circles={circles} circle={target} allowEmpty={true} />
        default:
            return <div>{locKey("unknown")}</div>
    }
}

const Plan = ({ plan, parameterTarget, organization, templates }) => {
    return <React.Fragment>
        {U.mapValue((parameters) => {
            return Object.keys(parameters).map((key) => {
                if (parameters[key].type === 'organizationId') {
                    U.view(key)(parameterTarget).set(organization)
                    return ''
                }
                else
                    return (<React.Fragment>
                        <div className='Label'>{locText(parameters[key].title)}</div>
                        <div><Parameter organization={organization}
                            templates={templates} parameter={parameters[key]}
                            parentTarget={parameterTarget}
                            target={U.view(key)(parameterTarget)} />
                        </div>
                    </React.Fragment>)
            })
        }, U.view('parameters')(plan))}
    </React.Fragment>
}

const Plans = ({ schedules, plans, selectedPlan, organization, templates }) => {
    const parameterTarget = U.atom({})
    const active = U.atom(false)
    return <div className='EditSchedule'>
        <h4>{locKey("newScheduling")}</h4>
        <div className='Label'>{locKey("type")}</div>
        {U.mapValue((plansValue) => <ParameterTemplateSelector templates={plansValue} template={selectedPlan} />, plans)}
        {
            U.mapValue((plan) => plan && plan.type !== "none" ? <Plan plan={plan} parameterTarget={parameterTarget}
                organization={organization} templates={templates} /> : '', selectedPlan)
        }
        {U.mapValue(sp => sp && sp.type !== "none" ? <>
            <div className='Label'>{locKey("running")}</div>
            <Parameter parameter={{ 'type': 'boolean' }} target={active} />
            <div class='Operations'></div>
            <ActionButton onClick={() => createSchedule({
                schedules, type: selectedPlan.get().type, organization: organization,
                active: active.get(),
                parameters: parameterTarget.get(),
                done: () => { selectedPlan.set(plans.get()[0]) }
            })} title={locKey("createScheduling")} />
        </> : null, selectedPlan)}
    </div >
}

export const Schedule = ({ schedule, organization, templates }) => {
    const edit = U.atom(false);
    schedule.skip(1).debounce(1000).observe((scheduleValue) => {
        if (edit.get()) {
            updateSchedule(scheduleValue)
        }
    });
    return (<div>
        {U.mapValue((editValue) => {
            return editValue ?
                <EditSchedule schedule={schedule} organization={organization} edit={edit}
                    templates={templates} /> :
                <StaticSchedule schedule={schedule} edit={edit} />
        }, edit)}
    </div>)
}

export const ScheduleList = ({ schedulesValue, organization, templatesValue }) => {
    const plans = U.atom([]);
    const selectedPlan = U.atom(null);
    const schedules = U.atom(schedulesValue)
    const templates = U.atom(templatesValue)
    scheduleService.getPlans(organization).then((planValues) => {
        plans.set(planValues)
        selectedPlan.set(planValues[0])
    })

    return (<div className='ScheduleList'>
        <div className='Schedule'>
            <div className='QuestionareHeading'>{locKey("questionare")}</div>
            <div className='FirstTimeHeading'>{locKey("startTime")}</div>
            <div className='LastTimeHeading'>{locKey("endTime")}</div>
            <div className='OperationsHeading'>{locKey("functions")}</div>
        </div>
        {U.mapElems((s) => <Schedule key={s.get().id} schedule={s}
            organization={organization} templates={templates} />, schedules)}
        <Plans schedules={schedules} selectedPlan={selectedPlan} plans={plans} organization={organization} templates={templates} />
    </div>)
}