import { apiUrl } from './common';

function login(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };
    return fetch(`${apiUrl}/user/login`, requestOptions)
        .then(handleResponse)
}

function sendPasswordReset(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    return fetch(`${apiUrl}/user/sendpasswordreset`, requestOptions)
        .then(handleResponse)
}

function sendWelcome(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    return fetch(`${apiUrl}/user/welcome`, requestOptions)
        .then(handleResponse)
}

function passwordReset(resetHash, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ resetHash, password })
    };
    return fetch(`${apiUrl}/user/resetpassword`, requestOptions)
        .then(handleResponse)
}

function createUser(email, adminOrganization, intervieweeOrganization) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, firstName: "tyhjä", lastName: "tyhjä", adminOrganization, intervieweeOrganization })
    };
    return fetch(`${apiUrl}/user/`, requestOptions)
        .then(handleResponse)
}

function updateUser(user) {
    const cleaned = JSON.parse(JSON.stringify(user))
    delete cleaned['twoFactorEnabled']
    delete cleaned['latestLogin']
    delete cleaned['anonymiseKey']
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cleaned)
    };
    return fetch(`${apiUrl}/user`, requestOptions)
        .then(handleResponse)
}

function updateMyself(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`${apiUrl}/user/myself`, requestOptions)
        .then(handleResponse)
}

function removeUser(email) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };
    return fetch(`${apiUrl}/user`, requestOptions)
        .then(handleResponse)
}

function getUser() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user`, requestOptions)
        .then(handleResponse)
}

function getUserWithId(userId) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user/${userId}`, requestOptions)
        .then(handleResponse)
}

function getMyItems() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user/myself/items`, requestOptions)
        .then(handleResponse)
}

function getMyItem(itemId) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user/myself/items/${itemId}`, requestOptions)
        .then(handleResponse)
}

function logout() {
    const requestOptions = {
        method: 'DELETE'
    }
    return fetch(`${apiUrl}/user/login`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        if (response.status !== 200) {
            throw response.status
        }
        const data = text && JSON.parse(text);
        return data;
    });
}

function importInterviewees(intervieweeOrganization, users) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ intervieweeOrganization, users })
    };
    return fetch(`${apiUrl}/user/import`, requestOptions)
        .then(handleResponse)
}

function setTwoFactorEnabled(enabled) {
    const requestOptions = {
        method: 'PUT'
    };
    return fetch(`${apiUrl}/user/twofactorenable/${enabled}`, requestOptions)
        .then(handleResponse)
}

function twoFactorCheck(code) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code })
    };
    return fetch(`${apiUrl}/user/twofactorcheck`, requestOptions)
        .then(handleResponse)
}

function getRecommendedExercises() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user/exercises/recommended`, requestOptions)
        .then(handleResponse)
}

function getStartedExercises() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/user/exercises/started`, requestOptions)
        .then(handleResponse)
}

function startExercise(uuid) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uuid })
    };
    return fetch(`${apiUrl}/user/exercises/started`, requestOptions)
        .then(handleResponse)
}

function saveExerciseState(uuid, state) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(state)
    };
    return fetch(`${apiUrl}/user/exercises/${uuid}/state`, requestOptions)
        .then(handleResponse)
}

function register(email, firstName, lastName, phoneNumber, password, hash) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, firstName, lastName, password, hash, phoneNumber })
    };
    return fetch(`${apiUrl}/user/register`, requestOptions)
        .then(handleResponse)
}

function registerVerify(smsCode, mailCode) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ smsCode, mailCode })
    };
    return fetch(`${apiUrl}/user/register-verify`, requestOptions)
        .then(handleResponse)
}

export const userService = {
    login,
    logout,
    getUser,
    sendPasswordReset,
    passwordReset,
    createUser,
    removeUser,
    updateUser,
    sendWelcome,
    updateMyself,
    getMyItems,
    getMyItem,
    importInterviewees,
    setTwoFactorEnabled,
    twoFactorCheck,
    getUserWithId,
    getRecommendedExercises,
    getStartedExercises,
    startExercise,
    saveExerciseState,
    register,
    registerVerify
};