import * as React from 'react';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

export const OpenToggle = ({ href, open, onChange }) => {
    return (<a onClick={() => onChange(!open)} href={href}>
        {open ?
            <FaMinusCircle className="OpenSign" /> :
            <FaPlusCircle className="OpenSign" />
        }
    </a>)
}