export const toggleAnimationClass = (id, toggleId, initial, showClass, hideClass) => {
    const toggle = document.getElementById(toggleId)
    document.getElementById(id).classList.toggle(hideClass);
    if (!initial) {
        if (toggle.classList.contains(hideClass)) {
            toggle.classList.toggle(showClass)
            toggle.classList.toggle(hideClass)
        } else {
            if (toggle.classList.contains(showClass)) {
                toggle.classList.toggle(showClass)
            }
            toggle.classList.toggle(hideClass)
        }
    } else {
        if (toggle.classList.contains(showClass)) {
            toggle.classList.toggle(hideClass)
            toggle.classList.toggle(showClass)
        } else {
            if (toggle.classList.contains(hideClass)) {
                toggle.classList.toggle(hideClass)
            }
            toggle.classList.toggle(showClass)
        }
    }
}