import * as React from 'karet';
import * as U from 'karet.util';
import { FaCircle, FaTrashAlt, FaArrowCircleLeft } from "react-icons/fa";
import { typeToLogo } from '../_helpers/organization';
import { orgService } from '../_services/organization.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { OpenToggle } from './OpenToggle';
import { toggleAnimationClass } from '../_ui/Animation';
import { CreateOrganization } from './CreateOrganization';
import { showYesNoDialog } from '../_ui/Dialog';
import './OrganizationTree.scss';

const loadSelectedOrganization = (id, selected) => {
    orgService.getOrganization(id).then((org) => {
        selected.set(org);
    }).catch(() => {
        pushAlert('Sinulla ei ole riittävästi oikeuksia.', 5);
    });
}

const showRemoveOrganization = (node, selected) => {
    showYesNoDialog({
        title: 'Poiston varmistus',
        message: `Haluatko varmasti poistaa organisaation "${node.get().name}"?`,
        parameter: null,
        onYes: () => {
            const id = node.get().id;
            orgService.removeOrganization(id).then(() => {
                const selectedId = selected.get() ? selected.get().id : null;
                if (selectedId === id) {
                    sessionStorage.removeItem("selectedOrganization");
                    selected.set(null);
                }
                node.remove();
            }).catch(() => {
                pushAlert('Sinulla ei ole riittävästi oikeuksia tai organisaatio ei ole tyhjä.', 5);
            });
        }
    })
}

const renderTree = (level, selected, node, parentNode) => {
    const open = U.atom(level === 0)
    const name = U.view('name')(node);
    const type = U.view('type')(node);
    const children = U.view('children')(node);
    const id = U.view('id')(node);
    const openNodeClass = U.mapValue((value) => value && value.id === id.get() ? 'OpenNodeSelected' : 'OpenNode', selected);
    return (<div key={id.get()} className={`Level${level}`}>
        <div className={type}>
            {U.mapValue((values) => values.length > 0 ?
                <OpenToggle open={open} href="#open" defaultOpen={level === 0}
                    localStorageId={`OrganizationOpen${id.get()}`} /> :
                <FaCircle className="NoOpenSign" />, children)}
            <div className={openNodeClass} onClick={() => loadSelectedOrganization(id.get(), selected)}>{name}</div>

            <div className={U.mapValue((value) => value === 'Root' ? "RootFunctions" : "OrganizationFunctions", type)}>
                <CreateOrganization parentNode={node} selected={selected} />
                {parentNode !== null ? <FaTrashAlt className="Remove"
                    onClick={() => showRemoveOrganization(node, selected)} /> : ''}
                {U.mapValue((value) => typeToLogo(value), type)}
            </div>
        </div>
        <div className={U.mapValue((open) => open ? 'Open' : 'Closed', open)}>
            {U.mapElems((child) => renderTree(level + 1, selected, child, node), children)}
        </div>
    </div>)
}

export const OrganizationTree = ({ className, heading, root, selected }) => {
    if (sessionStorage.getItem("selectedOrganization") !== null &&
        (!selected.get() || selected.get().id !== sessionStorage.getItem("selectedOrganization"))) {
        loadSelectedOrganization(sessionStorage.getItem("selectedOrganization"), selected)
    }
    selected.observe((value) => {
        if (value) {
            sessionStorage.setItem("selectedOrganization", value.id)
        }
    })
    const hidden = sessionStorage.getItem('OrganizationTreeHidden') === 'true'
    return (<div className={hidden ? `${className} Hide` : className} id={"OrgTree"}>
        <FaArrowCircleLeft className={'CloseToggle'} id={"OrgTreeToggle"}
            style={hidden ? { transform: 'rotateZ(180deg)', right: '-25px' } : { right: '14px' }}
            onClick={() => {
                sessionStorage.setItem('OrganizationTreeHidden',
                    !(sessionStorage.getItem('OrganizationTreeHidden') === 'true'))
                toggleAnimationClass('OrgTree', 'OrgTreeToggle', hidden, 'Show', 'Hide')
            }} />
        <h3>{heading}</h3>
        {renderTree(0, selected, root, null)}
    </div>)
}
