import * as React from 'karet';
import * as U from 'karet.util';
import { qService } from '../_services/questionare.service';
import { runService } from '../_services/run.service';
import Select from 'react-select';
import { find, map, groupBy } from 'underscore';
import { questionRange } from '../_helpers/questions';
import { locKeyStatic, locTextDyn, getDefaultLanguage } from '../_helpers/ui';
import './QuestionareSelector.scss';
const moment = require('moment');

const customStyles = {
    control: (base, state) => ({
        ...base,
        width: '18em'
    }),
    option: (base, state) => {
        return {
            ...base
        }
    }
};

const customStyleLevels = {
    control: (base, state) => ({
        ...base
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

const toOptions = (list) => {
    const groups = groupBy(list, (l) => l.group);
    return map(groups, (values, key) => ({
        label: key,
        options: values.map((q) => ({ value: q.id, label: q.name }))
    }))
}

const findSelected = (listValue, questionareId) => {
    for (let group of listValue) {
        const option = find(group.options, (option) => option.value === questionareId.get())
        if (option) {
            return option;
        }
    }
    return null;
}

const flattenQuestionare = (questionare) => {
    const qs = []
    qs.push({
        value: {
            value: questionare.id,
            type: 'Questionare',
            time: 'Current'
        }, title: {
            fi: `${questionare.title.fi} (${questionRange(questionare.questions).join('-')})`
        }
    })
    for (const q of questionare.questions) {
        if (q.subId) {
            qs.push({
                value: {
                    value: q.subId,
                    type: 'SubQuestionare',
                    time: 'Current',
                },
                level: 1,
                title: { fi: `  ${q.title.fi}(${questionRange(q).join('-')})` }
            })
            for (const qsub of q.questions) {
                qs.push({
                    value: {
                        value: qsub.id,
                        type: 'Question',
                        time: 'Current',
                    },
                    level: 2,
                    title: { fi: ` - ${qsub.fi}(${questionRange(qsub).join('-')})` }
                })
            }
        }
        else {
            qs.push({
                value: {
                    value: q.id,
                    type: 'Question',
                    time: 'Current'
                },
                level: 0,
                title: { fi: ` ${q.fi}(${questionRange(q).join('-')})` }
            })
        }
    }
    return qs
}


export const QuestionareSelector = ({ organization, questionareId, questionareName }) => {
    const list = U.atom([]);
    qService.getAll(organization).then((listValue) => {
        list.set(toOptions(listValue))
    });
    return (<div className='QuestionareSelector'>{U.mapValue((listValue) =>
        <Select styles={customStyles} value={findSelected(listValue, questionareId)}
            options={listValue} onChange={(newSelection) => {
                questionareId.set(newSelection.value);
                questionareName.set(newSelection.label);
                list.set(JSON.parse(JSON.stringify(list.get())));
            }} />, list)}</div>);
}

export const QuestionareSelect = ({ organization, onSelect, noAll }) => {
    const questionares = U.atom([])
    qService.getAll(organization).then((listValue) => {
        const qs = []
        for (const value of listValue) {
            qs.push({ value: value.id, title: value.title })
            for (const q of value.questions) {
                if (q.subId) {
                    qs.push({
                        value: q.subId, title: { fi: "- " + q.title.fi }
                    })
                }
            }
        }
        questionares.set(qs)
    });
    return (<select className="QuestionareSelect" onChange={(evt) => {
        onSelect(evt.target.value !== "" ? evt.target.value : null);
    }}>
        {!noAll ? <option value="">{locKeyStatic("allQuestionares")}</option> : null}
        {
            U.mapElems((q, index) => {
                const title = U.view('title')(q)
                return <option key={index} value={U.view('value')(q)}>
                    {locTextDyn(title)}
                </option>
            }, questionares)
        }
    </select >)
}

export const QuestionareSelectStyle = ({ organization, onSelect, noAll }) => {
    const questionares = U.atom([])
    const lang = getDefaultLanguage()
    qService.getAll(organization).then((listValue) => {
        const qs = []
        if (!noAll) {
            qs.push({ title: locKeyStatic("allQuestionares"), value: "" })
        }
        for (const value of listValue) {
            qs.push({ value: value.id, label: value.title[lang], level: 1 })
            for (const q of value.questions) {
                if (q.subId) {
                    qs.push({
                        value: q.subId,
                        label: q.title[lang],
                        level: 2
                    })
                }
            }
        }
        questionares.set(qs)
    });
    return <>{U.mapValue(qs => <Select styles={customStyleLevels} onChange={(newSelection) => {
        onSelect(newSelection);
    }} options={qs} />, questionares)}</>
}

export const QuestionareTimelineSelect = ({ organization, onSelect, noSelectTitle, subLevels }) => {
    const questionares = U.atom([])
    const lang = getDefaultLanguage()
    runService.getOrganizationTimelineRuns(organization)
        .then((response) => {
            const qs = []
            let first = true
            if (noSelectTitle) {
                qs.push({
                    value: null,
                    label: noSelectTitle,
                    level: 1
                })
            }
            for (const value of response.runs) {
                const q = value.questionare
                qs.push({
                    value: value.id,
                    label: `${q.title[lang]} (${moment(value.createdAt).format("D.M.Y")})`,
                    selected: first,
                    level: 1
                })
                first = false;
                if (subLevels) {
                    for (const quest of q.questions) {
                        if (quest.subId) {
                            qs.push({
                                runId: value.id,
                                value: quest.subId,
                                label: quest.title[lang],
                                level: 2,
                            })
                        }
                    }
                }
            }
            questionares.set(qs)
            if (qs[1]) onSelect(qs[1])
        });
    return <>{U.mapValue(qs => {
        return <select onChange={(newSelection) => {
            if (newSelection.target.value !== noSelectTitle) {
                onSelect(qs.find(q => q.value == newSelection.target.value));
            } else {
                onSelect(qs[0])
            }
        }}>
            {qs.map((q) => q.selected ? <option selected="selected" value={q.value}>{q.label}</option> : 
                 <option value={q.value}>{q.label}</option>)}
        </select>
    }, questionares)}</>
}

export const QuestionareItemSelector = ({ questionare, value, onSelect }) => {
    const qv = U.molecule({ q: questionare, v: value })
    return (<>{U.mapValue(({ q, v }) => {
        const qs = flattenQuestionare(q)
        if (!value.get() || !value.get().value) {
            value.set(qs[0].value)
            v = qs[0].value
        }
        return (<select className="QuestionareSelect"
            value={v.value}
            onChange={(evt) => {
                onSelect(evt.target.value !== "" ?
                    qs.find(q => q.value.value.toString() === evt.target.value).value :
                    qs[0].value);
            }}>
            {qs.map((q, index) => <option key={index}
                value={q.value.value}>
                {q.title.fi}
            </option>)
            }
        </select>)
    }, qv)}</>)
}