import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { LanguageTextEdit } from '../LanguageTextEdit';
import './TaskEditor.scss';
import { FaTrash } from 'react-icons/fa';
import { showYesNoDialog } from '../../_ui/Dialog';
const {v4: uuidv4}=require('uuid');

const Item = ({ item, index }) => {
    const description = U.view('description')(item)
    return (<div className={index % 2 === 1 ? "ItemOdd" : "ItemEven"}>
        <h5>Tehtävä {index + 1}</h5>
        <LanguageTextEdit values={description} rows={4} />
        <FaTrash className="Delete" onClick={() => deleteTask(item)} />
    </div>)
}

const addTask = ({ items }) => {
    items.modify(R.append({ uuid: uuidv4(), description: { fi: "", en: "", sv: "", ee: "", type: "CheckBox" } }))
}

const deleteTask = (item) => {
    showYesNoDialog({
        title: 'Poiston varmistus',
        message: `Haluatko varmasti poistaa tehtävän?`,
        parameter: item,
        onYes: (item) => {
            item.remove()
        }
    })
}

export const TaskEditor = ({ task }) => {
    const items = U.view("items")(task)
    const description = U.view("description")(task)
    return (<div className="TaskEditor">
        <div className="Type">
            <div className="Label">Tyyppi</div>
            <select>
                <option value="TaskList">Tehtävälista</option>
            </select>
        </div>
        <div className="BasicInfo">
            <div className="Label">Otsikko</div>
            <LanguageTextEdit values={task} rows={2} />
            <div className="Label">Kuvaus</div>
            <LanguageTextEdit values={description} rows={2} />
        </div>
        <h5>Tehtävät</h5>
        {U.mapElems((item, index) =>
            <Item item={item} index={index} />, items)}
        <button className="NewTaskButton"
            onClick={() => addTask({ items })}>Lisää tehtävä</button>
    </div >)
}