import * as React from 'react';
import { flatten } from 'underscore';
import { orgService } from '../_services/organization.service';
import { systemService } from '../_services/system.service';
import styled from 'styled-components'
import { getLanguage, locKeyLang } from '../_helpers';
import { Button, Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core';

const CalendarContainer = styled.div`
    margin-top:10px;
    margin-bottom:5px;
    display:grid;
    grid-template-columns:30em 10em;
    column-gap:10px;
`


const customStyles = {
    control: (base, state) => ({
        ...base
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

const treeToList = (root, level) => {
    return flatten([{ value: root.id, label: root.name, level }].concat(
        root.children.map((child) => treeToList(child, level + 1))));
}

export const OrganizationSelector = ({ organization, setOrganization, organizationName, setOrganizationName }) => {
    const [list, setList] = React.useState([])
    React.useEffect(() => {
        orgService.getHome().then((root) => {
            const listValue = treeToList(root, 1);
            setList(listValue)
        });
    }, [])
    return (<div className='OrganizationSelector'>
        <Select value={organization} size="small"
            style={{minWidth:'30em'}}
            input={<OutlinedInput />}
            onChange={(ev) => {
                setOrganization(ev.target.value);
                if (organizationName) {
                    setOrganizationName(newSelection.label)
                }
            }}>
                {list.map(l => <MenuItem key={l.value} value={l.value}>
                    <span style={{paddingLeft:l.level*10, fontSize:14-l.level}}>{l.label}</span>
                </MenuItem>)}
            </Select>
    </div>);
}


export const CalendarSelector = ({ organization, setCalendars }) => {
    const [list, setList] = React.useState([])
    const [calendarIds, setCalendarIds] = React.useState(organization.calendarIds)
    React.useEffect(() => {
        systemService.getCalendars().then((list) => {
            setList(list)
        });
    }, [organization])

    return (<CalendarContainer>
        <Select value={calendarIds} size="small"
            multiple
            input={<OutlinedInput />}
            renderValue={(selected) => selected.map(id => list.find(l => l.id === id)?.name).join(', ')}
            onChange={(event) => {
                setCalendarIds(event.target.value)
                setCalendars(event.target.value)
            }}>
            {list.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              <Checkbox checked={calendarIds.indexOf(c.id) !== -1} />
              <ListItemText primary={c.name} />
            </MenuItem>))}
        </Select>
    </CalendarContainer>);
}