import * as React from 'karet';
import * as U from 'karet.util';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import { find, map } from 'underscore';
import './GroupSelector.css'

const customStyles = {
    control: (base, state) => {
        return {
            ...base,
            fontSize: '0.9em',
            width: '10em',
            minHeight: '24px',
            maxHeight: '24px',
            borderRadius: '0px',
            paddingTop: '0px'
        }
    },
    option: (base, state) => {
        return {
            ...base,
            maxHeight: '24px',
            paddingTop: '0px',
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    },
    dropdownIndicator: (base) => ({
        ...base,
        fontSize: '0.9em',
        paddingTop: '0px',
        minHeight: '15px',
        maxHeight: '15px'
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: '0.9em',
        minHeight: '24px',
        maxHeight: '24px'
    })
};

const customStylesMulti = {
    control: (base, state) => ({
        ...base
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

export const GroupSelector = ({ selectedGroup, groups }) => {
    return (<div className='GroupSelector'>{U.mapValue((listValue) =>
        <Creatable styles={customStyles}
            value={find(listValue, (option) => selectedGroup.get() === option.value)}
            options={map(listValue, (group) => ({ value: group, label: group }))}
            onChange={(newSelection) => {
                selectedGroup.set(newSelection.value);
            }} />, groups)}</div>);
}

const groupToList = (groupValue) => {
    const list = []
    Object.keys(groupValue).forEach(key => {
        list.push({ value: key, label: key, level: 1 })
        for (const value of groupValue[key]) {
            list.push({ value: { key, value }, label: value, level: 2 })
        }
    })
    return list
}

export const GroupMultiSelector = ({ groups, selectedGroups }) => {
    return (<div className='GroupSelector'>
        {U.mapValue((groupValue) => <Select styles={customStylesMulti} isMulti={true}
            options={map(groupToList(groupValue))} onChange={(newSelection) => {
                const groupValues = map(newSelection, (v) => v.value)
                selectedGroups.set(groupValues)
            }} />, groups)}
    </div>)
}