import { apiUrl } from './common';

function getHome() {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/organization/home`, requestOptions)
        .then(handleResponse)
}

function getOrganization(id) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/organization/${id}`, requestOptions)
        .then(handleResponse)
}

function getInterviewees(id, start, count, recursive) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ start, count, recursive })
    };
    return fetch(`${apiUrl}/organization/${id}/interviewees`, requestOptions)
        .then(handleResponse)
}

function createOrganization(parentId, type, name) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ parentId, type, name })
    };
    return fetch(`${apiUrl}/organization`, requestOptions)
        .then(handleResponse)
}

function removeOrganization(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
    };
    return fetch(`${apiUrl}/organization`, requestOptions)
        .then(handleResponse)
}

function updateOrganization(organization) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(organization)
    };
    return fetch(`${apiUrl}/organization`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        if (response.status !== 200) {
            const data = (text && text.length > 0) && JSON.parse(text);
            throw {status: response.status, data}
        }
        const data = (text && text.length > 0) && JSON.parse(text);
        return data;
    });
}

function createTemplate(organization, name) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
    };
    return fetch(`${apiUrl}/organization/${organization}/template`, requestOptions)
        .then(handleResponse)
}

function updateTemplate(organization, template) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(template)
    };
    return fetch(`${apiUrl}/organization/${organization}/template`, requestOptions)
        .then(handleResponse)
}

function removeTemplate(organization, templateId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/organization/${organization}/template/${templateId}`, requestOptions)
        .then(handleResponse)
}

function anonymiseOrganization(id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/organization/${id}/anonymise`, requestOptions)
        .then(handleResponse)
}

function setAllowRegisterFrom(id, allowRegisterFrom) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({allowRegisterFrom})
    };
    return fetch(`${apiUrl}/organization/${id}/allow-register-from`, requestOptions)
        .then(handleResponse)
}

function deanonymiseOrganization(id) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/organization/${id}/deanonymise`, requestOptions)
        .then(handleResponse)
}

export const orgService = {
    getHome,
    getOrganization,
    createOrganization,
    removeOrganization,
    updateOrganization,
    getInterviewees,
    createTemplate,
    updateTemplate,
    removeTemplate,
    anonymiseOrganization,
    deanonymiseOrganization,
    setAllowRegisterFrom
};
