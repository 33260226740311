import * as React from 'karet';
import * as U from 'karet.util';
import { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { history } from '../_helpers';
import { HomePage } from '../HomePage';
import { RepliesPage } from '../RepliesPage';
import { IndividualResultsPage } from '../IndividualResultsPage';
import { EventsPage } from '../EventsPage';
import { LibraryPage } from '../LibraryPage';
import { RegisterPage } from '../RegisterPage';
import { LoginPage, SendPasswordResetPage, ResetPasswordPage } from '../LoginPage';
import { ReplyPage } from '../Reply';
import { connect } from 'react-redux';
import { userConstants } from '../_constants/user.constants';
import { userService } from '../_services/user.service';
import { alertMessages } from '../_reducers/alert.reducer';
import { Alert } from '../_components/Alert';
import { MyPage } from '../MyPage';
import { YesNoDialog, ErrorDialog, ContentDialog, OkDialog } from '../_ui/Dialog';
import { LibrarySelector } from '../_components/editors/LibrarySelector';
import { ThresholdEditor } from '../_components/editors/ThresholdEditor';
import { ImportUsers } from '../_components/editors/ImportUsers';
import { ConditionEditor } from '../_components/editors/ConditionEditor';
import { setLanguage } from '../_helpers/ui';
import { CompareResults } from '../_components/editors/CompareResults';
import { SystemPage } from '../SystemPage';
import MenuAppBar from './MenuAppBar';
import { CalendarPage } from '../CalendarPage/CalendarPage';
import { EventPage } from '../EventPage/EventPage';
import './App.scss';
import { CalendarReport } from '../CalendarReport/CalendarReport';

class App extends Component {
    logout() {
        userService.logout().then(() => {
            this.props.dispatch({ type: userConstants.LOGOUT })
            history.push('/login')
        });
    }

    render() {
        const { user } = this.props;
        return (
            <Router history={history}>
                <div className="App">
                    {user && <>
                        <OkDialog />
                        <YesNoDialog />
                        <ErrorDialog />
                        <ContentDialog />
                        <LibrarySelector />
                        <ThresholdEditor />
                        <ImportUsers />
                        <ConditionEditor />
                        <CompareResults />
                    </>}
                    
                    {user && <MenuAppBar user={user} />}
                    {user && <div className="Alerts">
                        {U.mapElems((message, index) => (
                            <Alert key={index} message={message} />), alertMessages)}
                    </div>}
                    <div className="Main">
                        <Route exact path="/"
                            component={user && user.rights.indexOf('admin') !== -1 ? HomePage : MyPage} />
                        <Route path="/calendar" render={() => <CalendarPage/>}/>
                        <Route path="/calendar-report" render={() => <CalendarReport/>}/>
                        <Route path="/reply" component={ReplyPage} />
                        <Route path="/event" component={EventPage}/>
                        <Route path="/login" component={LoginPage} />
                        <Route path="/register" component={RegisterPage} />
                        <Route path="/mypage" component={MyPage} />
                        <Route path="/replies" component={RepliesPage} />
                        <Route path="/individuals" component={IndividualResultsPage} />
                        <Route path="/events" component={EventsPage} />
                        <Route path="/library" component={LibraryPage} />
                        <Route path="/sendpasswordreset" component={SendPasswordResetPage} />
                        <Route path="/resetpassword" component={ResetPasswordPage} />
                        <Route path="/system" render={() => <SystemPage/>} />
                    </div>
                </div>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    if (state.authentication.user) {
        setLanguage(state.authentication.user.language)
    }
    return { user: state.authentication.user }
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 