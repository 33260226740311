import * as React from 'karet';
import * as U from 'karet.util';
import { locKey } from '../_helpers/ui';
import './Dialog.css'

const _title = U.atom('');
const _message = U.atom('');
const _parameter = U.atom('');
const _onYes = U.atom(() => { });
const _show = U.atom(false);

const _titleError = U.atom('');
const _messageError = U.atom('');
const _showError = U.atom(false);

const _titleContent = U.atom('');
const _content = U.atom('');
const _showContent = U.atom(false);

const _titleOk = U.atom('');
const _messageOk = U.atom('');
const _showOk = U.atom(false);

export const ContentDialog = () => {
    return (<div className="DialogBackground"
        style={{ display: U.mapValue(visible => visible ? 'block' : 'none', _showContent) }}>
        <div className="Dialog">
            <h4>{_titleContent}</h4>
            <div className="Content">
                {_content}
            </div>
            <div className="ButtonRow">
                <button className="YesButton" onClick={() => {
                    _showContent.set(false);
                }}>OK</button>
            </div>
        </div>
    </div>)
}

export const YesNoDialog = () => {
    return (<div className="DialogBackground"
        style={{ display: U.mapValue(visible => visible ? 'block' : 'none', _show) }}>
        <div className="Dialog">
            <h4>{_title}</h4>
            <div className="Content">
                {_message}
            </div>
            <div className="ButtonRow">
                <button className="NoButton" onClick={() => _show.set(false)}>{locKey("no")}</button>
                <button className="YesButton" onClick={() => {
                    _onYes.get()(_parameter.get()); _show.set(false);
                }}>{locKey("yes")}</button>
            </div>
        </div>
    </div>)
}

export const ErrorDialog = () => {
    return (<div className="DialogBackground"
        style={{ display: U.mapValue(visible => visible ? 'block' : 'none', _showError) }}>
        <div className="ErrorDialog">
            <h4>{_titleError}</h4>
            <div className="Content">
                {_messageError}
            </div>
            <div className="ButtonRow">
                <button className="YesButton" onClick={() => {
                    _showError.set(false);
                }}>{locKey("ok")}</button>
            </div>
        </div>
    </div>)
}

export const showYesNoDialog = ({ title, message, parameter, onYes }) => {
    _title.set(title);
    _message.set(message);
    _parameter.set(parameter);
    _onYes.set(onYes);
    _show.set(true);
}

export const showErrorDialog = ({ title, message }) => {
    _titleError.set(title);
    _messageError.set(message);
    _showError.set(true);
}

export const showContentDialog = ({ title, content }) => {
    _titleContent.set(title);
    _content.set(content);
    _showContent.set(true);
}

export const showOkDialog = ({ title, message }) => {
    _titleOk.set(title);
    _messageOk.set(message);
    _showOk.set(true);
}

export const OkDialog = () => {
    return (<div className="DialogBackground"
        style={{ display: U.mapValue(visible => visible ? 'block' : 'none', _showOk) }}>
        <div className="Dialog">
            <h4>{_titleOk}</h4>
            <div className="Content">
                {_messageOk}
            </div>
            <div className="ButtonRow">
                <button className="YesButton" onClick={() => _showOk.set(false)}>{locKey("ok")}</button>
            </div>
        </div>
    </div>)
}