import { apiUrl } from './common';

function createSchedule(type, active, organization, parameters) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            organization,
            type,
            active,
            parameters
        })
    };
    return fetch(`${apiUrl}/schedule`, requestOptions)
        .then(handleResponse)
}

function removeSchedule(id) {
    const requestOptions = {
        method: 'DELETE'
    };
    return fetch(`${apiUrl}/schedule/${id}`, requestOptions)
        .then(handleResponse)
}

function updateSchedule(schedule) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(schedule)
    };
    return fetch(`${apiUrl}/schedule`, requestOptions)
        .then(handleResponse)
}

function getPlans(organization) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/schedule/${organization}/plans`, requestOptions)
        .then(handleResponse)
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = (text && text.length > 0) && JSON.parse(text);
        if (response.status !== 200) {
            throw data
        }
        return data;
    });
}

export const scheduleService = {
    createSchedule,
    updateSchedule,
    removeSchedule,
    getPlans
};
