import * as React from 'karet';
import * as U from 'karet.util';
import Select from 'react-select';
import { getDefaultLanguage } from '../_helpers/ui';

const customStyles = {
    control: (base, state) => ({
        ...base,
        width: '18em'
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

export const ParameterTemplateSelector = ({ template, templates }) => {
    const list = U.atom(templates ? templates.map((t) => ({
        value: t,
        label: t.title[getDefaultLanguage()]
    })) : [])
    const listTemplate = U.molecule({ listc: list, templatec: template })
    return (<div>{U.mapValue(({ listc, templatec }) =>
        <Select styles={customStyles} value={listc.find((option, index) =>
            templatec ? templatec.title === option.value.title : index === 0)}
            options={listc} onChange={(newSelection) => {
                template.set(newSelection.value);
            }} />, listTemplate)}</div>);
}