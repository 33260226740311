import * as React from 'karet';
import * as U from 'karet.util';
import { TextEdit } from '../_components/TextEdit';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { orgService } from '../_services/organization.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { LanguageTextEdit } from '../_components/LanguageTextEdit';
import { locKey, locKeyStatic } from '../_helpers';
import { AddButton } from '../_components/BasicElements';
import './TemplateList.css';

const createTemplate = ({ templates, name, organization }) => {
    orgService.createTemplate(organization, name.get())
        .then((newTemplates) => {
            templates.set(newTemplates)
            name.set('');
        });
}

const removeTemplate = (organization, template) => {
    orgService.removeTemplate(organization, template.get().templateId)
        .then(() => template.remove())
        .catch(() => pushAlert(locKeyStatic('removingTemplateFailed'), 5))
}

const updateTemplate = (organization, templateValue) => {
    orgService.updateTemplate(organization, templateValue).catch(() => {
        pushAlert(locKeyStatic('updateTemplateFailed'), 5)
    });
}

const StaticTemplate = ({ organization, template, edit }) => {
    const id = U.view('templateId')(template);
    const name = U.view('name')(template);
    return (<div key={id.get()} className='Template'>
        <div className='Name'>{name}</div>
        <div className='Operations'>
            <div className='Remove'
                onClick={() => removeTemplate(organization, template)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

const EditTemplate = ({ organization, template, edit }) => {
    const name = U.view('name')(template);
    const shortMessage = U.view('shortMessage')(template);
    const shortMessageSender = U.view('shortMessageSender')(template);
    const email = U.view('email')(template);
    const emailTitle = U.view('title')(email);
    const emailMessage = U.view('message')(email);
    return (<div className='EditTemplate'>
        <h4>Muokkaa pohjaa</h4>
        <div className='Label'>{locKey("name")}</div><TextEdit value={name} />
        <div className='Label'>{locKey("SMS")}</div><LanguageTextEdit values={shortMessage} />
        <div className='Label'>{locKey("senderSms")}</div><TextEdit value={shortMessageSender} />
        <div className='Label'>{locKey("email")}</div>
        <div>
            <div className='SubLabel'>{locKey("title")}</div>
            <LanguageTextEdit values={emailTitle} />
            <div className='SecondSubLabel'>{locKey("message")}</div>
            <LanguageTextEdit values={emailMessage} />
        </div>
        <div className="Operations">
            <div className='Remove'
                onClick={() => removeTemplate(organization, template)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

export const Template = ({ organization, template }) => {
    const edit = U.atom(false);
    template.skip(1).debounce(1000).observe((templateValue) => {
        updateTemplate(organization, templateValue)
    });
    return (<div>
        {U.mapValue((editValue) => {
            return editValue ?
                <EditTemplate template={template} edit={edit} /> :
                <StaticTemplate organization={organization} template={template} edit={edit} />
        }, edit)}
    </div>)
}

export const TemplateList = ({ templatesValue, organization }) => {
    const newName = U.atom("");
    const templates = U.atom(templatesValue)
    
    return (<div className='TemplateList'>
        <div className='Template'>
            <div className='NameHeading'>{locKey("name")}</div>
            <div className='OperationsHeading'>{locKey("functions")}</div>
        </div>
        {U.mapElems((t) =>
            <Template key={t.get().templateId} organization={organization} template={t} />, templates)}
        <div className='Template'>
            <div className='NameLabel'>{locKey("name")}</div>
            <div className='NameNew'>
                <TextEdit value={newName} />
            </div>
            <AddButton onClick={() => createTemplate(
                { templates, name: newName, organization })} />
        </div>
    </div>)
}
