import * as React from 'react';
import styled from 'styled-components'
import * as qs from 'query-string';
import './EventPage.scss'
import { slideBottomColorDark, slideTopColorDark } from '../Utils';
import { ActionButton, CloseButton } from '../_components/BasicElements';
import * as calendarService from '../_services/calendar.service'

const CancelEvent = styled.div`
    min-width:400px;
    max-width:400px;
    margin:aut4;
    padding-bottom:3.0em;
    background:linear-gradient(${slideTopColorDark}, ${slideBottomColorDark});
    box-shadow: 4px 4px 4px rgba(0,0,0,0.3);
    padding:15px;
    border-top-left-radius:10px;
    border-bottom-right-radius:10px;
    color:white;
    position:relative;
`

const JoinEvent = styled.div`
    min-width:400px;
    max-width:400px;
    margin:auto;
    padding-bottom:3.0em;
    background:linear-gradient(${slideTopColorDark}, ${slideBottomColorDark});
    box-shadow: 4px 4px 4px rgba(0,0,0,0.3);
    padding:15px;
    border-top-left-radius:10px;
    border-bottom-right-radius:10px;
    color:white;
    position:relative;
`

const InfoText = styled.div`
    margin-top:2.0em;
    margin-bottom:2.0em;
`

const InfoValue = styled.div`
    display:grid;
    grid-template-columns:8em 1fr;
    margin-bottom:0.5em;
`

const Controls = styled.div`
    width:100%;
    margin-left:auto;
    align-self:right;
    column-gap:5px;
    display:grid;
    grid-template-columns:1fr 1fr;
    max-width:20em;
`

const OneControl = styled.div`
    width:100%;
    margin-left:auto;
    align-self:right;
    column-gap:5px;
    display:grid;
    grid-template-columns:1fr;
    max-width:10em;
`

const Heading = styled.div`
    font-size:1.4em;
    margin-bottom:1.0em;
`

export const EventPage = () => {
    const [action, setAction] = React.useState("")
    const [response, setResponse] = React.useState({})
    React.useEffect(() => {
        const parsed = qs.parse(window.location.search)
        setAction(parsed.action)
        if (parsed.action === "join") {
            calendarService.actionEvent(parsed.action, parsed.hash, parsed.password).then((response) => {
                setResponse(response)
            })    
        }
        else if (parsed.action === "cancel") {
            calendarService.getEvent(parsed.hash, parsed.password).then((response) => {
                setResponse(response)
            })
        }
    }, [])
    return (<div className="EventPage">
        {action === "cancel" && <JoinEvent>
            <Heading>Keskustelun peruuttaminen</Heading>
            <InfoValue>
                <div>Kuvaus</div>
                <div>{response.description}</div>
            </InfoValue>
            <InfoValue>
                <div>Kesto</div>
                <div>{response.duration} minuuttia</div>
            </InfoValue>
            <InfoText>Haluatko perua keskustelun?</InfoText>
            <Controls>
                <ActionButton title="Ei" onClick={() => window.close()}/>
                <CloseButton title="Kyllä" onClick={() => {
                    const parsed = qs.parse(window.location.search)
                    calendarService.actionEvent(parsed.action, parsed.hash, parsed.password).then((response) => {
                        if (response.status === "cancelled") {
                            setAction("cancelled")
                        }
                        else {
                            setAction("notCancelled")
                        }
                    })
                }} />
            </Controls>
        </JoinEvent>}
        {action === "join" && <JoinEvent>
            <Heading>Liity Keskusteluun</Heading>
            <InfoValue>
                <div>Kuvaus</div>
                <div>{response.description}</div>
            </InfoValue>
            <InfoValue>
                <div>Kesto</div>
                <div>{response.duration} minuuttia</div>
            </InfoValue>
            <InfoText>Haluatko liittyä keskustelun?</InfoText>
            <Controls>
                <CloseButton title="Ei" onClick={() => window.close()}/>
                <ActionButton disabled={!response.url} title="Kyllä" onClick={() => window.location.href=response.url}/>
            </Controls>
        </JoinEvent>}
        {action === "cancelled" && <JoinEvent>
            <Heading>Keskustelu peruutettu</Heading>
            <InfoText>Osallistumisesi on nyt peruutettu.</InfoText>
            <OneControl>
                <ActionButton disabled={!response.url} title="Sulje" onClick={() => window.close()}/>
            </OneControl>
        </JoinEvent>}
        {action === "notCancelled" && <JoinEvent>
            <Heading>Keskustelua ei voi perua</Heading>
            <InfoText>Keskustelua ei voi enää perua ajankohdan takia.</InfoText>
            <OneControl>
                <ActionButton disabled={!response.url} title="Sulje" onClick={() => window.close()}/>
            </OneControl>
        </JoinEvent>}
    </div>)
}