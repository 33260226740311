import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import './Paging.css'

export const PagingTotal = ({ start, setStart, count, total }) => {
    return (<div className="Paging">
        <FaAngleLeft className="PagingButton"
            onClick={() => setStart(Math.max(0, start - count))} />
       {`${start}-${Math.min(start + count, total)}`}&nbsp;/{start + count < total ? `${total}+` : total}
        <FaAngleRight className="PagingButton"
            onClick={() => setStart(
                Math.min(start + count, Math.max(0, total - count)))} />
    </div>)
}