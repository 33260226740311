import * as React from 'karet';
import * as U from 'karet.util';
import { partial } from 'underscore';
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";

const toggleOpenClick = (localStorageId, open, e) => {
    e.preventDefault();
    open.set(!open.get());
    if (localStorageId) {
        localStorage.setItem(localStorageId, open.get() ? 'true' : 'false');
    }
}

export const OpenToggle = ({ open, href, localStorageId, defaultOpen }) => {
    open.set((localStorage.getItem(localStorageId) === 'true' && localStorageId) || defaultOpen);
    return (<a onClick={partial(toggleOpenClick, localStorageId, open)} href={href}>
        {U.mapValue((open) => open ?
            <FaMinusCircle className="OpenSign" /> :
            <FaPlusCircle className="OpenSign" />, open)
        }
    </a>)
}