import * as React from 'karet';
import { Component } from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
    PieChart, Pie, Cell, LineChart, Line
} from 'recharts';
import { TiChartArea, TiChartLine } from 'react-icons/ti'

import './TotalMetrics.scss';
import { locKeyStatic } from '../_helpers';

export class TotalMetrics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            history: 'Split'
        }
    }

    calculateColorSegments(metrics, colors) {
        const min = metrics.totalMin;
        const max = metrics.totalMax;
        const segmentSize = (max - min) / colors.length
        const allSegments = []
        for (let i = 0; i < colors.length; i++) {
            const curr = min + segmentSize * i;
            allSegments.push({
                min: Math.round(curr),
                max: Math.round(curr + segmentSize), color: colors[i], count: 0
            })
        }
        for (let total of metrics.totals) {
            let index = allSegments.findIndex((s) => s.min <= total && s.max >= total)
            if (index === -1) {
                index = 0;
            }
            allSegments[index].count++;
        }
        let data = []
        for (let i = 0; i < colors.length; i++) {
            if (allSegments[i].count > 0) {
                data.push({
                    label: allSegments[i].min + "-" + allSegments[i].max,
                    value: allSegments[i].count,
                    color: colors[i],
                    description: allSegments[i].min + "-" + allSegments[i].max
                })
            }
        }
        const notReplied = metrics.sent - metrics.replies
        data.push({
            label: 'Ei vastattu', value: notReplied, color: '#7f7f7f',
            description: 'Ei vastattu'
        })
        return data
    }

    calculateThresholdSegments(metrics, thresholds) {
        const allSegments = []
        for (let i = 0; i < thresholds.length; i++) {
            allSegments.push({
                min: thresholds[i].value,
                max: i < thresholds.length - 1 ? thresholds[i + 1].value : metrics.totalMax,
                color: thresholds[i].color, count: 0
            })
        }
        for (let total of metrics.totals) {
            const index = allSegments.findIndex((s) => s.min <= total && s.max >= total)
            allSegments[index !== -1 ? index : 0].count++;
        }
        let data = []
        for (let i = 0; i < thresholds.length; i++) {
            data.push({
                label: allSegments[i].min + "-" + allSegments[i].max,
                value: allSegments[i].count,
                color: thresholds[i].color,
                description: thresholds[i].fi
            })
        }
        const notReplied = metrics.sent - metrics.replies
        data.push({
            label: 'Ei vastattu', value: notReplied, color: '#afafaf',
            description: 'Ei vastattu'
        })
        return data
    }

    renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180.0);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180.0);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

    render() {
        const { metrics, colors, thresholds } = this.props;
        const { history } = this.state
        let maxTotal = 0;
        const lineData = []
        for (let metric of metrics) {
            if (metric.totals.length > 0) {
                maxTotal = Math.max(maxTotal, metric.totals[metric.totals.length - 1])
                let sum = 0
                for (const value of metric.totals) {
                    sum += value
                }
                sum /= metric.totals.length;
                const percentage = ((sum - metric.totalMin) / (metric.totalMax - metric.totalMin)).toFixed(2)
                lineData.unshift({
                    name: metric.createdAt.substr(5, 5),
                    Tulos: percentage,
                    label: Math.round(percentage * 100) + '%'
                })
            }
        }
        if (maxTotal === 0) {
            return (<div></div>)
        }
        const datas = metrics.map((metric) => ({
            date: metric.createdAt,
            stack: thresholds && thresholds.length > 1 ?
                this.calculateThresholdSegments(metric, thresholds) :
                this.calculateColorSegments(metric, colors)
        }))
        const stackData = datas.reverse().map((data) => {
            const stack = {
                name: data.date.substr(5, 5),
                stack: data.stack
            }
            for (let i in data.stack) {
                stack[data.stack[i].label] = data.stack[i].value
            }
            return stack;
        })
        const firstData = datas[datas.length - 1]
        firstData.stack = firstData.stack.filter((s) => s.value > 0.0)
        return (<div className="TotalMetrics">
            <div className="Column">
                <div className="Heading">{locKeyStatic("latest")} ({firstData.date.substr(0, 10)})</div>
                <div className="Pie">
                    <PieChart width={200} height={200}>
                        <Pie
                            data={firstData.stack}
                            cx={100}
                            cy={100}
                            labelLine={false}
                            label={this.renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {
                                firstData.stack.map((entry, index) =>
                                    <Cell key={`cell-${index}`} fill={entry.color} />)
                            }
                        </Pie>
                    </PieChart>
                </div>
            </div>
            <div className="Column">
                <div className="Heading">{locKeyStatic("resultDescription")}</div>
                {thresholds ? <div className="Descriptions">
                    <div className="DescriptionHeading">{locKeyStatic("resultInterval")} {metrics[0].totalMin} - {metrics[0].totalMax}</div>
                    {datas[datas.length - 1].stack.map((d, index) => <React.Fragment key={index}>
                        <div key={`${index}-label`} className="Label" style={{ backgroundColor: d.color }}>
                        </div>
                        <div key={`${index}-points`} className="Points">{this.toPercent(d.value / metrics[0].sent)}</div>
                        <div key={`${index}-description`} className="Description">{d.description}</div>
                    </React.Fragment>)}
                    <div></div>
                </div> : <div className="Descriptions">{locKeyStatic("missingDescription")}</div>}
            </div>
            <div className="Column">
                <div className="Heading">
                    {locKeyStatic("history")}
                    <TiChartArea onClick={() => this.setState({ history: 'Split' })}
                        className={history === 'Split' ? 'SelectedChart' : 'Chart'} />
                    <TiChartLine onClick={() => this.setState({ history: 'Line' })}
                        className={history === 'Line' ? 'SelectedChart' : 'Chart'} />
                </div>
                <div className="History">
                    {
                        history === 'Split' ?
                            <AreaChart
                                width={350}
                                height={200}
                                data={stackData}
                                stackOffset="expand"
                                margin={{
                                    top: 10, right: 0, left: 0, bottom: 10,
                                }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis tickFormatter={this.toPercent} />
                                <Tooltip />
                                {stackData[0].stack.map((s, index) =>
                                    <Area type="monotone" key={index} dataKey={`${s.label}`} stackId="1" stroke="#8884d8" fill={s.color} />
                                )}
                            </AreaChart> :
                            <LineChart
                                width={350}
                                height={200}
                                data={lineData}
                                margin={{
                                    top: 10, right: 0, left: 0, bottom: 10,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis tickFormatter={this.toPercent} />
                                <Tooltip formatter={(value, _, __) => value * 100 + '%'} />
                                <Line type="basis" dataKey='Tulos' stroke="#8884d8" label={false} />
                            </LineChart>
                    }
                </div>
            </div>
        </div >)
    }
}