import * as React from 'karet';
import * as U from 'karet.util';
import { UserSelector } from "./UserSelector";
import { QuestionareSelector } from './QuestionareSelector';
import { runService } from '../_services/run.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { TemplateSelector } from './TemplateSelector';
import { BooleanCheckbox, Checkbox } from './Checkbox';
import './QuestionareSender.scss';
import { locKey, locKeyStatic } from '../_helpers';
import { ActionButton } from './BasicElements';
import { NormMaterialSelector } from './CircleSelector';
import { libraryService } from '../_services/library.service';

const sendQuestionare = (organizationId, questionareId, selectedUser, template, mediums,
    options, normMaterial) => {
    runService.sendUserQuestionare(organizationId, selectedUser.get(), questionareId.get(),
        template.get(), mediums.get(), 
        options.get().indexOf("additionalQuestions") !== -1,
        options.get().indexOf("consentDataCollection") !== -1,
         normMaterial.get())
        .then((response) => {
            pushAlert(<div>
                <div>{locKeyStatic("questionareSent")} {locKeyStatic("anonymousLink")}: <a href={"/reply?hash=" + response.anonymousLink}>{response.anonymousLink}</a></div>
            </div>, 15, 'Info')
        })
        .catch(() => pushAlert(locKeyStatic("questionareSendFailed"), 5))
}

export const QuestionareSender = ({ organization, templatesValue }) => {
    const questionareId = U.atom(null);
    const selectedUser = U.atom([]);
    const templates = U.atom(templatesValue)
    const template = U.atom(templates.get()[0].templateId);
    const mediums = U.atom(['email'])
    const options = U.atom(['additionalQuestions','consentDataCollection'])
    const anonymous = U.atom(false);
    const normMaterial = U.atom(null)
    let circles = U.atom([])
    libraryService.searchLibraryItems(organization, "", "NormMaterial").then((circlesValue) => {
        circles.set(circlesValue)        
    })
    return (<div className='QuestionareSender'>
        <div>
            <div className='Label'>{locKey("questionare")}</div>
            <QuestionareSelector organization={organization} questionareId={questionareId}
                questionareName={U.atom(null)} />
        </div>
        {U.mapValue(anonymousValue => !anonymousValue ?
            <>
                <div>
                    <div className='Label'>{locKey("selectTemplate")}</div>
                    <TemplateSelector template={template} templates={templates.get()} />
                </div>
                <div>
                    <UserSelector organization={organization} selectedUser={selectedUser} />
                </div>
            </> : null, anonymous)}
        <div>
            <div className='Label'>{locKey("normMaterial")}</div>
            <div className='Mediums'>
                <NormMaterialSelector circles={circles} circle={normMaterial} allowEmpty={true}/>
            </div>
        </div>
        {U.mapValue(anonymousValue => !anonymousValue ?
            <div>
                <div className='Label'>{locKey("mediums")}</div>
                <div className='Mediums'>
                    <Checkbox selectedOptions={mediums} value='email' /><span>{locKey("email")}</span>
                    <Checkbox selectedOptions={mediums} value='sms' /><span>{locKey("SMS")}</span>
                </div>
            </div> : null, anonymous)}
        <div>
            <div className='Label'>{locKey("options")}</div>
            <div className='Mediums'>
                <Checkbox selectedOptions={options} value='additionalQuestions' /><span>{locKey("additionalQuestions")}</span>
                <Checkbox selectedOptions={options} value='consentDataCollection' /><span>{locKey("consentDataCollection")}</span>
                <BooleanCheckbox value={anonymous} /><span>{locKey("anonymousQuestionare")}</span>
            </div>
        </div>
        {U.mapValue(anonymousValue => anonymousValue ? <div/> : null, anonymous)}
        <ActionButton onClick={() => sendQuestionare(organization, questionareId, selectedUser, template,
            mediums, options, normMaterial)} title={locKey("send")} />
    </div >)
}