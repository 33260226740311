import * as React from 'karet';
import { range } from 'underscore';
import { replyToColor, questionRange, getMinText, getMaxText } from '../_helpers/questions.js';
import { locKeyStatic, locText, locFunc } from '../_helpers/ui.js';
import "./Question.scss";

export const replyCountFromReplies = (replies) => {
    if (Array.isArray(replies)) {
        return replies.length
    }
    let count = 0;
    for (const key in replies) {
        if (replies[key]) {
            count += replies[key]
        }
    }
    return count
}

export const renderQuestionHeader = (question) => {

    if (question.reply === 'text' || question.reply === 'optionPoints') {
        return (<React.Fragment>
            <div className="TextHeadingFull">{question.reply === 'text' ?
                locText(question) :
                locKeyStatic("pointSelection")}</div>
        </React.Fragment>)
    }
    else if (question.reply === 'enumerationText') {
        return <></>
    }
    else if (question.subId) {
        return (<div className="SubQuestionareHeading">{locText(question.title)}</div>)
    }
    const minMax = questionRange(question);
    return (<React.Fragment>
        <div className="StartRow"></div>
        <div className="MinMax">
            <span className="Min" style={{ color: replyToColor(question, minMax[0], 1.0) }}>
                {locFunc(lang => getMinText(question, lang))}
            </span>
            <span>-</span>
            <span className="Max" style={{ color: replyToColor(question, minMax[1], 1.0) }}>
                {locFunc(lang => getMaxText(question, lang))}
            </span>
        </div>
        <div className="TextHeading">{locKeyStatic("question")}</div>
        <div className="ReplyContainer">
            {
                range(minMax[0], minMax[1] + 1).map((index) => (
                    <div key={index} className="Heading"
                        style={{ backgroundColor: replyToColor(question, index, 1.0) }} >
                        {index}
                    </div >))
            }
            {replyCountFromReplies(question.replies) > 1 ?
                <div className="KaHeading">{locKeyStatic("avg")}</div> :
                ''}
        </div>
    </React.Fragment>)
}