import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { runService } from '../_services/run.service';
import { formatTime, formatStatus, locKeyStatic, locKey } from '../_helpers/ui';
import { pushAlert } from '../_reducers/alert.reducer';
import { QuestionareReplies } from './QuestionareReplies';
import { FaEye, FaClock, FaDiceOne, FaTrash, FaAlignJustify } from 'react-icons/fa';
import { RadioButtons } from '../_ui/RadioButtons';
import { percentageToColor, getColors } from '../_helpers/questions';
import { showYesNoDialog } from '../_ui/Dialog';
import { TemplateSelector } from './TemplateSelector';
import * as moment from 'moment';
import { PagingTotal } from '../_ui/Paging';
import { DateTime } from './DateTime';
import { DropDown } from './DropDown';
import { TotalMetrics } from '../_ui/TotalMetrics';
import { orgService } from '../_services/organization.service';
import { ItemList } from './ItemList';
import { ActionButton, AddButton } from './BasicElements';
import { CompareResultsShow, CompareResultsCreate } from './editors/CompareResults';
import './RunList.scss';
import { ResultBar, ResultBarPercentage } from '../_helpers/visuals';

function addReminderTime(runId, templateId, time, reminderTimes) {
    runService.addReminderTime(runId, templateId, time).then((times) => {
        reminderTimes.set(times.map(time => moment(time).format('YYYY-MM-DD HH:mm')))
    }).catch(() => {
        pushAlert(locKeyStatic("addingReminderFailed"), 3000)
    })
}

const deleteRunReport = (runId, report, reports) => {
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: locKeyStatic("areYouSureRemoveReport", { name: report.get().name }),
        onYes: () => {
            const reportId = report.get().reportId
            runService.deleteRunReport(runId, reportId).then(() => {
                reports.modify(R.filter(r => r.reportId !== reportId))
                report.set(reports.get()[0])
            }).catch(() => {
                pushAlert(locKeyStatic("removeFailed"), 3000)
            })
        }
    })
}

const StaticRun = ({ organizationId, run, edit, checked }) => {
    const questionare = U.view('questionare')(run);
    const created = U.view('createdAt')(run);
    const schedule = U.view('schedule')(run);
    const runType = U.view('runType')(run);
    const type = U.molecule({ schedule, runType })
    const organizationName = U.view('organizationName')(run);
    const metrics = U.view('metrics')(run);
    return (<div className='Run'>
        <div className='Name'>{questionare}</div>
        <div className='Name'>{organizationName}</div>
        <div className='Result'>
            {U.mapValue((metrics) => metrics && metrics.total > 0 ? <svg style={{ width: '5em', height: '1.8em', marginBottom: -2 }}>
                <rect x="5%" y="5%" width="90%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
                    fill={percentageToColor(metrics['percentage'], 1.0)}>
                </rect>
                <text x="50%" y="70%" textAnchor="middle">{metrics['total']}</text>
            </svg> : '-', metrics)}
        </div>
        <div className='Type'>
            {U.mapValue(({ schedule, runType }) =>
                runType === "Original" ? (schedule ? <FaClock /> : <FaDiceOne />) : <FaAlignJustify />, type)}</div>
        <div className='Created'>{formatTime(created, '-')}</div>
        <div className='Functions'>
            <div className='Remove'
                onClick={() => showRemoveRun(organizationId, run)}><FaTrash /></div>
            <input className="Select" type="checkbox" checked={checked}
                onChange={(e) => checked.set(e.target.checked)} />
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEye /></div>
        </div>
    </div >)
}

const showRemoveRun = (organization, run) => {
    const runValue = run.get();
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: locKeyStatic("confirmResultRemove", {
            questionare: runValue.questionare,
            createdAt: runValue.createdAt
        }),
        parameter: run,
        onYes: (run) => { removeRun(organization, run) }
    })
}

const removeRun = (organization, run) => {
    runService.removeRun(organization, run.get().id)
        .then(() => run.remove())
        .catch(() => pushAlert(locKeyStatic("removingResultsFailed"), 5));
}

export const QuestionareLevel = ({ level, thresholds, hideTitle, hideParent }) => {
    return (<React.Fragment>
        {U.mapValue((lv) => lv ?
            <React.Fragment>
                {!hideTitle ? <div className="OrganizationHeading">{lv.organizationName}</div> : null}
                <QuestionareReplies questionMap={U.atom(lv.questions)} />
                {lv.parent && !hideParent ? <QuestionareLevel level={U.atom(lv.parent)} thresholds={thresholds} />
                    : ''}
            </React.Fragment> : '', level)}
    </React.Fragment>)
}

const QuestionareResults = ({ questions, thresholds, fetch, hideTitle, hideParent }) => {
    const temporary = U.atom(null)
    const level = U.atom(null)
    temporary.observe((value) => {
        if (value !== null && value.questions.questions.length > 0) {
            level.set(value)
        }
    })
    fetch(0, 20, temporary, { column: 'total', direction: 'asc' })
    return (<React.Fragment>
        <QuestionareLevel key={'level'} level={level} thresholds={thresholds} hideTitle={hideTitle}
            hideParent={hideParent} />
    </React.Fragment>)
}

const QuestionareSummary = ({ questions, thresholds, fetch, hideTitle, hideParent }) => {
    const temporary = U.atom(null)
    const level = U.atom(null)
    temporary.observe((value) => {
        if (value !== null && value.questions.questions.length > 0) {
            level.set(value)
        }
    })
    fetch(0, 20, temporary, { column: 'total', direction: 'asc' })
    return (<React.Fragment>
        {U.mapValue(q => q && q.questions && q.questions.totalMetrics &&
            q.questions.totalMetrics.length > 0 ?
            <TotalMetrics metrics={q.questions.totalMetrics} thresholds={[]}
                colors={getColors()} /> : null, level)}
    </React.Fragment>)
}

const SendReminder = ({ run, reminderTimes, organization }) => {
    const templates = U.view('templates')(organization)
    const template = U.atom(null)
    const reminderTime = U.atom(moment().format())
    return (<div className="SendReminder">
        <span className="Label">{locKey("template")}</span>
        <TemplateSelector template={template} templates={templates.get()} />
        <span className="Label">{locKey("time")}</span>
        <DateTime value={reminderTime} />
        <div className="ReminderButton">
            <AddButton onClick={() =>
                addReminderTime(run.get().id, template.get(), reminderTime.get(), reminderTimes)} />
        </div>
    </div>)
}

const fetchItems = (organizationId, runId, start, count, target, sort) => {
    runService.getOrganizationRunItems({ runId, organizationId, start, count, sort })
        .then((items) => target.set(items))
}

const fetchGroupItems = (organizationId, runId, group, groupValue, start, count, target, sort) => {
    runService.getOrganizationRunItems({ runId, organizationId, start, count, group, groupValue, sort })
        .then((items) => target.set(items))
}

const EditRun = ({ run, organizationId, organization, edit, checked }) => {
    const questionare = U.view("questionare")(run);
    const status = U.view("status")(run);
    const createdAt = U.view("createdAt")(run);
    const questions = U.atom([]);
    const schedule = U.view('schedule')(run);
    const runType = U.view('runType')(run);
    const type = U.molecule({ schedule, runType })
    const organizationName = U.view("organizationName")(run);
    const anonymousLink = U.view("anonymousLink")(run);
    const runId = U.view("id")(run);
    const reports = U.atom([]);
    const totalMetrics = U.atom(null);
    const metrics = U.view('metrics')(run);
    const totalResult = U.view('total')(metrics);
    const percentage = U.view('percentage')(metrics);
    const normPercentage = U.view('normPercentage')(metrics);
    const thresholds = U.atom({})
    const groups = U.atom({});
    const replies = U.atom(0);
    const total = U.atom(0);
    const reminderTimes = U.atom(null)
    const sentReminders = U.atom([])
    const selectedGrouping = U.atom(null);
    const minDate = U.atom("")
    const maxDate = U.atom("")
    const parentOrgs = run.get().parentOrgs
    const children = U.atom([])
    const report = U.atom(null)

    runService.getOrganizationRun(parentOrgs.indexOf(organizationId) !== -1 ?
        run.get().organizationId : organizationId, run.get().id)
        .then((runDetails) => {
            replies.set(runDetails.replies)
            total.set(runDetails.total)
            questions.set(runDetails.questions)
            groups.set(runDetails.groups)
            orgService.getOrganization(run.get().organizationId).then((organization) => {
                if (organization.type === 'Root') {
                    children.set([])
                } else {
                    children.set(organization.children.filter(o => o.id !== organizationId))
                }
            })
            organizationName.set(runDetails.organizationName)
            anonymousLink.set(runDetails.anonymousLink)
            thresholds.set(runDetails.thresholds)
            totalMetrics.set(runDetails.totalMetrics)
            minDate.set(runDetails.minDate)
            maxDate.set(runDetails.maxDate)
            reminderTimes.set(runDetails.status === 'Sent' ? runDetails.reminderTimes.map((time) =>
                moment(time).format('YYYY-MM-DD HH:mm')) : null)
            sentReminders.set(runDetails.sentReminders.map((time) =>
                moment(time).format('YYYY-MM-DD HH:mm')))
            reports.set(runDetails.reports)
            if (runDetails.reports.length > 0) {
                report.set(runDetails.reports[0])
            }
        })

    return (<div className="EditRun">
        <h4>{locKey("results")}</h4>
        <div className="InfoLabel">{locKey("questionare")}</div><div>{questionare}</div>
        <div className="InfoLabel">{locKey("organization")}</div><div>{organizationName}</div>
        <div className="InfoLabel">{locKey("result")}</div>
        <div>
            <ResultBar percentage={percentage.get()} total={totalResult} marginTop={"-0.3em"} />
        </div>
        {U.mapValue(percentage => percentage ?
            <React.Fragment>
                <div className="InfoLabel">{locKey("resultNorm")}</div>
                <div><ResultBarPercentage percentage={percentage} marginTop={"-0.3em"} /></div>
            </React.Fragment> : null, normPercentage)
        }
        <div className="InfoLabel">{locKey("anonymousLink")}</div><div>
            {U.mapValue(link =>
                <a href={"/reply?hash=" + link}>{link}</a>, anonymousLink)}
        </div>
        <div className="InfoLabel">
            {U.mapValue((value) => value === "Original" ?
                locKeyStatic("sendTime") :
                locKeyStatic("creationTime"), runType)}
        </div><div>{formatTime(createdAt)}</div>
        {
            U.mapValue((reminders) => reminders ? <React.Fragment>
                <div className="InfoLabel">{locKeyStatic("comingReminders")}</div>
                <div>
                    {reminders.length > 0 ? <React.Fragment>{reminderTimes.map((time) => <div>{time}</div>)}</React.Fragment> :
                        <SendReminder run={run} reminderTimes={reminderTimes} organization={organization} />}
                </div>
            </React.Fragment> : '', reminderTimes)
        }
        <div className="InfoLabel">{locKeyStatic("sentReminders")}</div>
        <div>
            {U.mapValue((sentReminders) => sentReminders && sentReminders.length > 0 ?
                <React.Fragment>{sentReminders.map((time) => <div>{formatTime(time)}</div>)}</React.Fragment> : '-', sentReminders)}
        </div>
        <div className="InfoLabel">{locKeyStatic("replyCount")}</div> <div>{replies}/{total}</div>
        <div className="InfoLabel">{locKeyStatic("type")}</div>
        <div>{U.mapValue(({ schedule, runType }) =>
            runType === "Original" ? (schedule ? locKeyStatic("scheduled") : locKeyStatic("individual")) : locKeyStatic("assembledReport"), type)}</div>
        {
            U.mapValue((value) => value === "Combination" || value === "CombinationTimePeriod" ? <React.Fragment>
                <div className="InfoLabel">{locKeyStatic("repliesFromTime")}</div>
                <div>
                    {U.mapValue(value => moment(value).format("DD.MM.YYYY"), minDate)} -&nbsp;
                {U.mapValue(value => moment(value).format("DD.MM.YYYY"), maxDate)}
                </div>
            </React.Fragment> : "", runType)
        }
        <div className="InfoLabel">{locKeyStatic("status")}</div><div>{formatStatus(status)}</div>
        <div className="InfoLabel">{locKeyStatic("reports")}</div>
        <div className="Report">
            {U.mapValue(values =>
                <>
                    <select disabled={values.length === 0} onChange={(event) => report.set(values[event.target.value])}>
                        {values.map((r, index) =>
                            <option value={index} selected={report.get() && r.reportId === report.get().reportId} >
                                {r.name}
                            </option>)}
                    </select>
                    {values.length === 0 ?
                        <FaTrash className="RemoveDisabled" /> :
                        <FaTrash className="Remove"
                            onClick={() => deleteRunReport(run.get().id, report, reports)} />}
                    <div className="Actions">
                        <CompareResultsShow organizationId={organizationId} hostRunId={runId} report={report}
                            reports={reports} disabled={values.length === 0} />
                        <CompareResultsCreate organizationId={organizationId} hostRunId={runId} report={report}
                            reports={reports} />
                    </div>
                </>, reports)}
        </div>
        <div className="LabelCenter">{locKeyStatic("group")}</div>
        <div className="Group">
            <RadioButtons selected={selectedGrouping} defaultTitle={'Kaikki'}
                buttonNames={U.mapValue((groupsValue) => Object.keys(groupsValue), groups)} />
        </div>
        <div className="ResultLabel">{locKeyStatic("resultList")}</div>
        <div className="Replies">
            {U.mapValue((selectedGroupingValue) => !selectedGroupingValue ?
                <React.Fragment>
                    <DropDown title={organizationName} initial={true}>
                        <QuestionareResults questions={questions} hideTitle={true}
                            thresholds={thresholds}
                            fetch={fetchItems.bind(this, organizationId, run.get().id)} />
                        <ItemList organizationId={organizationId} runId={runId} group={U.atom(null)} />
                    </DropDown>
                    {U.mapElems(child => <DropDown key={child.get().name} title={child.get().name}>
                        <QuestionareSummary questions={questions} hideTitle={false}
                            thresholds={thresholds} hideParent={true}
                            fetch={fetchItems.bind(this, child.get().id, run.get().id)} />
                    </DropDown>, children)}
                </React.Fragment> :
                <React.Fragment>
                    {U.mapElems((groupValue) => <React.Fragment>
                        <DropDown title={groupValue}>
                            <QuestionareResults questions={questions}
                                thresholds={thresholds} hideTitle={true}
                                fetch={fetchGroupItems.bind(this, organizationId,
                                    run.get().id, selectedGroupingValue, groupValue.get())} />
                            <ItemList organizationId={organizationId} runId={runId}
                                group={U.atom({ name: selectedGroupingValue, value: groupValue.get() })} />
                        </DropDown>
                    </React.Fragment>, U.view(selectedGroupingValue)(groups))}
                </React.Fragment>, selectedGrouping)
            }
        </div>
        <div className="Functions" >
            <div />
            <input className="Select" type="checkbox" checked={checked}
                onChange={(e) => checked.set(e.target.checked)} />
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEye /></div>
        </div>
    </div >)
}

const Run = ({ organizationId, organization, run, checked, open }) => {
    const edit = U.atom(open);
    return (<div>
        {U.mapValue((editValue) => editValue ?
            <EditRun organizationId={organizationId} organization={organization} run={run} edit={edit}
                checked={checked} /> :
            <StaticRun organizationId={organizationId} run={run} edit={edit} checked={checked} />, edit)}
    </div>)
}

const fetchRuns = (organizationId, start, count, total, target, loading) => {
    if (loading) { loading.set(true) }
    runService.getOrganizationRuns(organizationId, start, count).then((result) => {
        if (loading) { loading.set(false) }
        total.set(result.total)
        target.set(result.runs)
    })
}

const combineSelected = (start, organizationId, runs, checked) => {
    const runIds = runs.map((r, index) => checked[index] ? r.id : null).filter(id => id)
    runService.combineRuns(organizationId, runIds).then(() => {
        pushAlert(locKeyStatic("combiningSucceeded"), 3000, 'Info')
        start.set(1)
        start.set(0)
    }).catch(() => {
        pushAlert(locKeyStatic("combiningFailed"), 3000)
    })
}

export const RunList = ({ organizationId, organization }) => {
    const runs = U.atom([])
    const start = U.atom(0)
    const count = U.atom(10)
    const checked = U.atom([])
    const combinedDisabled = U.atom(true)
    const loading = U.atom(false)
    runs.observe((values) => {
        checked.set(values.map(() => false))
    })
    checked.observe((values) => {
        combinedDisabled.set(!values.find(v => v))
    })
    return (<div className='RunList'>
        <div className='RunFunction'>
            <div></div>
            {U.mapValue(disabled => <ActionButton disabled={disabled} title={locKeyStatic("combineSelected")}
                onClick={() => combineSelected(start, organization.get().id, runs.get(), checked.get())} />,
                combinedDisabled)}
        </div>
        <div className='Run'>
            <div className='NameHeading'>{locKeyStatic("questionare")}</div>
            <div className='OrganizationHeading'>{locKeyStatic("organization")}</div>
            <div className='ResultHeading'>{locKeyStatic("result")}</div>
            <div className='TypeHeading'>{locKeyStatic("type")}</div>
            <div className='CreatedHeading'>{locKeyStatic("sent")}</div>
            <div className='FunctionsHeading'>{locKeyStatic("functions")}</div>
        </div>
        {U.mapValue(value => value ? <div className="Loader" /> : null, loading)}
        {U.mapElems((r, index) => <Run key={index} open={index === 0}
            organizationId={organizationId} organization={organization} run={r}
            checked={U.view(index)(checked)} />, runs)}
        <PagingTotal start={start} count={count} fetch={fetchRuns.bind(this, organizationId)} target={runs} loading={loading} />
    </div>)
}
