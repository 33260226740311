import * as React from 'react';
import { getLanguage, history, locKeyLang } from '../_helpers';
import { orgService } from '../_services/organization.service';
import { OrganizationTree } from '../_tcomponents/OrganizationTree';
import styled from 'styled-components'
import { NumberEdit } from '../_tcomponents/TextEdit';
import * as calendarService from '../_services/calendar.service'
import { Button, FormControl, MenuItem, Paper, Select, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, TextField } from '@material-ui/core';
import moment from 'moment';
import { debounce } from '../_tcomponents/Selectors';
import { FaTrash } from 'react-icons/fa';
import { pushAlert } from '../_reducers/alert.reducer';
import './CalendarReport.css'
import { useSelector } from 'react-redux';

const CalendarContainer = styled.div`
    padding-left:2.0em;
    padding-right:2.0em;
    width:100%;
    min-height:100%;
    margin-bottom:5em;
    flex:70%;
    background-color:#f0f0f0;
`

const SearchContainer = styled.div`
    display:grid;
    grid-template-columns:20em 10em 1em 10em 8em;
    column-gap:5px;
    margin-top:1.0em;
    margin-bottom:2.0em;
    align-content:center;
`

const ResultContainer = styled.div`
    display:grid;
    grid-template-columns:20em 10em 10em;
    margin:0.5em;
    row-gap:5px;
    column-gap:5px;
    align-items:center;
`

const RowHead = styled.div`
    font-weight:bold;
`


const Participants = styled.div`
    display:grid;
    grid-template-columns: 20em 5em;
    row-gap:10px;
    column-gap:10px;
    align-items:center;
    margin-bottom:2.0em;
`

const Participant = styled.div`
    margin-top:5px;
    margin-bottom:5px;
    padding:10px;
    border-radius:15px;
    border:1px solid lightgray;
`

const ParticipantRemove = styled.div`
    color:red;
    text-align:center;
    cursor:pointer;
`

export const CalendarReport = () => {
    const [search, setSearch] = React.useState({ calendarId: null, duration: 30 })
    const [home, setHome] = React.useState(null)
    const [selected, setSelected] = React.useState(null)
    const [calendars, setCalendars] = React.useState([])
    const [results, setResults] = React.useState([])
    const [startDate, setStartDate] = React.useState(moment().startOf("month").subtract({months: 1}).format("YYYY-MM-DD"))
    const [endDate, setEndDate] = React.useState(moment().subtract({months: 1}).endOf("month").format("YYYY-MM-DD"))
    const [loading, setLoading] = React.useState(false)

    const findReservedEvents = () => {
        if (search.calendarId) {
            setLoading(true)
            setResults([])
            calendarService.getReservedReport(search.calendarId, selected.id, startDate, endDate).then((results) => {
                setResults(results)
                setLoading(false)
            }).catch(() => setLoading(false))
        }
    }

    const user = useSelector((state) => state.authentication?.user)
    if (!user) {
        history.push("/")
    }

    React.useEffect(() => {
        findReservedEvents()
    }, [selected])
    React.useEffect(() => {
        orgService.getHome().then((home) => {
            setHome(home)
            setSelected(home.id)
            calendarService.getCalendars().then((calendars) => {
                setCalendars(calendars)
                setSearch({ ...search, calendarId: calendars[0]?.id })
            })
        }).catch((_) => history.push("/login"))
    }, [])


    return (<div className="CalendarReport" >
        <OrganizationTree className='OrganizationTree'
            heading={locKeyLang('Organizations', getLanguage())}
            root={home}
            selected={selected} setSelected={setSelected} />
        <CalendarContainer>
            <h3>{selected?.name}</h3>
            <Paper style={{ padding: '1.0em' }}>
                <h4>Etsi aikoja</h4>
                <SearchContainer>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <Select variant={"outlined"} value={search.calendarId} 
                            onChange={(evt) => setSearch({...search, calendarId: evt.target.value})} size={"small"}>
                            {calendars.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <input type="date" value={startDate} onChange={(ev) => setStartDate(ev.target.value)}/>
                    <div style={{margin:'auto'}}>-</div>
                    <input type="date" value={endDate} onChange={(ev) => setEndDate(ev.target.value)} />
                    <Button variant="contained" size="small" color="primary" onClick={() => {
                        findReservedEvents()
                    }}>Etsi</Button>
                </SearchContainer>
                <h4>Tulokset</h4>
                <ResultContainer>
                    <RowHead>Järjestäjä</RowHead>
                    <RowHead>Määrä</RowHead>
                    <RowHead>Yhteensä (sis alv)</RowHead>
                    {results.length === 0 ? "Ei järjestettyjä" : results.map((r) => <>
                        <div>{r.organizer}</div>
                        <div>{r.count}&nbsp;kpl</div>
                        <div>{r.grossRevenue.toFixed(2)}&nbsp;€</div>
                    </>)}
                </ResultContainer>
                {loading && <div className="Loader"/>}
            </Paper>
        </CalendarContainer>
    </div>)
}