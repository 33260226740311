import { apiUrl } from './common';

function getQuestions(hash) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/run/questions/${hash}`, requestOptions)
        .then(handleResponse)
}

function saveReplies(hash, replies, submit) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ replies: replies, submit })
    };
    return fetch(`${apiUrl}/run/replies/${hash}`, requestOptions)
        .then(handleResponse)
}

function sendWelcome(hash) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/replies/${hash}/welcome`, requestOptions)
        .then(handleResponse)
}

function getOrganizationRuns(organizationId, start, count) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ start, count })
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}`, requestOptions)
        .then(handleResponse)
}

function getOrganizationTimelineRuns(organizationId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/timeline`, requestOptions)
        .then(handleResponse)
}


function getMatrixOrganizationItems(organizationId, sort, questionare, subQuestionare, runId, group, start, count) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ start, count, sort, questionare, subQuestionare, runId, group })
    };
    return fetch(`${apiUrl}/run/matrix/organization/${organizationId}/items`, requestOptions)
        .then(handleResponse)
}

function combineRuns(organizationId, runIds) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ runIds })
    };
    return fetch(`${apiUrl}/run/combine/organization/${organizationId}`, requestOptions)
        .then(handleResponse)
}

function getRunItem(organizationId, itemId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/item/${itemId}`, requestOptions)
        .then(handleResponse)
}

function getOrganizationRun(organizationId, runId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/run/${runId}`, requestOptions)
        .then(handleResponse)
}

function getOrganizationRunTotal(organizationId, runId, subId, groups) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...subId ? { subId } : {}, ...groups.length > 0 ? { groups } : {} })
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/run/${runId}/total`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = (text && text.length > 0) && JSON.parse(text);
        if (response.status !== 200) {
            throw response.status
        }
        return data;
    });
}

function setLanguage(hash, language) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/language/${hash}/${language}`, requestOptions)
        .then(handleResponse)
}

function sendUserQuestionare(organizationId, userIds, questionareId, templateId, mediums,
    additionalQuestions, consentDataCollection, normMaterialId) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userIds,
            questionareId,
            templateId,
            mediums,
            additionalQuestions,
            consentDataCollection,
            normMaterialId
        })
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/users/questionare`,
        requestOptions).then(handleResponse)
}

function removeRun(organizationId, runId) {
    const requestOptions = {
        method: 'DELETE'
    };
    return fetch(`${apiUrl}/run/organization/${organizationId}/run/${runId}`, requestOptions)
        .then(handleResponse)
}

function getOrganizationRunItems({ organizationId, runId, group, groupValue, start, count, sort }) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ group, groupValue, start, count, sort })
    };
    if (group) {
        return fetch(`${apiUrl}/run/organization/${organizationId}/run/items/${runId}?start=${start}&count=${count}`, requestOptions)
            .then(handleResponse)
    }
    return fetch(`${apiUrl}/run/organization/${organizationId}/run/items/${runId}`, requestOptions)
        .then(handleResponse)
}

function addReminderTime(runId, templateId, time) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ templateId, time })
    };
    return fetch(`${apiUrl}/run/run/${runId}/reminderTime`, requestOptions)
        .then(handleResponse)
}

function saveRunReport(runId, report) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(report)
    };
    return fetch(`${apiUrl}/run/run/${runId}/report`, requestOptions)
        .then(handleResponse)
}

function deleteRunReport(runId, reportId) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/run/run/${runId}/report/${reportId}`, requestOptions)
        .then(handleResponse)
}

function updateItemHandlingStatus(itemId, status) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ itemId, status })
    };
    return fetch(`${apiUrl}/runitem/${itemId}/handlingStatus/${status}`, requestOptions)
        .then(handleResponse)
}

export const runService = {
    getQuestions,
    saveReplies,
    setLanguage,
    addReminderTime,
    getOrganizationRun,
    getOrganizationRunItems,
    getOrganizationRuns,
    sendUserQuestionare,
    removeRun,
    getRunItem,
    sendWelcome,
    combineRuns,
    getMatrixOrganizationItems,
    getOrganizationTimelineRuns,
    getOrganizationRunTotal,
    saveRunReport,
    deleteRunReport,
    updateItemHandlingStatus
};
