import * as React from 'karet';
import * as U from 'karet.util';
import { FaEdit } from 'react-icons/fa'
import { evtTarget } from '../_helpers';
import './EditableTextField.css'

export const EditableTextField = ({ text }) => {
    const edit = U.atom(false);
    return (<span className="EditTextField">{U.mapValue((value) => value ?
        <input value={text} onKeyUp={(evt) => { if (evt.keyCode === 13) edit.set(false) }}
            onBlur={() => edit.set(false)}
            autoFocus={true}
            onChange={(evt) => text.set(evtTarget(evt).value)} /> :
        <span onClick={() => edit.set(true)}>{text}</span>, edit)}
        <FaEdit className='Edit' onClick={() => edit.set(!edit.get())} />
    </span>)
}