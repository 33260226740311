import { deleteApi, getApi, postApi, putApi } from './common';

export async function saveCalendarEvent(event) {
    const body = {...event, state: undefined, serviceType: undefined}
    return await postApi('/calendar/event', body)
}

export async function getCalendars() {
    return await getApi('/calendar')
}

export async function getEvents() {
    return await getApi('/calendar/events')
}

export async function deleteCalendarEvent(id) {
    return await deleteApi(`/calendar/event/${id}`)
}

export async function findFreeEvents(calendarId, duration) {
    return await postApi('/calendar/events/free', {calendarId, duration})
}

export async function getReservedReport(calendarId, organization, startDate, endDate) {
    return await postApi(`/calendar/events/reserved/${organization}`, {calendarId, startDate, endDate})
}

export async function reserveEvent(organization, id, participants) {
    return await putApi(`/calendar/events/reserve/${organization}`, {id, participants})
}

export async function actionEvent(action, hash, password) {
    return await getApi(`/calendar/event?action=${action}&hash=${hash}&password=${password}`)
}

export async function getEvent(hash, password) {
    return await getApi(`/calendar/event?action=get&hash=${hash}&password=${password}`)
}

export async function getEventRun(id) {
    return await getApi(`/calendar/eventrun/${id}`)
}