import * as React from 'karet';
import * as U from 'karet.util';
import { flatten } from 'underscore';
import Select from 'react-select';
import './CircleSelector.scss';

const customStyles = {
    control: (base, state) => ({
        ...base
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

const treeToList = (root, level) => {
    return flatten([{ value: root.uuid, label: root.key, level }].concat(
            root.children.map((child) => treeToList(child, level + 1))));
}

export const CircleSelector = ({ organization, circle, circles, allowEmpty }) => {
    const list = U.atom([]);
    circles.observe((circles) => {
        let listValue = allowEmpty ? [{ value: null, label: 'Ei käsitettä' }] : []
        for (const circle of circles) {
            listValue = listValue.concat(treeToList(circle, 1))
        }
        list.set(listValue);
    })
    return (<div className='CircleTree'>{U.mapValue((listValue) =>
        <Select styles={customStyles} value={listValue.find((option) => circle.get() === option.value)}
            options={listValue} onChange={(newSelection) => {
                circle.set(newSelection.value);
                list.set(JSON.parse(JSON.stringify(list.get())));
            }} />, list)}</div>);
}

const normMaterialToList = (root, level) => {
    return flatten([].concat(
            root.library.map((child) => ({ value: child.uuid, label: child.description.fi }))));
}

export const NormMaterialSelector = ({ circle, circles, allowEmpty }) => {
    const list = U.atom([]);
    circles.observe((circles) => {
        let listValue = allowEmpty ? [{ value: null, label: 'Ei materiaalia' }] : []
        for (const circle of circles) {
            listValue = listValue.concat(normMaterialToList(circle, 1))
        }
        list.set(listValue);
    })
    return (<div className='CircleTree'>{U.mapValue((listValue) =>
        <Select styles={customStyles} value={listValue.find((option) => circle.get() === option.value)}
            options={listValue} onChange={(newSelection) => {
                circle.set(newSelection.value);
                list.set(JSON.parse(JSON.stringify(list.get())));
            }} />, list)}</div>);
}