import * as React from 'karet';
import * as U from 'karet.util';
import { MdCreateNewFolder } from 'react-icons/md';
import { typeHasChildren, parentTypeToSubTypes, typeToName, typeToLogo } from '../_helpers';
import { orgService } from "../_services/organization.service";
import { pushAlert } from '../_reducers/alert.reducer';
import './CreateOrganization.css'

const createNewOrganization = (parentNode, type, selected) => {
    orgService.createOrganization(parentNode.get().id, type, "Uusi").then((newOrg) => {
        sessionStorage.setItem("selectedOrganization", newOrg);
        selected.set(newOrg);
        return orgService.getOrganization(parentNode.get().id).then((org) => {
            parentNode.set(org);
        })
    }).catch((err) => {
        pushAlert(err === 400 ? 'Virheellinen pyyntö' : 'Sinulla ei ole riittävästi oikeuksia.', 5);
    });
}

export const CreateOrganization = ({ className, parentNode, selected }) => {
    const type = U.view('type')(parentNode);
    const show = U.atom(false)
    return (<div className={className} onClick={() => show.set(!show.get())}>
        {U.mapValue((value) => typeHasChildren(value) ?
            <MdCreateNewFolder style={{ cursor: 'pointer' }} /> : '', type)}
        {U.mapValue((visible) =>
            visible ? <div className="CreateOrganizationDropDown">
                <div className="Toggle"><MdCreateNewFolder /><div className="Overlap" /></div>
                <div className="ContentHolder">
                    {U.mapValue((type) =>
                        <React.Fragment>
                            {parentTypeToSubTypes(type).map((subType) =>
                                <div className={subType}
                                    onClick={() => {
                                        show.set(false);
                                        createNewOrganization(parentNode, subType, selected);
                                    }}>
                                    {typeToName(subType)}
                                    <span style={{ position: 'absolute', right: 5 }}>{typeToLogo(subType)}</span>
                                </div>)}
                        </React.Fragment>, type)}
                </div>
            </div> : '', show)}
    </div >)
}