import * as React from 'karet';
import * as U from 'karet.util';
import { default as ReactSelect } from 'react-select';
import PropTypes from "prop-types";
import { filter, find, map } from 'underscore';
import { orgService } from '../_services/organization.service';
import { GroupMultiSelector } from './GroupSelector';
import './UserSelector.css';
import { locKey, locKeyStatic } from '../_helpers';

const customStyles = {
    control: (base, state) => ({
        ...base,
        width: '18em'
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em',
            selected: true
        }
    }
};

const fetch = ({ organizationId, interviewees, groups, recursive, selectedGroups }) => {
    orgService.getInterviewees(organizationId, 0, 1000, recursive).then((result) => {
        if (Object.keys(groups.get()).length === 0) {
            const groupValue = {}
            for (const user of result.users) {
                for (const group of user.groups) {
                    if (!(group.name in groupValue)) {
                        groupValue[group.name] = []
                    }
                    if (groupValue[group.name].indexOf(group.value) === -1) {
                        groupValue[group.name].push(group.value)
                    }
                }
            }
            groups.set(groupValue)
        }

        const users = selectedGroups.length > 0 ? result.users.filter(user => {
            for (const kv of user.groups) {
                if (selectedGroups.find((selected) => selected.value === kv.value)) {
                    return true
                }
            }
            return false
        }) : result.users
        interviewees.set(users)
    })
}

export const UserSelector = ({ organization, selectedUser }) => {
    const interviewees = U.atom([])
    const groups = U.atom({})
    const recursive = U.atom(false)
    const selectedGroups = U.atom([])
    const filterFields = U.molecule({ recursive, selectedGroups })
    filterFields.observe(({ recursive, selectedGroups }) => {
        fetch({ organizationId: organization, interviewees, groups, recursive, selectedGroups })
    })
    const contentChange = U.molecule({ interviewees, selectedUsers: selectedUser })
    return (<div className='UserSelector'>
        {U.mapValue(groupValue => Object.keys(groupValue).length > 0 ?
            <React.Fragment>
                <div className='Label'>Suodata</div>
                <GroupMultiSelector selectedGroups={selectedGroups} groups={groups} />
            </React.Fragment> : '', groups)}
        <div className="Label">{locKey("interviewees")}</div>
        {U.mapValue(({ interviewees, selectedUsers }) => {
            const options = map(interviewees, (user) => ({ value: user.id, label: user.email }))
            const value = filter(options, (option) => find(selectedUsers, (o) => o === option.value))
            if (options.length > 0) {
                return (<Select styles={customStyles} isMulti={true}
                    allOption={{ label: locKeyStatic("all"), value: "*" }}
                    allowSelectAll={true}
                    value={value}
                    options={options} onChange={(newSelection) => {
                        const userIds = map(newSelection, (v) => v.value)
                        selectedUser.set(userIds);
                    }} />)
            } else {
                return (<Select styles={customStyles} isMulti={true}
                    value={value}
                    options={options} onChange={(newSelection) => {
                        const userIds = map(newSelection, (v) => v.value)
                        selectedUser.set(userIds);
                    }} />)
            }
        }, contentChange)}
        <div className="Recursive">
            <input type="checkbox" onChange={(evt) => recursive.set(evt.target.checked)} />
            <span className="Label">{locKey("subOrganizations")}</span>
        </div>
    </div >);
}

const Select = props => {
    if (props.allowSelectAll) {
        if (props.value.length === props.options.length) {
            return (
                <ReactSelect
                    {...props}
                    value={[props.allOption]}
                    onChange={selected => props.onChange(selected ? selected.slice(1) : null)}
                />
            );
        }

        return (
            <ReactSelect
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={selected => {
                    if (selected &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    }

    return <ReactSelect {...props} />;
};

Select.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    })
};

Select.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*"
    }
};