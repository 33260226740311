export const apiUrl = '/api';

export const postApi = async(url, body) => {
    const response = await fetch(`${apiUrl}${url}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    })
    return await response.json()
}

export const putApi = async(url, body) => {
    const response = await fetch(`${apiUrl}${url}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    })
    return await response.json()
}

export const getApi = async(url) => {
    const response = await fetch(`${apiUrl}${url}`, {
        method: 'GET'
    })
    return await response.json()
}

export const deleteApi = async(url) => {
    const response = await fetch(`${apiUrl}${url}`, {
        method: 'DELETE'
    })
    return response.status
}