import { localizeText } from './ui';
import loc from './questions.json'

const colors = [
    { p: 0.0, r: 255, g: 0, b: 0 },
    { p: 0.25, r: 255, g: 165, b: 0 },
    { p: 0.50, r: 255, g: 255, b: 0 },
    { p: 0.75, r: 154, g: 205, b: 50 },
    { p: 1.0, r: 0, g: 128, b: 0 }
]



function colToCss(col, a) {
    return `rgba(${Math.round(col.r)}, ${Math.round(col.g)}, ${Math.round(col.b)}, ${a})`;
}

function interpolate(col1, col2, pp) {
    return {
        r: col1.r + (col2.r - col1.r) * pp,
        g: col1.g + (col2.g - col1.g) * pp,
        b: col1.b + (col2.b - col1.b) * pp
    }
}

function posToColor(pos, opacity) {
    let i = 0;
    if (colors.length < 1) {
        return colToCss({ r: 1.0, g: 1.0, b: 1.0 }, opacity)
    }
    while (colors[i].p < pos && i < colors.length - 1) i++;

    const ncol = colors[i]
    const col = colors[i > 0 ? i - 1 : i]

    if (i >= 1) {
        const pp = (pos - col.p) / (ncol.p - col.p);
        const icol = interpolate(col, ncol, pp)
        return colToCss(icol, opacity);
    }
    return colToCss(col, opacity);
}

export function replyToColor(question, value, opacity) {
    const [min, max] = questionRange(question);
    const pos = (value - min) / (max - min);
    return posToColor(pos, opacity);
}

export function percentageToColor(percentage, opacity) {
    return posToColor(percentage, opacity)
}

export function questionRange(question) {
    if (Array.isArray(question)) {
        const totals = [0, 0]
        for (const q of question) {
            const qvals = questionRange(q)
            totals[0] += qvals[0]
            totals[1] += qvals[1]
        }
        return totals
    }
    else if (question.subId) {
        return questionRange(question.questions)
    }
    switch (question.reply) {
        case 'agreeNotAgreeSelect':
            return [1, 5]
        case 'inverseAgreeNotAgreeSelect6':
        case 'agreeNotAgreeSelect6':
            return [1, 6]
        case 'text':
            return [0, 0]
        case 'enumerationText':
            return [0, 0]
        case 'optionPoints':
            let min = null, max = null
            if (!question.replyParameters || !question.replyParameters.options) return [0, 0]
            for (const option of question.replyParameters.options) {
                if (!min || option.value < min) { min = option.value }
                if (!max || option.value > max) { max = option.value }
            }
            return [min, max]
        default:
            return [1, 5]
    }
}

export function questionsRange(questions) {
    const range = [0, 0]
    for (let q of questions) {
        if (q.id) {
            const qr = questionRange(q);
            range[0] += qr[0];
            range[1] += qr[1];
        } else if (q.subId) {
            const subRange = questionsRange(q.questions);
            range[0] += subRange[0];
            range[1] += subRange[1];
        }
    }
    return range;
}

export const getMinText = (question, language) => {
    switch (question.reply) {
        case 'inverseAgreeNotAgreeSelect6':
            return localizeText(loc.fullyAgree, language)
        case 'agreeNotAgreeSelect':
        case 'agreeNotAgreeSelect6':
            return localizeText(loc.fullyDisagree, language)
        case 'optionPoints':
            const options = question.replyParameters.options
            return options[0].text[language]
        case 'enumerationText':
            return ''
        default:
            const minText = question.replyParameters.minText
            return minText[language] || minText["fi"];
    }
}

export const getMaxText = (question, language) => {
    switch (question.reply) {
        case 'inverseAgreeNotAgreeSelect6':
            return localizeText(loc.fullyDisagree, language)
        case 'agreeNotAgreeSelect':
        case 'agreeNotAgreeSelect6':
            return localizeText(loc.fullyAgree, language)
        case 'optionPoints':
            const options = question.replyParameters.options
            return options[options.length - 1].text[language]
        case 'enumerationText':
            return ''
        default:
            const maxText = question.replyParameters.maxText
            return maxText[language] || maxText["fi"];
    }
}

export const getColors = () => {
    return colors.map((c) => colToCss(c, 1.0))
}