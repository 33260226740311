import * as React from 'karet';
import { percentageToColor } from './questions';
import * as U from 'karet.util';

export const ResultBar = ({ total, percentage, marginTop, normPercentage }) => {
    return <svg style={{ width: '7em', height: '1.6em', marginTop: marginTop || '0.3em' }}>
        {normPercentage ?
            <React.Fragment>
                <rect x="5%" y="5%" width="34%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
                    fill={percentageToColor(percentage, 1.0)}>
                </rect>
                <text x="22%" y="70%" textAnchor="middle">{total}</text>
                <rect x="46%" y="5%" width="50%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
                    fill={percentageToColor(normPercentage, 1.0)}>
                </rect>
                <text x="71%" y="70%" textAnchor="middle">N{(normPercentage * 100).toFixed(0)}%</text>
            </React.Fragment> :
            U.mapValue((value) => <React.Fragment>
                {value && <rect x="5%" y="5%" width="90%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
                    fill={percentageToColor(percentage, 1.0)}>
                </rect>}
                {value ? <text x="50%" y="70%" textAnchor="middle">{value}</text> : 
                    <text x="0%" y="70%" textAnchor="middle">-</text>}
            </React.Fragment>, total)
            
        }

    </svg>
}

export const ResultBarPercentage = ({ percentage, marginTop }) => {
    return <svg style={{ width: '7em', height: '1.6em', marginTop: marginTop || '0.3em' }}>
        <rect x="5%" y="5%" width="90%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
            fill={percentageToColor(percentage, 1.0)}>
        </rect>
        <text x="50%" y="70%" textAnchor="middle">{(percentage * 100).toFixed(0)}%</text>
    </svg>
}

export const ResultBarChange = ({ percentage, change }) => {
    return <svg style={{ width: '3em', height: '1.6em', marginTop: '0.3em' }}>
        <rect x="5%" y="5%" width="90%" height="90%" rx="5" ry="5" stroke={"#efefff"} strokeWidth="2"
            fill={percentageToColor((percentage + 1.0) / 2.0, 1.0)}>
        </rect>
        <text x="50%" y="70%" textAnchor="middle">{(change <= 0 ? "" : "+") + change}</text>
    </svg>
}