import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import "./LibrarySelector.scss"
import { libraryService } from '../../_services/library.service';
import { locKey, locKeyPlain, locText, locKeyStatic } from '../../_helpers/ui';
import { CloseButton, ActionButton } from '../BasicElements';

const _visible = U.atom(false)
const _onSelect = U.atom(null)
const _organizationId = U.atom(null)
const _libraryItems = U.atom([])
const _selectedItems = U.atom([])
const _searchText = U.atom("")

const renderItem = (item, select) => {
    const description = U.view("description")(item)
    return (<div key={U.view("uuid")(item)} className={select ? "Item" : "ItemSelected"}>
        <div>{locText(description.get())}</div>
        <div>
            <button onClick={() =>
                select ? _selectedItems.modify(R.append(item.get())) : item.remove()}
                className="ActionButton">
                {select ? locKey("select") : locKey("remove")}
            </button>
        </div>
    </div>)
}


const renderCircle = (item, select) => {
    return (<>
        <div key={U.view("uuid")(item)} className={select ? "Circle" : "CircleSelected"}>
            <div className="HeaderName">{U.view("key")(item)}</div>
            <div>
                <button onClick={() => select ? _selectedItems.modify(R.append(item.get())) : item.remove()}
                    className="ActionButton">
                    {select ? locKey("select") : locKey("remove")}
                </button>
            </div>
        </div>
        <div className="Content">
            {U.mapElems(item => renderItem(item, select), U.view("library")(item))}
            {U.mapElems(item => renderCircle(item, select), U.view("children")(item))}
        </div>
    </>)
}

export const LibrarySelector = () => {
    _searchText.skip(1).debounce(1000).observe((text) => {
        if (_visible.get()) {
            libraryService.searchLibraryItems(_organizationId.get(), text, "Question").then((libraryItems) => {
                _libraryItems.set(libraryItems)
            })
        }
    })
    _visible.skip(1).observe(() => {
        libraryService.searchLibraryItems(_organizationId.get(), _searchText.get(), "Question").then((libraryItems) => {
            _libraryItems.set(libraryItems)
        })
    })

    return (<React.Fragment>
        {U.mapValue(visibleValue => visibleValue ?
            <div className="LibrarySelector">
                <div className="TopBar">
                    <span>{locKeyStatic("search")}</span>
                    <input type="text" value={_searchText} className="Search"
                        onChange={(evt) => _searchText.set(evt.target.value)} />
                </div>
                <div className="ContentBar">
                    <div className="ContentColumn">
                        <div className="HeaderText">{locKeyPlain("resultsWord")}</div>
                        <div>
                            {U.mapElems(item => renderCircle(item, true), _libraryItems)}
                        </div>
                    </div>
                    <div className="ContentColumn">
                        <div className="HeaderText">{locKeyPlain("selected")}</div>
                        <div>
                            {U.mapElems(item => item.get().key ?
                                renderCircle(item, false) :
                                renderItem(item, false), _selectedItems)}
                        </div>
                    </div>
                </div>
                <div className="BottomBar">
                    <div></div>
                    <CloseButton onClick={() => _visible.set(false)} title={locKey("close")} />
                    <ActionButton onClick={() => {
                        _onSelect.get()(_selectedItems.get())
                        _selectedItems.set([])
                        _visible.set(false)
                    }
                    } title={locKey("addSelected")} />
                </div>
            </div>
            : null, _visible)}
    </React.Fragment>)
}

export const showLibrarySelector = (organizationId, onSelect) => {
    _organizationId.set(organizationId.get())
    _onSelect.set(onSelect)
    _visible.set(true)
}