import { apiUrl } from './common';

function createQuestionare(organization, group, name) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ organization, group, name })
    };
    return fetch(`${apiUrl}/questionare`, requestOptions)
        .then(handleResponse)
}

function getQuestionare(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/questionare/${id}`, requestOptions)
        .then(handleResponse)
}

function removeQuestionare(id) {
    const requestOptions = {
        method: 'DELETE'
    };
    return fetch(`${apiUrl}/questionare/${id}`, requestOptions)
        .then(handleResponse)
}

function updateQuestionare(questionare) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionare)
    };
    return fetch(`${apiUrl}/questionare`, requestOptions)
        .then(handleResponse)
}

function getAll(organizationId) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/questionare/organization/${organizationId}`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = (text && text.length > 0) && JSON.parse(text);
        if (response.status !== 200) {
            throw response.status
        }
        return data;
    });
}

export const qService = {
    getQuestionare,
    createQuestionare,
    updateQuestionare,
    removeQuestionare,
    getAll
};
