const areSetsEqual = (a, b) => a.size === b.size && [...a].every(value => b.has(value));

const allRights = [
    'admin',
    'userManagement',
    'organizationManagement',
    'scheduleManagement',
    'questionareManagement',
    'runAccess',
    'individualRights',
    'openAnswers',
    'templateManagement',
    'userItemAccess',
    'libraryManagement',
    'system',
    'personalInformation',
    'createMeetings',
    'reserveMeetings']

const individualResultViewer = [
    'admin',
    'userManagement',
    'organizationManagement',
    'runAccess',
    'individualRights',
    'personalInformation',
    'openAnswers']

const totalResultViewer = [
    'admin',
    'userManagement',
    'organizationManagement',
    'runAccess']

const userManager = [
    'admin',
    'userManagement',
    'organizationManagement']

export function mapRightsToRole(rights) {
    const userRights = new Set(rights)
    const allRightsSet = new Set(allRights)
    if (areSetsEqual(allRightsSet, userRights)) {
        return "all"
    }
    if (areSetsEqual(new Set(individualResultViewer), userRights)) {
        return "individualResultViewer"
    }
    if (areSetsEqual(new Set(totalResultViewer), userRights)) {
        return "totalResultViewer"
    }
    if (areSetsEqual(new Set(userManager), userRights)) {
        return "userManager"
    }
    return "custom"
}

export function roleToRights(role, rights) {
    switch (role) {
        case "all": {
            return allRights
        }
        case "individualResultViewer": {
            return individualResultViewer
        }
        case "totalResultViewer": {
            return totalResultViewer
        }
        case "userManager": {
            return userManager
        }
        default: {
            return rights
        }
    }
}