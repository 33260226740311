import * as React from 'react';
import Creatable from 'react-select/creatable';
import { find, map } from 'underscore';
import './GroupSelector.css'

const customStyles = {
    control: (base, state) => {
        return {
            ...base,
            fontSize: '0.9em',
            width: '10em',
            minHeight: '24px',
            maxHeight: '24px',
            borderRadius: '0px',
            paddingTop: '0px'
        }
    },
    option: (base, state) => {
        return {
            ...base,
            maxHeight: '24px',
            paddingTop: '0px',
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    },
    dropdownIndicator: (base) => ({
        ...base,
        fontSize: '0.9em',
        paddingTop: '0px',
        minHeight: '15px',
        maxHeight: '15px'
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: '0.9em',
        minHeight: '24px',
        maxHeight: '24px'
    })
};

export const GroupSelector = ({ selectedGroup, setSelectedGroup, groups }) => {
    return (<div className='GroupSelector'>
        <Creatable styles={customStyles}
            value={find(groups, (option) => selectedGroup === option.value)}
            options={map(groups, (group) => ({ value: group, label: group }))}
            onChange={(newSelection) => {
                setSelectedGroup(newSelection.value);
            }}/> 
    </div>)
}