import * as React from 'react'
import { clone } from '../_helpers/ui'
import { Checkbox as CheckboxMui } from '@material-ui/core';

const setOption = (checked, options, setOptions, value) => {
    const optionsValue = clone(options)
    if (optionsValue.indexOf(value) !== -1 && !checked) {
        optionsValue.splice(optionsValue.indexOf(value), 1)
    } else if (optionsValue.indexOf(value) === -1 && checked) {
        optionsValue.push(value)
    }
    setOptions(optionsValue)
}

export const Checkbox = ({ selectedOptions, setOptions, value }) => {
    return (<CheckboxMui
        checked={selectedOptions.indexOf(value) !== -1}
        onChange={(evt) => setOption(evt.target.checked, selectedOptions, setOptions, value)} />)
}