import * as React from 'karet';
import * as U from 'karet.util';
import { FaCircle } from 'react-icons/fa';
import { evtTarget } from '../_helpers';
import { mapRightsToRole, roleToRights } from '../_helpers/role';
import { setLanguage, locKeyPlain } from '../_helpers/ui';
import './Selectors.css';

export const WeekdaySelector = ({ value }) => {
    return (<select className='WeekdaySelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="0" selected={value.get() === "0"}>{locKeyPlain("sunday")}</option>
        <option value="1" selected={value.get() === "1"}>{locKeyPlain("monday")}</option>
        <option value="2" selected={value.get() === "2"}>{locKeyPlain("tuesday")}</option>
        <option value="3" selected={value.get() === "3"}>{locKeyPlain("wednesday")}</option>
        <option value="4" selected={value.get() === "4"}>{locKeyPlain("thursday")}</option>
        <option value="5" selected={value.get() === "5"}>{locKeyPlain("friday")}</option>
        <option value="6" selected={value.get() === "6"}>{locKeyPlain("saturday")}</option>
    </select>)
}


export const VisibilitySelector = ({ value }) => {
    return (<select className='LanguageSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="individual" selected={value.get() === "individual"}>{locKeyPlain("visibilityIndividual")}</option>
        <option value="right" selected={value.get() === "right"}>{locKeyPlain("visibilityRight")}</option>
        <option value="all" selected={value.get() === "all"}>{locKeyPlain("visibilityAll")}</option>
    </select>)
}

export const ColumnSelector = ({ value }) => {
    return (<select className='ColumnSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="firstName" selected={value.get() === "firstName"}>{locKeyPlain("firstName")}</option>
        <option value="lastName" selected={value.get() === "lastName"}>{locKeyPlain("lastName")}</option>
        <option value="phoneNumber" selected={value.get() === "phoneNumber"}>{locKeyPlain("phoneNumber")}</option>
        <option value="email" selected={value.get() === "email"}>{locKeyPlain("email")}</option>
        <option value="name" selected={value.get() === "name"}>{locKeyPlain("name")}</option>
        <option value="unit" selected={value.get() === "unit"}>{locKeyPlain("subOrganization")}</option>
        <option value="language" selected={value.get() === "language"}>{locKeyPlain("language")}</option>
        <option value="group" selected={value.get() === "group"}>{locKeyPlain("group")}</option>
        <option value="" selected={value.get() === ""}>{locKeyPlain("noImport")}</option>
    </select>)
}

export const HandlingStatusSelector = ({ value }) => {
    return (<select className='ColumnSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="New" selected={value.get() === "New"}>{locKeyPlain("new")}</option>
        <option value="Progress" selected={value.get() === "Progress"}>{locKeyPlain("progress")}</option>
        <option value="Ready" selected={value.get() === "Ready"}>{locKeyPlain("ready")}</option>
    </select>)
}

export const UserItemStatusSelector = ({ value }) => {
    return (<select className='LanguageSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="New" selected={value.get() === "New"}>{locKeyPlain("new")}</option>
        <option value="Handled" selected={value.get() === "Handled"}>{locKeyPlain("handled")}</option>
    </select>)
}

export const IntervalSelector = ({ length, value }) => {
    return (<select className='IntervalSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="Days" selected={value.get() === "Days"}>{locKeyPlain("days")}</option>
        <option value="Weeks" selected={value.get() === "Weeks"}>{locKeyPlain("weeks")}</option>
        <option value="Months" selected={value.get() === "Months"}>{locKeyPlain("months")}</option>
    </select>)
}

export const ColorSelector = ({ value }) => {
    return (<div className="ColorSelector">
        {["rgb(255,0,0)", "rgb(255,165,0)", "rgb(255,255,0)",
            "rgb(154,205,50)", "rgb(0,128,0)"].map((color) =>
                <React.Fragment>
                    {U.mapValue((v) => v !== color ? <FaCircle className="Ball"
                        style={{ color }} onClick={() => value.set(color)} /> :
                        <FaCircle className="SelectedBall"
                            style={{ color }} onClick={() => value.set(color)} />, value)}
                </React.Fragment>)
        }
    </div >)
}

export const ConditionTypeSelector = ({ value }) => {
    return (<>
        {U.mapValue(currentValue => <select className='ConditionTypeSelector' value={currentValue.type} onChange={(evt) => {
            switch (evt.target.value) {
                case "If":
                    value.set({ type: "If", source: { type: "Questionare", value: null }, operator: "GreaterThan", parameter: 0, conditions: [] })
                    break;
                case "And":
                    value.set({ type: "And", conditions: [{ type: "If", source: { type: "Questionare", value: null }, operator: "GreaterThan", parameter: 0 }] })
                    break;
                case "Or":
                    value.set({ type: "Or", conditions: [{ type: "If", source: { type: "Questionare", value: null }, operator: "GreaterThan", parameter: 0 }] })
                    break;
                default:
                    value.set({ type: "None", conditions: [] })
                    break;
            }
        }}>
            <option value="None" selected={value.get().type === "None"}>{locKeyPlain("noCondition")}</option>
            <option value="If" selected={value.get().type === "If"}>{locKeyPlain("oneCondition")}</option>
            <option value="And" selected={value.get().type === "And"}>{locKeyPlain("allConditions")}</option>
            <option value="Or" selected={value.get().type === "Or"}>{locKeyPlain("someCondition")}</option>
        </select>, value)}</>)
}

export const SourceSelector = ({ value }) => {
    return (<select className='SourceSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
        <option value="Total" selected={value.get() === "Total"}>{locKeyPlain("total")}</option>
        <option value="Question" selected={value.get() === "Question"}>{locKeyPlain("question")}</option>
    </select>)
}

export const OperatorSelector = ({ value }) => {
    return (<>
        {U.mapValue(currentValue => <select className='SourceSelector' value={currentValue} onChange={(evt) => value.set(evtTarget(evt).value)}>
            <option value="LessThan" selected={value.get() === "LessThan"}>{locKeyPlain("smallerThan")}</option>
            <option value="GreaterThan" selected={value.get() === "GreaterThan"}>{locKeyPlain("largerThan")}</option>
            <option value="Equal" selected={value.get() === "Equal"}>{locKeyPlain("equal")}</option>
            <option value="Between" selected={value.get() === "Between"}>{locKeyPlain("between")}</option>
        </select>, value)}
    </>)
}

export const TimeSelector = ({ value }) => {
    return (<>
        {U.mapValue(currentValue => <select className='SourceSelector' onChange={(evt) => value.set(evtTarget(evt).value)}>
            <option value="Before" selected={value.get() === "Before"}>{locKeyPlain("before")}</option>
            <option value="Current" selected={value.get() === "Current"}>{locKeyPlain("now")}</option>
        </select>, value)}
    </>)
}

export const RoleSelector = ({ custom, rights }) => {
    return <>{U.mapValue(rightsValue => {
        const role = mapRightsToRole(rightsValue)
        custom.set(role === "custom")
        return <select className='SourceSelector' onChange={(evt) => {
            custom.set(evtTarget(evt).value === "custom")
            rights.set(roleToRights(evtTarget(evt).value, rightsValue))
        }}>
            <option value="custom" selected={role === "custom"}>{locKeyPlain("customRole")}</option>
            <option value="userManager" selected={role === "userManager"}>{locKeyPlain("userManagerRole")}</option>
            <option value="totalResultViewer" selected={role === "totalResultViewer"}>{locKeyPlain("totalResultViewerRole")}</option>
            <option value="individualResultViewer" selected={role === "individualResultViewer"}>{locKeyPlain("individualResultViewerRole")}</option>
            <option value="all" selected={role === "all"}>{locKeyPlain("allRole")}</option>
        </select>
    }, rights)}</>
}

export const LanguageSelector = ({ value, changeDefault }) => {
    return (<select className='LanguageSelector' onChange={(evt) => {
        if (changeDefault) {
            setLanguage(evtTarget(evt).value)
        }
        value.set(evtTarget(evt).value)
    }}>
        <option value="fi" selected={value.get() === "fi"}>{locKeyPlain("finnish")}</option>
        <option value="en" selected={value.get() === "en"}>{locKeyPlain("english")}</option>
        <option value="sv" selected={value.get() === "sv"}>{locKeyPlain("swedish")}</option>
        <option value="ee" selected={value.get() === "ee"}>{locKeyPlain("estonia")}</option>
    </select>)
}
