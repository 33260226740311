import * as React from 'karet';
import * as U from 'karet.util';
import { connect } from 'react-redux';
import { orgService } from '../_services/organization.service';
import { OrganizationTree } from '../_components/OrganizationTree';
import { OrganizationDetails } from '../OrganizationDetails/OrganizationDetails';
import { ProtectedPage } from '../_components/ProtectedPage';
import './HomePage.css';
import { locKey } from '../_helpers';

class HomePage extends ProtectedPage {
    constructor() {
        super()
        this.state = {
            home: U.atom(null),
            selected: U.atom(null)
        }
    }

    UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount()
        this.getHome();
    }

    getHome() {
        orgService.getHome().then((home) => {
            this.state.home.set(home)
            return orgService.getOrganization(home.id)
        }).then((org) => {
            if (!this.state.selected.get()) {
                this.state.selected.set(org);
            }
        }).catch(() => {
        })
    }

    render() {
        const { home, selected } = this.state;
        const { user } = this.props
        return (
            <div className="HomePage" >
                {
                    U.mapValue((value) => value ? <OrganizationTree className='OrganizationTree'
                        heading={locKey('Organizations')}
                        root={home}
                        selected={selected} /> : '', home)
                }
                {
                    U.mapValue((value) => value ? <OrganizationDetails
                        onChange={this.getHome.bind(this)}
                        organizationInitial={value} rights={user ? user.rights : []} /> : '', selected)
                }
            </div >
        );
    }
}

function mapStateToProps(state) {
    return { user: state.authentication.user }
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage }; 