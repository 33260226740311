import * as React from 'react';
import { MdCreateNewFolder } from 'react-icons/md';
import { typeHasChildren, parentTypeToSubTypes, typeToName, typeToLogo } from '../_helpers';
import { orgService } from "../_services/organization.service";
import { pushAlert } from '../_reducers/alert.reducer';
import './CreateOrganization.css'

const createNewOrganization = (parentNode, setParentNode, type, selected) => {
    orgService.createOrganization(parentNode.id, type, "Uusi").then((newOrg) => {
        sessionStorage.setItem("selectedOrganization", newOrg);
        selected.set(newOrg);
        return orgService.getOrganization(parentNode.id).then((org) => {
            setParentNode(org);
        })
    }).catch((err) => {
        pushAlert(err === 400 ? 'Virheellinen pyyntö' : 'Sinulla ei ole riittävästi oikeuksia.', 5);
    });
}

export const CreateOrganization = ({ className, parentNode, selected }) => {
    const type = parentNode.type
    const [show, setShow] = React.useState(false)
    return (<div className={className} onClick={() => setShow(!show)}>
        {typeHasChildren(type) ?
            <MdCreateNewFolder style={{ cursor: 'pointer' }} /> : ''}
        {show ? <div className="CreateOrganizationDropDown">
            <div className="Toggle"><MdCreateNewFolder /><div className="Overlap" /></div>
            <div className="ContentHolder">
                <React.Fragment>
                    {parentTypeToSubTypes(type).map((subType) =>
                        <div className={subType}
                            onClick={() => {
                                show.set(false);
                                createNewOrganization(parentNode, subType, selected);
                            }}>
                            {typeToName(subType)}
                            <span style={{ position: 'absolute', right: 5 }}>{typeToLogo(subType)}</span>
                        </div>)}
                </React.Fragment>
            </div>
        </div> : ''}
    </div >)
}