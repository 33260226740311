import * as React from 'react';
import { getLanguage, history, locKeyLang } from '../_helpers';
import { systemService } from '../_services/system.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import './SystemPage.scss'
import { Box, Button, Paper, Table, TableBody, TableCell, 
    TableContainer, TableHead, TableRow } from '@material-ui/core';
import { TextEditSingle } from '../_tcomponents/TextEdit';
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const Container = styled.div`
    margin:1.0em;
`

const Section = styled.div`
    display:grid;
    position:relative;
    grid-template-columns: 12em 20em;
    grid-column-gap: 2px;
    grid-row-gap: 4px;
    padding:10px 10px 10px 10px;
    margin:2px;
    min-width:100%;
    border-radius: 5px;
    border: 1px solid lightgray;
`

const Heading = styled.h4`
    margin-top:1.0em;
    grid-column-start: 1;
    grid-column-end: 3;
`

const Label = styled.div`
`

const Value = styled.input`
    padding-left:5px;
    background-color:lightgray;
    width: 6em;
`

const Calendars = styled.div`
    border-radius: 5px;
    border: 1px solid lightgray;
    padding:10px 10px 10px 10px;
`

const CalendarList = styled.div`
    display:grid;
    grid-template-columns: 20em 40em;
`

export const SystemPage = () => {
    const language = getLanguage()
    const [resources, setResources] = useState([])
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [calendars, setCalendars] = useState([])
    useEffect(() => {
        try {
            systemService.getResources().then((resources) => setResources(resources))
            systemService.getCalendars().then((calendars) => setCalendars(calendars))
        }
        catch {
            pushAlert(locKeyLang("couldNotLoad", language), 5000)
        }
    }, [])

    const user = useSelector((state) => state.authentication?.user)
    if (!user) {
        history.push("/")
    }

    return (<Container>
        <Section>
            <Heading>{locKeyLang("resources", language)}</Heading>
            <Label>{locKeyLang("smsFinland", language)}</Label>
            <Value disabled value={`${resources.finlandSmsCount} ${locKeyLang("pcs", language)}`}/>
            <Label>{locKeyLang("smsWorld", language)}</Label>
            <Value disabled value={`${resources.worldSmsCount} ${locKeyLang("pcs", language)}`}/>
        </Section>
        <Calendars>
           <Heading>{locKeyLang("calendars", language)}</Heading>
           <Box component="form">
                <TextEditSingle style={{marginRight:5}} label={locKeyLang("name", language)} value={name} setValue={setName}/>
                <TextEditSingle style={{marginRight:5,minWidth:400}} label={locKeyLang("description", language)}
                    value={description} setValue={setDescription} />
                <Button style={{marginTop:2}} size="medium" color="primary" variant="contained"
                    onClick={async () => {
                        const calendar = await systemService.createCalendar({name, description})
                        setName("")
                        setDescription("")
                        setCalendars(calendars.concat([calendar]))
                    }}>
                    {locKeyLang("createNewWords", language)}
                </Button>
            </Box>
            <TableContainer component={Paper} style={{marginTop:20}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: 200}}>{locKeyLang("name", language)}</TableCell>
                            <TableCell>{locKeyLang("description", language)}</TableCell>
                            <TableCell>{locKeyLang("eventsWord", language)}</TableCell>
                            <TableCell style={{width: 50}}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {calendars.map((row) => (
                        <TableRow>
                            <TableCell>
                                {row.name}
                            </TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>{row.events}</TableCell>
                            <TableCell>
                                <FaTrash style={{cursor:'pointer'}} onClick={() => {
                                    systemService.deleteCalendar(row.id).then((success) => {
                                        if (success) {
                                            setCalendars(calendars.filter(c => c.id !== row.id))
                                        } else {
                                            pushAlert(locKeyLang("couldNotLoad", language), 5000)
                                        }
                                    })
                                }}/>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Calendars>
    </Container>)
}