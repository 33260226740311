import * as React from 'react'
import { useEffect, useState } from 'react'
import { FaEdit, FaTrash, FaMailBulk, FaCheck } from 'react-icons/fa';
import { extend } from 'underscore';
import { userService } from '../_services/user.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { TextEdit, NumberEdit } from '../_tcomponents/TextEdit';
import { LanguageSelector } from '../_tcomponents/Selectors'
import { evtTarget, locKeyStatic, locKey, timeFromNow } from '../_helpers/ui';
import readXlsxFile from 'read-excel-file';
import { showErrorDialog, showYesNoDialog, showOkDialog } from '../_ui/Dialog';
import { PagingTotal } from '../_tcomponents/Paging';
import { showImportUsers } from './editors/ImportUsers';
import { AddButton, ActionButton } from './BasicElements';
import { MdErrorOutline } from 'react-icons/md';
import { orgService } from '../_services/organization.service';
import { debounce, RoleSelector } from '../_tcomponents/Selectors';
import './UserList.scss';
import { ValueList } from '../_tcomponents/ValueList';
import { OrganizationSelector } from '../_tcomponents/OrganizationSelector';
import { mapRightsToRole } from '../_helpers/role';
import { Checkbox } from '../_tcomponents/Checkbox';
import styled from 'styled-components'
import { useIsMount } from '../Utils';


const CheckboxList = styled.div`
    display:grid;
    grid-template-columns: 2em 15em 2em 15em;
    column-gap:5px;
    row-gap:0px;
    align-items:center;
    font-size:0.8em;
`

const updateUser = (userValue) => {
    return userService.updateUser(userValue)
        .catch(() => {
            pushAlert(locKeyStatic("userUpdateFailed"), 5);
        })
}

const allRights = {
    admin: "admin",
    userManagement: "userManagement",
    organizationManagement: "organizationManagement",
    scheduleManagement: "schedulingControl",
    questionareManagement: "questionareManagement",
    runAccess: "result",
    individualRights: "individualResults",
    personalInformation: "personalInformationResults",
    openAnswers: "openAnswers",
    templateManagement: "templates",
    userItemAccess: "events",
    libraryManagement: "libraryManagement",
    system: "system",
    createMeetings: "createMeetings",
    reserveMeetings: "reserveMeetings"
}

const updateUserDebounced = debounce(updateUser, 1000)

const createUser = (users, setUsers, email, setEmail, adminOrganization, intervieweeOrganization) => {
    userService.createUser(email, adminOrganization, intervieweeOrganization)
        .then((user) => {
            setUsers([user].concat(users))
            setEmail("")
        })
        .catch((err) => {
            pushAlert(locKeyStatic("userCreateFailed"), 5);
        });
}

const sendWelcome = (user) => {
    showYesNoDialog({
        title: locKeyStatic("confirmSend"),
        message: locKeyStatic("sendWelcomeEmailConfirmation"),
        onYes: () => {
            userService.sendWelcome(user.email).then(() => {
                pushAlert(locKeyStatic("userWelcomeMessageSent"), 5, "Info")
            }).catch(() => {
                pushAlert(locKeyStatic("userWelcomeMessageFailed"), 5)
            })
        }
    })
}

const removeUser = (onRemove, isAdmin, user) => {
    if (user.adminOrganization !== null && user.intervieweeOrganization !== null) {
        updateUser(extend(user, isAdmin ? { adminOrganization: null } : { intervieweeOrganization: null }))
            .then(() => {
                onRemove()
            })
    } else {
        userService.removeUser(user.email).then(() => onRemove())
            .catch((error) => {
                pushAlert(locKeyStatic("removingUserFailed"), 5);
            });
    }
}

const showRemoveUser = (user, isAdmin, onRemove) => {
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: `${locKeyStatic("areYouSureToRemove")} "${user.firstName + " " + user.lastName}" ${isAdmin ? locKeyStatic("user") : locKeyStatic("respondent")}?`,
        parameter: user,
        onYes: removeUser.bind(this, onRemove, isAdmin, user)
    });
}

const showAnonymise = (setStart, intervieweeOrganization) => {
    showYesNoDialog({
        title: locKeyStatic("confirmAnonymise"),
        message: locKeyStatic("areYouSureToAnonymise"),
        parameter: intervieweeOrganization,
        onYes: (intervieweeOrganization) => {
            orgService.anonymiseOrganization(intervieweeOrganization).then((response) => {
                setStart(0)
                showOkDialog({
                    title: locKeyStatic("anonymiseDone"),
                    message: locKeyStatic("anonymiseDoneMessage", response)
                })
            })
        }
    });
}

const showDeanonymise = (setStart, intervieweeOrganization) => {
    showYesNoDialog({
        title: locKeyStatic("confirmDeanonymise"),
        message: locKeyStatic("areYouSureToDeanonymise"),
        parameter: intervieweeOrganization,
        onYes: (intervieweeOrganization) => {
            orgService.deanonymiseOrganization(intervieweeOrganization).then((response) => {
                setStart(0)
                showOkDialog({
                    title: locKeyStatic("deanonymiseDone"),
                    message: locKeyStatic("deanonymiseDoneMessage", response)
                })
            })
        }
    });
}

const StaticUser = ({ user, userEdit, setUserEdit, isAdmin, onRemove }) => {
    return (<div className='User'>
        <div className='Email'>{user.anonymiseKey ? locKeyStatic("anonymised") : user.email}</div>
        {user.latestLogin && user.latestLogin.timestamp ? <div className={user.latestLogin.success ? 'LatestLogin Success' : 'LatestLogin Fail'}>
            <div>{timeFromNow(user.latestLogin.timestamp)}</div>
            <div>{user.latestLogin.success ? <FaCheck /> : <MdErrorOutline />}</div>
        </div> : <div className="NoLatestLogin">-</div>}
        {!user.anonymiseKey &&
            <div className='Operations'>
                <div className='Remove' onClick={() => showRemoveUser(user, isAdmin, onRemove)}><FaTrash /></div>
                <div className='Edit' onClick={() => setUserEdit(!userEdit)}><FaEdit /></div>
            </div>
        }
    </div>)
}

const EditUser = ({ allGroups, userInitial, userEdit, setUserEdit, isAdmin, showRights, showWelcome, onRemove }) => {
    const [user, setUser] = useState(userInitial)
    const isMount = useIsMount()
    useEffect(() => {
        if (!isMount) {
            updateUserDebounced(user)
        }
    }, [user])

    const organization = isAdmin ? user.adminOrganization : user.intervieweeOrganization
    const [custom, setCustom] = useState(mapRightsToRole(user.rights) === "custom")

    return (<div className='EditUser'>
        <h4>{locKey("modifyUserInformation")}</h4>
        <div className='Label'>{locKey("firstName")}</div><TextEdit value={user} setValue={setUser} member='firstName' />
        <div className='Label'>{locKey("lastName")}</div><TextEdit value={user} setValue={setUser} member='lastName' />
        <div className='Label'>{locKey("email")}</div>
        <div className='ValueWithIcon'>
            <TextEdit value={user} setValue={setUser} member={'email'} />
            {showWelcome ? <div className='Invite' onClick={() => sendWelcome(user)}><FaMailBulk /></div> : ''}
        </div>
        <div className='Label'>{locKey("phoneNumber")}</div>
        <TextEdit value={user} setValue={setUser} member={'phoneNumber'} />
        {showRights ? <React.Fragment>
            <div className='Label'>{locKey("rights")}</div>
            <div className='Rights'>
                <div className="RoleSelector">
                    <RoleSelector setCustom={setCustom} rights={user.rights} 
                        setRights={(rights) => setUser({...user, rights})} />
                </div>
                {custom && <CheckboxList>
                    {Object.keys(allRights).map(right => 
                        <><Checkbox selectedOptions={user.rights} size="small" style={{padding:0}} setOptions={(rights) => {
                            setUser({...user, rights})
                        }} value={right} /><span>{locKey(allRights[right])}</span></>
                    )}
                </CheckboxList>}
            </div>
        </React.Fragment> : ''}
        <div className='Label'>{locKey("resultDepth")}</div>
        <NumberEdit value={user} setValue={setUser} member='resultRightsDepth' />
        <div className='Label'>{locKey("resultLevels")}</div>
        <NumberEdit value={user} setValue={setUser} member='resultRightsLayers' />
        <div className='Label'>{locKey("language")}</div>
        <div>
            <LanguageSelector value={user.language} setValue={(language) => setUser({...user, language})} />
        </div>
        <div className='Label'>{locKey("groups")}</div>
        <div>
            <ValueList groups={allGroups} values={user.groups} setValues={(groups) => setUser({...user, groups})} />
        </div>
        <div className='Label'>{locKey("organization")}</div>
        <OrganizationSelector organization={organization} setOrganization={(value) => {
            setUser({...user, ...isAdmin ? {adminOrganization: value} : {intervieweeOrganization: value}})
        }} />
        <div className="Operations">
            <div className='Remove' onClick={() => showRemoveUser(user, isAdmin)}><FaTrash /></div>
            <div className='Edit' onClick={() => setUserEdit(!userEdit)}><FaEdit /></div>
        </div>
    </div >)
}

export const EditUserContact = ({ user, edit }) => {
    user.skip(1).debounce(1000).observe((userValue) => {
        if (edit.get()) {
            updateUser(userValue)
        }
    });
    const firstName = U.view('firstName')(user);
    const lastName = U.view('lastName')(user);
    const email = U.view('email')(user);
    const phoneNumber = U.view('phoneNumber')(user);
    return (<div className='EditUserContact'>
        <h4>{locKey("modifyUserInformation")}</h4><span></span>
        <div className='Label'>{locKey("firstName")}</div><TextEdit value={firstName} />
        <div className='Label'>{locKey("lastName")}</div><TextEdit value={lastName} />
        <div className='Label'>{locKey("email")}</div><TextEdit value={email} />
        <div className='Label'>{locKey("phoneNumber")}</div><TextEdit value={phoneNumber} />
    </div >)
}

const formatImportResults = (result) => {
    let text = locKeyStatic("importStatement", result)
    if (result.missingUnits && result.missingUnits.length > 0) {
        text += " " + locKeyStatic("missingSubOrganizations", { missingUnits: result.missingUnits.join(",") })
    }
    return text
}

const importExcel = (intervieweeOrganization, onOrganizationChange) => {
    const input = document.getElementById('importInterviewees')
    readXlsxFile(input.files[0]).then((rows) => {
        input.value = ""
        input.value = null
       

        rows[0] = rows[0].map((c) => c.toLowerCase());
        const columns = rows[0]
        rows.splice(0, 1)
        showImportUsers(columns, rows, (users) => {
            userService.importInterviewees(intervieweeOrganization, users).then((result) => {
                pushAlert(formatImportResults(result), 15, 'Info')
                onOrganizationChange()
            }).catch(() => {
                pushAlert(locKeyStatic("addingRespondentsFailed"), 5)
            })
        })
    }).catch((error) => {
        showErrorDialog({
            title: locKeyStatic("wrongFormat"),
            message: locKeyStatic("fileIsNotInExcelFormat")
        })
    })
}

export const User = ({ user, isAdmin, showRights, groups, showWelcome, onRemove }) => {
    const [userEdit, setUserEdit] = useState(false)
    return (<div>
       {userEdit ?
            <EditUser userInitial={user} userEdit={userEdit} setUserEdit={setUserEdit} 
                allGroups={groups} isAdmin={isAdmin}
                showRights={showRights} showWelcome={showWelcome} onRemove={onRemove} /> :
            <StaticUser user={user} userEdit={userEdit} setUserEdit={setUserEdit} isAdmin={isAdmin} onRemove={onRemove} />
        }
    </div>)
}

export const StaticUserContact = ({ user }) => {
    return (<div className='EditUserContact'>
        <h4>Yhteystiedot</h4><span></span>
        <div className='Label'>{locKey("firstName")}</div><div>{user.firstName}</div>
        <div className='Label'>{locKey("lastName")}</div><div>{user.lastName}</div>
        <div className='Label'>{locKey("email")}</div><div>{user.email}</div>
        <div className='Label'>{locKey("phoneNumber")}</div><div>{user.phoneNumber}</div>
    </div >)
}

export const UserList = ({ groups, showRights, showWelcome, recursive,
    onOrganizationChange, adminOrganization, intervieweeOrganization }) => {
    const [newEmail, setNewEmail] = useState("") 
    const [start, setStart] = useState(0)
    const [count, setCount] = useState(20)
    const [users, setUsers] = useState([])
    const [total, setTotal] = useState(0)
    useEffect(() => {
        if (adminOrganization) {
            orgService.getOrganization(adminOrganization).then((org) => {
                setUsers(org.admins)
            })
        } else if (intervieweeOrganization) {
            orgService.getInterviewees(intervieweeOrganization, start, count, recursive).then((response) => {
                setUsers(response.users)
                setTotal(response.total)
            })
        }
    }, [adminOrganization, intervieweeOrganization, start, count])
    return (<div className='UserList'>
        <div className='User'>
            <div className='EmailHeading'>{locKey("email")}</div>
            <div className='LatestLoginHeading'>{locKey("latestLogin")}</div>
            <div className='OperationsHeading'>{locKey("functions")}</div>
        </div>
        {users.map((user) => <User key={user.id} user={user} groups={groups}
            isAdmin={adminOrganization !== undefined} showRights={showRights} showWelcome={showWelcome}
            onRemove={() => setUsers(users.filter(u => u.email !== user.email))} />)}
        <PagingTotal start={start} setStart={setStart} count={count} total={total}/>
        <div className='User'>
            <div className='Label'>{locKey("email")}</div>
            <div className='NewEmail'>
                <input type="text" value={newEmail}
                    onChange={(evt) => setNewEmail(evtTarget(evt).value)} />
            </div>
            <AddButton onClick={() => createUser(users, setUsers, newEmail, setNewEmail, adminOrganization, intervieweeOrganization)}
                title={locKey("add")} />
            {intervieweeOrganization ?
                <div className="ActionButtons">
                    <ActionButton className={"AnonymiseButton"} onClick={() => showAnonymise(start, intervieweeOrganization)}
                        title={locKey("anonymise")} />
                    <ActionButton className={"AnonymiseButton"} onClick={() => showDeanonymise(start, intervieweeOrganization)}
                        title={locKey("deanonymise")} />
                    <ActionButton className={"ImportButton"} onClick={() => document.getElementById("importInterviewees").click()}
                        title={locKey("import")} />
                    <input type="file" id="importInterviewees"
                        onChange={() => importExcel(intervieweeOrganization, () => {
                            setStart(0)
                            onOrganizationChange()
                        })} />
                </div> : ''}
        </div>
    </div>)
}