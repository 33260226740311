import * as React from 'karet';
import * as U from 'karet.util';
import * as L from 'partial.lenses';
import { TextArea } from './TextEdit';
import { evtTarget, locKey, getDefaultLanguage } from '../_helpers/ui';
import './LanguageTextEdit.css';

export const Badge = ({ visible, current, text }) => {
    const valueText = U.molecule({ visible, text })
    return <span className={U.mapValue(({ visible, text }) => visible === current ? "BadgeSelected" :
        text && text.length > 0 ? "Badge" : "BadgeMissing", valueText)}
        onClick={() => visible.set(current)}> {current}</span>
}

export const LanguageTextEdit = ({ values, rows }) => {
    const fi = U.view('fi')(values);
    const en = U.view('en')(values);
    const sv = U.view('sv')(values);
    const ee = U.view(['ee', L.defaults('')])(values);
    const visible = U.atom(getDefaultLanguage())
    return (<div className='LanguageTextEdit'>
        {U.mapValue(value => {
            switch (value) {
                case 'fi':
                    return (<TextArea value={fi} rows={rows} />)
                case 'en':
                    return (<TextArea value={en} rows={rows} />)
                case 'ee':
                    return (<TextArea value={ee} rows={rows} />)
                default:
                    return (<TextArea value={sv} rows={rows} />)
            }
        }, visible)}
        <Badge visible={visible} current='fi' text={fi} />
        <Badge visible={visible} current='en' text={en} />
        <Badge visible={visible} current='sv' text={sv} />
        <Badge visible={visible} current='ee' text={ee} />
    </div>)
}

export const LanguageTitleEdit = ({ values }) => {
    const fi = U.view('fi')(values);
    const en = U.view('en')(values);
    const sv = U.view('sv')(values);
    const ee = U.view(['ee', L.defaults('')])(values);
    return (<div className='LanguageTitleEdit'>
        <div className='Label'>{locKey("finnish")}</div>
        <input value={fi} onChange={(evt) => fi.set(evtTarget(evt).value)} />
        <div className='Label'>{locKey("english")}</div>
        <input value={en} onChange={(evt) => en.set(evtTarget(evt).value)} />
        <div className='Label'>{locKey("swedish")}</div>
        <input value={sv} onChange={(evt) => sv.set(evtTarget(evt).value)} />
        <div className='Label'>{locKey("estonia")}</div>
        <input value={ee} onChange={(evt) => ee.set(evtTarget(evt).value)} />
    </div>)
}
