import * as React from 'react';
import { connect } from 'react-redux';
import { ProtectedPage } from '../_components/ProtectedPage';
import { User } from './User';
import './MyPage.scss'

class MyPage extends ProtectedPage {
    render() {
        const { user, dispatch } = this.props;
        return (user ?
            <div className="MyPage">
                <User user={user} isAdmin={false} showRights={false} showWelcome={false}
                    showTwoFactor={true} dispatch={dispatch} />
            </div> : ''
        );
    }
}

function mapStateToProps(state) {
    return { user: state.authentication.user }
}

const connectedMyPage = connect(mapStateToProps)(MyPage);
export { connectedMyPage as MyPage }; 