import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import * as L from 'partial.lenses';
import { LanguageTitleEdit, LanguageTextEdit } from './LanguageTextEdit';
import { evtTarget, optionLocKey, locKey } from '../_helpers/ui';
import { NumberEdit } from './TextEdit';
import { ActionButton } from './BasicElements';
import './ReplyEditor.scss';
import { VisibilitySelector } from './Selectors';
import { FaTrash } from 'react-icons/fa';
import { BooleanCheckbox } from './Checkbox';

const setReplyType = (type, reply, parameters) => {
    reply.set(type);
    if (type === 'optionPoints') {
        parameters.set({ excludeFromSums: false, options: [] })
    }
    else if (type === 'customScaleSelect') {
        parameters.set({
            minText: { fi: "", sv: "", en: "", ee: "" },
            maxText: { fi: "", sv: "", en: "", ee: "" }
        });
    } else if (type === 'enumerationText') {
        parameters.set({
            values: [],
            type: "free"
        })
    } else {
        parameters.set({});
    }
}

const organizationNamesToList = (organization) => {
    let list = []
    list.push({fi: organization.name, sv: organization.name, en: organization.name, ee: organization.name })
    for (let index in organization.children) {
        list = list.concat(organizationNamesToList(organization.children[index]))
    }
    return list
}

export const CustomScale = ({ parameters }) => {
    const minText = U.view("minText")(parameters);
    const maxText = U.view("maxText")(parameters);
    return (<div className="CustomScale">
        <div>Min</div>
        <LanguageTitleEdit values={minText} />
        <div>Max</div>
        <LanguageTitleEdit values={maxText} />
    </div>)
}

export const OptionPoints = ({ parameters }) => {
    const options = U.view(['options', L.defaults([])])(parameters)
    return (<div className="OptionPoints">
        <div className="Option">
            <div className="Label">Valinnan teksti</div>
            <div className="Label">Pisteet</div>
        </div>
        {U.mapElems(option => {
            const text = U.view('text')(option)
            const value = U.view('value')(option)
            return (<div className="Option">
                <LanguageTextEdit values={text} rows={1} />
                <NumberEdit value={value} />
            </div>)
        }, options)}
        <ActionButton onClick={() => options.modify(
            R.append({ text: { fi: "", en: "", sv: "", ee: "" }, value: 1 }))} title="Lisää" />
    </div>)
}

export const FreeText = ({ parameters }) => {
    const visibility = U.view("visibility")(parameters);
    return <div>
        <div className='Title'>{locKey("visibility")}</div>
        <VisibilitySelector value = { visibility } />
    </div>
}

export const EnumerationText = ({ parameters }) => {
    const values = U.view(['values', L.defaults([])])(parameters)
    const type = U.view('type')(parameters)
    const title = U.view('title')(parameters)
    return (<div className="EnumerationValues">
        <div className="Label">{locKey("type")}</div>
        <select value={type} onChange={(evt) => {
            values.set([])
            type.set(evtTarget(evt).value)
        }}>
            {optionLocKey("group", "groupTextSelection", type)}
            {optionLocKey("organization", "organisationTextSelection", type)}
        </select>
        <div className="Label">{locKey("title")}</div>
        <LanguageTextEdit values={title} />
        {U.mapValue(typeValue => typeValue === "organization" ? <>
           </> : 
            <>
                <div className="Label">{locKey("selectionText")}</div>
                    {U.mapElems((value) => {
                        return (<div className="Value">
                            <LanguageTextEdit values={value} />
                            <FaTrash className="Delete" onClick={() => value.remove()} />
                        </div>)
                    }, values)}
                <ActionButton onClick={() => values.modify(
                    R.append({ fi: "", en: "", sv: "", ee: "" }))} title={locKey("add")} />                
            </>, type)}
    </div>)
}

export const ReplyEditor = ({ question, organization }) => {
    const reply = U.view("reply")(question);
    const parameters = U.view(["replyParameters", L.defaults({ options: [] })])(question);
    const excludeFromSums = U.view(["excludeFromSums", L.defaults(false)])(question);
    return (<div className='ReplyEditor'>
        <div className='Title'>{locKey("answer")}</div>
        <select value={reply} onChange={(evt) => setReplyType(evtTarget(evt).value, reply, parameters)}>
            {optionLocKey('agreeNotAgreeSelect6', "agreeDisagree16", reply)}
            {optionLocKey('inverseAgreeNotAgreeSelect6', "disagreeAgree16", reply)}
            {optionLocKey('agreeNotAgreeSelect', "agreeDisagree15", reply)}
            {optionLocKey('customScaleSelect', "opposites15", reply)}
            {optionLocKey('text', "freeText", reply)}
            {optionLocKey('optionPoints', "optionPoints", reply)}
            {optionLocKey('enumerationText', "enumerationText", reply)}
        </select>
        <div className='ExcludeFromSums'>
            <BooleanCheckbox value={excludeFromSums} /><div>{locKey("leaveOutFromSums")}</div>
        </div>
        {
            U.mapValue((replyValue) => {
                switch (replyValue) {
                    case 'customScaleSelect':
                        return <CustomScale parameters={parameters} />
                    case 'optionPoints':
                        return <OptionPoints parameters={parameters} />
                    case 'text':
                        return <FreeText parameters={parameters} />
                    case 'enumerationText':
                        return <EnumerationText parameters={parameters} organization={organization} />
                    default:
                        return null
                }
            }, reply)
        }
    </div >)
}