import { Component } from 'react';
import { history } from '../_helpers';
import { userConstants } from '../_constants/user.constants';
import { userService } from '../_services/user.service';

export class ProtectedPage extends Component {
    UNSAFE_componentWillMount() {
        this.checkUserLoggedIn();
    }

    UNSAFE_componentWillUpdate() {
        this.checkUserLoggedIn();
    }

    checkUserLoggedIn() {
        if (!this.props.user) {
            userService.getUser().then((user) => {
                this.props.dispatch({ type: userConstants.LOGIN_SUCCESS, user })
                history.push('/')
            }).catch(() => {
                history.push('/login')
            })
        }
    }

    render() {
        return (this.props.children);
    }
}