import * as React from 'karet';
import * as U from 'karet.util';
import { filter, range } from 'underscore';
import { Component } from 'react';
import { runService } from '../_services/run.service';
import * as qs from 'query-string';
import { localizeText, locKey, setLanguage, getLanguage, locKeyStatic, localizeTextWithFormatting } from '../_helpers/ui';
import loc from './Localizations.json'
import { percentageToColor, questionsRange } from '../_helpers/questions';
import { history } from '../_helpers';
import { ActionButton, CloseButton } from '../_components/BasicElements';
import './ReplyPage.scss'

export class ReplyPage extends Component {
    constructor() {
        super();
        const left = U.atom(null)
        const highlight = U.atom(false)
        this.state = {
            language: U.atom(getLanguage()),
            questionare: null,
            replies: U.atom([]),
            done: U.atom('Sent'),
            isAnonymous: U.atom(false),
            anonymousLink: U.atom(null),
            hasConsent: true,
            left,
            highlight,
            range: U.atom([0, 0]),
            result: U.atom(0),
            resultText: U.atom({}),
            showWelcome: false,
            showError: false,
            leftHighlight: U.molecule({ highlight, left })
        }
    }

    getHash() {
        return qs.parse(this.props.location.search).hash
    }

    getTheme() {
        return qs.parse(this.props.location.search).theme
    }

    getEmptyReplies(questionare, replies) {
        questionare.questions.forEach((q) => {
            if (q.subId) {
                this.getEmptyReplies(q, replies)
            } else if (q.id) {
                replies[q.id] = { id: q.id, value: q.reply === "text" ? "" : null }
            }
        })
        return replies
    }

    componentWillMount() {
        runService.getQuestions(this.getHash())
            .then((questionare) => {
                if (questionare.status === "Replied") {
                    this.state.done.set(questionare.status);
                }
                this.state.range.set(questionsRange(questionare.questions))
                this.state.replies.set(this.getEmptyReplies(questionare, {}))
                this.state.language.set(questionare.language);
                this.state.isAnonymous.set(questionare.isAnonymous)
                this.state.hasConsent = questionare.hasConsent
                setLanguage(questionare.language)
                this.setState({ questionare });
            })
            .catch((error) => {
                this.state.done.set("Error")
            })
    }

    setAnswer(value, reply) {
        const { replies, left, language, highlight } = this.state;
        reply.modify((r) => ({ id: r.id, value }))

        highlight.set(false)
        const replyArray = Object.values(replies.get())
        const count = filter(replyArray, (q) => q.value === null).length;

        left.set(count > 0 ? `${loc.left[language.get()].replace("{pcs}", count)}` :
            loc.allAnswered[language.get()])
    }

    renderOption(value, reply) {
        const self = this;
        return U.mapValue((replyValue) =>
            <span id={`Option-${reply.get().id}-${value}`}
                className={replyValue.value === value ? 'OptionSelected' : 'Option'}
                onClick={() => self.setAnswer(value, reply)}>
                {value}
            </span>, reply)
    }

    renderRadio(params, reply) {
        const self = this;
        return U.mapValue((replyValue) =>
            <div className='RadioRow'>
                <span id={`Option-${reply.get().id}-${params.value}`}
                    className={replyValue.value === params.value ||
                        replyValue.value === params.value[this.state.language.get()] ? 'RadioSelected' : 'Radio'}
                    onClick={() => {
                        const language = this.state.language.get()
                        if (params.value[language]) {
                            self.setAnswer(params.value[language], reply)
                        } else {
                            self.setAnswer(params.value, reply)
                        }
                    }}>
                    {replyValue.value === params.value ? <div></div> : ""}
                </span>
                <span>{localizeText(params.text, this.state.language)}</span>
            </div>, reply)
    }

    renderAgreeNotAgree(q, replies, min, max) {
        const reply = U.view(q.id)(replies)
        return (<div className='AgreeNotAgree'>
            <span className="ExplanationLeft">{localizeText(loc.fullyDisagree, this.state.language)}</span>
            {range(min, max).map((i) => this.renderOption(i, reply))}
            <span className="ExplanationRight">{localizeText(loc.fullyAgree, this.state.language)}</span>
        </div>)
    }

    inverseRenderAgreeNotAgree(q, replies, min, max) {
        const reply = U.view(q.id)(replies)
        return (<div className='AgreeNotAgree'>
            <span className="ExplanationLeft">{localizeText(loc.fullyAgree, this.state.language)}</span>
            {range(min, max).map((i) => this.renderOption(i, reply))}
            <span className="ExplanationRight">{localizeText(loc.fullyDisagree, this.state.language)}</span>
        </div>)
    }

    renderCustomScaleSelect(question, replies, min, max) {
        const p = question.replyParameters;
        const reply = U.view(question.id)(replies)
        return (<div className='AgreeNotAgree'>
            <span className="ExplanationLeft">{localizeText(p.minText, this.state.language)}</span>
            {range(min, max).map((i) => this.renderOption(i, reply))}
            <span className="ExplanationRight">{localizeText(p.maxText, this.state.language)}</span>
        </div>)
    }

    renderOptionPoints(question, replies) {
        const p = question.replyParameters.options
        const reply = U.view(question.id)(replies)
        return (<div className='OptionPoints'>
            {p.map(params => this.renderRadio(params, reply))}
        </div >)
    }

    renderEnumerationText(question, replies) {
        const values = question.replyParameters.values
        const reply = U.view(question.id)(replies)
        return (<div className='OptionPoints'>
            {values.map(value => this.renderRadio({ value, text: value }, reply))}
        </div >)
    }

    renderText(q, replies) {
        const reply = U.view(q.id)(replies)
        const value = U.view("value")(reply)
        return (<div className='TextReply'>
            <textarea cols="80" rows="4" className='Text' type="text" value={value} onChange={(evt) => this.setAnswer(evt.target.value, reply)} />
        </div>)
    }

    renderReply(q) {
        const { replies } = this.state;
        switch (q.reply) {
            case 'customScaleSelect':
                return this.renderCustomScaleSelect(q, replies, 1, 6)
            case 'agreeNotAgreeSelect6':
                return this.renderAgreeNotAgree(q, replies, 1, 7)
            case 'inverseAgreeNotAgreeSelect6':
                return this.inverseRenderAgreeNotAgree(q, replies, 1, 7)
            case 'text':
                return this.renderText(q, replies)
            case 'optionPoints':
                return this.renderOptionPoints(q, replies)
            case 'enumerationText':
                return this.renderEnumerationText(q, replies)
            default:
                return this.renderAgreeNotAgree(q, replies, 1, 6)
        }
    }

    renderQuestion(q, index, answered) {
        return (<div className={answered ? "Question FadeOut" : "Question"}>
            <h4 className="Text">{index + 1}. {localizeText(q, this.state.language)}</h4>
            {this.renderReply(q)}
        </div>)
    }

    renderQuestionare(questionare, sub, replies) {
        return (<React.Fragment>
            {sub ? <h3>{localizeText(questionare.title, this.state.language)}</h3> :
                <React.Fragment>
                    <h2>{localizeText(questionare.title, this.state.language)}</h2>
                    <hr></hr>
                </React.Fragment>
            }
            <div>{localizeText(questionare.description, this.state.language)}</div>
            <h3>{localizeText(questionare.questions, this.state.language)}</h3>
            {questionare.questions.map((q, index) => {
                if (q.id) {
                    return this.renderQuestion(q, index, replies[q.id].value !== null && replies[q.id].value !== "")
                } else if (q.subId) {
                    return this.renderQuestionare(q, true, replies)
                }
                return null
            })}
        </React.Fragment>)
    }

    renderConsent(questionare) {
        return (<div className="ConsentContainer">
            <h3>{localizeText(loc.collectionHeading, this.state.language)}</h3>
            <div>{localizeTextWithFormatting(loc.consentText, this.state.language)}</div>
            <div className="ConsentButtons">
                <CloseButton onClick={() => window.location.href="https://cauhacare.fi"} title={locKey("noLeavePage")}/>
                <ActionButton onClick={() => this.setState({hasConsent: true})} title={locKey("yesContinueToQuestionare")}/>
            </div>
        </div>)
    }

    submitReplies() {
        const { result, resultText } = this.state
        runService.saveReplies(this.getHash(), Object.values(this.state.replies.get()), true).then((report) => {
            result.set({ percentage: report.normPercentage || report.result, type: report.normPercentage ? "Norm" : "Total" })
            resultText.set(report)
            this.state.isAnonymous.set(report.isAnonymous)
            if (report.isAnonymous) {
                this.state.anonymousLink.set(report.link)
            }
            if (report.additionalQuestions) {
                this.state.done.set('Partial')
            } else {
                this.state.done.set('Replied')
            }
        })
    }

    setLanguage(language) {
        runService.setLanguage(this.getHash(), language).then(() => {
            this.state.language.set(language);
            setLanguage(language)
        }).catch(() => {
            this.state.language.set(language);
            setLanguage(language)
        })
    }

    polarToCartesian(cx, cy, radius, angle) {
        var radians;
        radians = (angle - 90) * Math.PI / 180.0;
        return [Math.round((cx + (radius * Math.cos(radians))) * 100) / 100, Math.round((cy + (radius * Math.sin(radians))) * 100) / 100];
    }

    svgArcPath(x, y, radius, startAngle, endAngle) {
        var endXy, startXy;
        startXy = this.polarToCartesian(x, y, radius, endAngle);
        endXy = this.polarToCartesian(x, y, radius, startAngle);
        return "M " + startXy[0] + " " + startXy[1] + " A " + radius + " " + radius + " 0 0 0 " + endXy[0] + " " + endXy[1];
    }

    doLogin() {
        history.push('/mypage')
    }

    sendWelcome() {
        runService.sendWelcome(this.getHash())
            .then(() => {
                this.setState({ showWelcome: true })
            })
            .catch(() => {
                this.setState({ showError: true })
            })
    }

    renderResultText(text) {
        const language = this.state.language.get()
        const { resultText } = text
        return (<React.Fragment>
            {resultText.texts.length > 0 ?
                <React.Fragment>
                    {resultText.level === 1 ?
                        <h3>{resultText.title[language]}</h3> :
                        <h4>{resultText.title[language]}</h4>}
                    {resultText.texts.map(text => <p className="text">{text[language]}</p>)}
                </React.Fragment> : ""}
            {resultText.subs.map(subText => <p>{this.renderResultText(subText)}</p>)}
        </React.Fragment >)
    }

    hasUnanswered() {
        const { replies } = this.state
        const replyArray = Object.values(replies.get())
        const count = filter(replyArray, (q) => q.value === null).length;
        return count > 0;
    }

    replyAdditionalQuestions() {
        const { anonymousLink } = this.state
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        if (anonymousLink.get()) {
            window.location.href = "/reply?hash=" + anonymousLink.get()
        } else {
            window.location.reload()
        }
    }

    render() {
        const { questionare, done, result, resultText, showWelcome, showError, replies, leftHighlight,
            highlight, isAnonymous, hasConsent } = this.state
        return (<React.Fragment>
            {U.mapValue((doneValue) => doneValue !== 'Sent' ?
                <div className="Overlay">
                    <div className="Thanks">
                        <div className="Scroll">
                            {U.mapValue(({ percentage, type }) => percentage > 0.0 ?
                                <React.Fragment>
                                    <svg viewBox="0 0 100 50">
                                        <path stroke-width="7" fill="none" stroke={"#efefef"}
                                            d={this.svgArcPath(50, 50, 45, -90, 90)}></path>
                                        <path stroke-width="5" fill="none" stroke={percentageToColor(percentage, 1.0)}
                                            d={this.svgArcPath(50, 50, 45, -90, -90 + percentage * 180)}></path>
                                        <text x={50} y={48} textAnchor="middle" fill={percentageToColor(percentage, 1.0)}>
                                            {(percentage * 100).toFixed(0)}%
                                    </text>
                                    </svg>
                                    <div className="Number">
                                        {type === "Norm" ?
                                            localizeText(loc.resultTypeNorm, this.state.language) :
                                            localizeText(loc.resultTypeTotal, this.state.language)
                                        }
                                    </div>
                                    <div className="Description">
                                        {U.mapValue((text) => this.renderResultText(text), resultText)}
                                    </div>
                                    {U.mapValue(anonymous => !anonymous || doneValue !== "Replied" ?
                                        <>
                                            {localizeText(doneValue === 'Replied' || doneValue === 'Partial' ?
                                                loc.thankYou : loc.error, this.state.language)}
                                            <div className="Login">
                                                {doneValue !== 'Replied' ? localizeText(loc.partial, this.state.language) : ''}
                                                {doneValue === "Replied" ?
                                                    null :
                                                    <div className="ButtonRowSingle">
                                                        <ActionButton onClick={this.replyAdditionalQuestions.bind(this)}
                                                            title={locKey("replyAdditional")} />
                                                    </div>}
                                                {showWelcome ? <div className="Welcome">
                                                    {localizeText(loc.welcomeSent, this.state.language)}
                                                </div> : ''}
                                                {showError ? <div className="Error">
                                                    {localizeText(loc.welcomeError, this.state.language)}
                                                </div> : ''}
                                            </div>
                                        </> : null, isAnonymous)}
                                </React.Fragment> :
                                <React.Fragment>
                                    {localizeText(doneValue === 'Replied' ? loc.thankYou : loc.error, this.state.language)}
                                </React.Fragment>, result)}
                        </div>
                    </div>
                    <div className="Done">
                    </div>
                </div>
                : '', done)
            }
            <div className="ReplyPage">
                <div className="Flags">
                    <img src="images/fi-flag.png" alt="Suomi" onClick={() => this.setLanguage('fi')} />
                    <img src="images/sv-flag.png" alt="Svenska" onClick={() => this.setLanguage('sv')} />
                    <img src="images/en-flag.png" alt="English" onClick={() => this.setLanguage('en')} />
                    <img src="images/ee-flag.png" alt="Estonia" onClick={() => this.setLanguage('ee')} />
                </div>
                {!this.getTheme() && <img className="Logo" alt="CauhaCare logo" src={"images/cauhacaresolutions.png"} />}
                {this.getTheme() && <img className="KoutsiLogo" alt="CauhaCare logo" src={"images/mielenkoutsi.png"} />}
                <div>
                    {hasConsent && questionare ? <>
                        { this.renderQuestionare(questionare, null, replies.get())}
                        {U.mapValue(({ left, highlight }) => left ? <>
                            <div className={!highlight ? "Saved" : "SavedHighlight Shake"}>
                                {left}
                            </div>
                        </> : null, leftHighlight)}
                        <div className="SubmitButton">
                            {U.mapValue(title =>
                                <ActionButton onClick={() => {
                                    if (!this.hasUnanswered()) {
                                        this.submitReplies();
                                    } else {
                                        highlight.set(true);
                                        this.setState({ questionare })
                                    }
                                }} title={title} />, localizeText(loc.sendReplies, this.state.language))}
                        </div>
                    </> : ''}
                    {!hasConsent ? this.renderConsent(questionare) : null}
                </div>
            </div>
        </React.Fragment>);
    }
}
