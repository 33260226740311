import * as React from 'react';
import { useState, useEffect } from 'react';
import { TextEdit } from '../_tcomponents/TextEdit';
import { userService } from '../_services/user.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { formatTime, timeFromNow, locKeyStatic, setLanguage, locKeyLang, getLanguage } from '../_helpers/ui';
import { QuestionareReplies } from '../_components/QuestionareReplies';
import { FaEye, FaReply } from 'react-icons/fa';
import { QuestionareTotal } from '../_ui/QuestionareTotal';
import { QuestionareLevel } from '../_components/RunList';
import { OpenToggle } from '../_tcomponents/OpenToggle';
import { MdDone } from 'react-icons/md';
import { ExerciseMaterial } from '../_components/editors/Exercise';
import { ActionButton } from '../_components/BasicElements';
import './User.scss';
import { debounce, LanguageSelector, useEffectUpdate } from '../_tcomponents/Selectors';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Close} from '@material-ui/icons';
import styled from 'styled-components'
import moment from 'moment';
import { Reservation } from '../_tcomponents/Reservation';
import { useSelector } from 'react-redux';

const DialogContent = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    margin:1em;
    margin-right:2.0em;
`

const DialogTitle = styled.div`
    margin-top:1.0em;
    margin-left:1.0em;
    font-size:1.5em;
`

const DialogActivation = styled.div`
    margin-top:2.0em;
    display:grid;
    grid-template-rows: 1fr 5em 2em;
`

const DialogHelp = styled.div`
`

const DialogInput = styled.div`
    margin-top:2.0em;
`

const TwoFactorInput = styled.input`
    margin-right:0.5em;
`

const NoQuestionaresInfo = styled.div`
    margin-top:1.0em;
    margin-bottom:1.0em;
`

const ReservationContainer = styled.div`
    position:relative;
    padding-left:8px;
    padding-right:8px;
    margin:2px;
    min-width:100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid lightgray;
    padding-bottom:10px;
`

const Heading = styled.h4`
    grid-column-start:1;
    grid-column-end:3;
`

const Label = styled.div`
    font-weight:bold;
    font-style:normal;
    font-size:1.0em;
    grid-column: 1;
    margin: 0.3em;
    grid-column-start: 1;
`


const updateMyself = (userValue) => {
    return userService.updateMyself(userValue)
        .catch(() => {
            pushAlert(locKeyStatic("personalInformationUpdateFailed"), 5);
        })
}

const debounceUpdateMyself = debounce(updateMyself, 1000)


const StaticItem = ({ item, edit, setEdit }) => {
    return (<div className='Item'>
        <div className='QuestionareName'>{item.questionare}</div>
        <div className='Result'>
            {item.metrics && item.metrics['total'] > 0 ? <QuestionareTotal percentage={item.metrics['percentage']}
                    total={item.metrics['total']} /> : locKeyStatic("notReplied")}
        </div>
        <span className='Created'>{timeFromNow(item.created)}</span>
        <div className='Functions'>
            {item.metrics && item.metrics['total'] > 0 ?
                    <div className='Edit' onClick={() => setEdit(!edit)}><FaEye /></div> :
                    <div className='Reply' onClick={() => window.open(item.link)}><FaReply /></div>}
        </div>
    </div >)
}

const twoFactorActivate = (setMessage, setDialogOpen) => {
    userService.twoFactorCheck(
        document.getElementById("twoFactorCode").value).then(() => {
            setMessage(locKeyStatic("activationSucceeded"))
            setTimeout(() => { setDialogOpen(false)}, 3000)
        }).catch(() => {
            setMessage(locKeyStatic("activationFailed"))
        })
}

const startExercise = (exercise, setStartedExercises) => {
    const id = exercise.description.id
    userService.startExercise(id).then(() => {
        userService.getStartedExercises().then((exercises) => {
            setStartedExercises(exercises)
        })
    }).catch(() => {
        pushAlert(locKeyStatic("startingExerciseFailed"), 3000)
    })
}

const EditItem = ({ item, edit, setEdit }) => {
    const questionare = item.questionare
    const updatedAt = item.updatedAt
    const parent = item.parent
    return (<div className="EditItem">
        <div className="Label">{locKeyStatic("questionare")}</div><div>{questionare}</div>
        <div className="Label">{locKeyStatic("updated")}</div><div>{formatTime(updatedAt)}</div>
        <div className="ResultLabel">{locKeyStatic("results")}</div><div></div>
        <div className="Replies">
            <QuestionareReplies questionMap={item.questions}/>
        </div>
        <div className="Functions">
            <div />
            <div />
            <div className='Edit' onClick={() => setEdit(!edit)}><FaEye /></div>
        </div>
    </div >)
}

const Item = ({ item }) => {
    const [edit, setEdit] = useState(false)
    return (<>
        {edit ? <EditItem item={item} edit={edit} setEdit={setEdit} /> :
            <StaticItem item={item} edit={edit} setEdit={setEdit} />}
    </>)
}

export const ItemList = ({ items }) => {
    return (<div className='ItemList'>
        <div className='Item'>
            <div className='QuestionareHeading'>{locKeyStatic("questionare")}</div>
            <div className='ResultHeading'>{locKeyStatic("result")}</div>
            <div className='CreatedHeading'>{locKeyStatic("updated")}</div>
            <div className='FunctionsHeading'>{locKeyStatic("functions")}</div>
        </div>
        {items.map(i => <Item item={i} />)}
    </div>)
}

export const Exercise = ({ exercise, setStartedExercises }) => {
    const description = exercise.description
    const title = description.fi
    const text = description.description.fi
    return (<div className='Exercise'>
        <div>
            <h5>{title}</h5>
            <div>{text}</div>
        </div>
        <div>
            <div className="StartButton">
                <ActionButton
                    onClick={() => startExercise(exercise, setStartedExercises)}
                    title={locKeyStatic("start")} />
            </div>
        </div>
    </div >)
}

export const StartedExercise = ({ exercise }) => {
    const title = exercise.description
    const state = exercise.state
    const status = exercise.status
    const description = state.description
    return (<div className='Exercise'>
        <div>
            <h5>{title.fi}</h5>
            <div>{description.fi}</div>
        </div>
        <div>
            {status === 'Handled' ?
                <MdDone className="ReadyText" /> :
                <>
                    <div className="StartButton">
                        <ExerciseMaterial exercise={exercise} />
                    </div>
                </>}
        </div>
    </div >)
}

export const ExerciseList = ({ exercises, startedExercises }) => {
    return (<div className='ExerciseList'>
        {exercises.length === 0 ? locKeyStatic("noExercises") : null}
        {exercises.map(exercise => <Exercise exercise={exercise} startedExercises={startedExercises} />)}
    </div>)
}

export const StartedExerciseList = ({ exercises }) => {
    return (<div className='ExerciseList'>
        {exercises.length === 0 ? locKeyStatic("noExercises") : null}
        {exercises.map(exercise => <StartedExercise exercise={exercise} />)}
    </div>)
}

export const User = ({ user }) => {
    let initialUserObj = {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        language: user.language
    }
    const { organization } = user
    const [items, setItems] = useState([])

    const userState = useSelector((state) => {
        return state.authentication.user
    })

    const [userOpen, setUserOpen] = useState(true)
    const [questionaresOpen, setQuestionaresOpen] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [userObj, setUserObj] = useState(initialUserObj)
    const [twoFactorEnabled, setTwoFactorEnabled] = useState(user.twoFactorEnabled === true || user.twoFactorEnabled === "true")
    const [message, setMessage] = useState("")
    const [twoFactorImageUrl, setTwoFactorImageUrl] = useState("")

    useEffectUpdate(() => {
        debounceUpdateMyself(userObj)
    }, [userObj])

    async function loadInitialState() {
        setItems(await userService.getMyItems())
    }

    useEffect(() => {
        loadInitialState()
    }, [])

    const today = moment()
    return (<div className='MyPage'>
        {userState.rights.indexOf("createMeetings") !== -1 ? <ReservationContainer>
            <Heading>{locKeyStatic("calendar")}</Heading> 
            <Reservation organization={user.adminOrganization}/>
        </ReservationContainer> : null}
        <div className='User'>           
            <h4>{locKeyStatic("personalInformation")}</h4>
            <div className="OpenToggleContainer">
                <OpenToggle open={userOpen} onChange={(open) => setUserOpen(open)} />
            </div>
            {userOpen ? <>
                <div className='Label'>{locKeyStatic("firstName")}</div><TextEdit value={userObj} setValue={setUserObj} member={"firstName"} />
                <div className='Label'>{locKeyStatic("lastName")}</div><TextEdit value={userObj} setValue={setUserObj} member={"lastName"} />
                <div className='Label'>{locKeyStatic("email")}</div><TextEdit value={userObj} setValue={setUserObj} member={"email"} />
                <div className='Label'>{locKeyStatic("phoneNumber")}</div><TextEdit value={userObj} setValue={setUserObj} member={"phoneNumber"} />
                <div className='Label'>{locKeyStatic("language")}</div>
                <div>
                    <LanguageSelector value={userObj.language} setValue={(language) => {setUserObj({...userObj, language })}} />
                </div>
                <div className='Label'>{locKeyStatic("twoFactorAuth")}</div>
                <div>
                    {twoFactorEnabled ?
                        <Button variant="contained" color="primary" onClick={() => {
                            userService.setTwoFactorEnabled(false).then(() => {
                                setTwoFactorEnabled(false)
                            })
                        }}>{locKeyStatic("off")}</Button> :
                        <Button variant="contained" color="primary" onClick={() =>  {
                            userService.setTwoFactorEnabled(true).then((response) => {
                                setTwoFactorImageUrl(response.url)
                                setDialogOpen(true)
                            })
                        }}>{locKeyStatic("on")}</Button>}
                </div>
                <div className='Label'>{locKeyStatic("organization")}</div>
                <div>{organization}</div>
            </> : null}
        </div>
        <div className='Questionares'>           
            <h4>{locKeyStatic("questionares")}<sup> ({items.length} {locKeyStatic("pcs")})</sup></h4>
            <div className="OpenToggleContainer">
                <OpenToggle open={questionaresOpen} onChange={(open) => setQuestionaresOpen(open)} />
            </div>
            {items.length > 0 ? <>
                {questionaresOpen && <ItemList items={items} />}
            </> : <NoQuestionaresInfo>{locKeyStatic("noQuestionaresInfo")}</NoQuestionaresInfo>}
        </div>
        <Dialog open={dialogOpen}>
            <IconButton
            aria-label="close"
            onClick={setDialogOpen.bind(this, false)}
            style={{
                position: 'absolute',
                width: 20,
                height: 20,
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <Close />
            </IconButton>
            <DialogTitle>{locKeyStatic("twoFactorAuth")}</DialogTitle>
            <DialogContent>
                <img src={twoFactorImageUrl} width={256} alt="Two factor" />
                <DialogActivation>
                    <DialogHelp>{locKeyStatic("activationHelp")}</DialogHelp>
                    <DialogInput>
                        <TwoFactorInput type="number" id="twoFactorCode" onChange={() => setMessage("")}/>
                        <button onClick={() => { twoFactorActivate(setMessage, setDialogOpen) }}>{locKeyStatic("activate")}</button>
                    </DialogInput>
                    <p>{message}</p>
                </DialogActivation>
            </DialogContent>
        </Dialog>
    </div>)
}