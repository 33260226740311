import React from 'react';
import { userService } from '../_services/user.service';
import { history, locKeyStatic } from '../_helpers'
import { connect } from 'react-redux'
import { LoginButton } from '../_components/BasicElements';
import './login.scss'

class SendPasswordResetPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
            error: false,
            done: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({ submitted: true });
        const { email } = this.state;
        if (email) {
            userService.sendPasswordReset(email).then(() => {
                this.setState({ done: true })
                setTimeout(() => { history.push('/login') }, 3000)
            }).catch((err) => {
                this.setState({ error: true })
            })
        }
    }

    render() {
        const { email, submitted, error, done } = this.state;
        return (
            <div className="Screen">
                <div className="Background">
                    <h2>{locKeyStatic("resetPassword")}</h2>
                    <img src="images/cauhacaresolutions.png" className="Logo" alt="Cauhacare solutions logo"></img>
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                            <label htmlFor="email">{locKeyStatic("email")}</label>
                            <input type="text" className="form-control" name="email" value={email} onChange={this.handleChange} />
                            {submitted && !email &&
                                <div className="Help">{locKeyStatic("emailNeeded")}</div>
                            }
                            {error &&
                                <div className="Error">{locKeyStatic("sendingInfoFailed")}</div>
                            }
                            {done &&
                                <div className="Done">{locKeyStatic("infoWasSent")}</div>
                            }
                        </div>
                        <div className="form-group">
                            <LoginButton title={locKeyStatic("sendInfo")} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {}
}

const connectedSendPasswordResetPage = connect(mapStateToProps)(SendPasswordResetPage);
export { connectedSendPasswordResetPage as SendPasswordResetPage }; 