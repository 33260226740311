import * as U from 'karet.util';
import { filter } from 'underscore';
const initialState = U.atom({ messages: [] });
export const alertMessages = U.view("messages")(initialState);

export function alerts() {
    setInterval(() => {
        const currTime = Date.now();
        const value = JSON.parse(JSON.stringify(initialState.get()));
        const filteredMessages = filter(value.messages, (msg) => {
            return msg.endTime > currTime;
        });
        if (filteredMessages.length !== value.messages.length) {
            value.messages = filteredMessages;
            initialState.set(value);
        }
    }, 1000);
    return initialState;
}

export function pushAlert(message, duration, className = 'Alert') {
    const value = JSON.parse(JSON.stringify(initialState.get()));
    const endTime = Date.now() + duration * 1000;
    value.messages.push({ endTime, message, className });
    initialState.set(value);
}

export function parseAlertMessage(messageObj) {
    return messageObj.get().message;
}

export function parseEndtime(messageObj) {
    return messageObj.get().endTime;
}

export function parseClassName(messageObj) {
    return messageObj.get().className;
}