import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import * as L from 'partial.lenses';
import { TextEdit } from './TextEdit';
import { FaEdit, FaTrash, FaArrowUp, FaArrowDown, FaCopy } from 'react-icons/fa';
import { OrganizationSelector } from './OrganizationSelector';
import { qService } from '../_services/questionare.service';
import { pushAlert } from '../_reducers/alert.reducer';
import { LanguageTextEdit } from './LanguageTextEdit';
import { ReplyEditor } from './ReplyEditor';
import { questionsRange } from '../_helpers/questions';
import { showYesNoDialog } from '../_ui/Dialog';
import { showLibrarySelector } from './editors/LibrarySelector';
import { Thresholds } from './editors/ThresholdEditor';
import { ConditionEditorOpen } from './editors/ConditionEditor';
import { locKeyStatic, locKey } from '../_helpers/ui';
import { AddButton, ActionButtonWide } from './BasicElements';
import './QuestionareList.scss';
const {v4: uuidv4}=require('uuid');

const createQuestionare = ({ questionares, group, name, organization }) => {
    qService.createQuestionare(organization, group.get(), name.get())
        .then((questionare) => {
            questionares.modify(R.append(questionare))
            group.set('');
            name.set('');
        });
}

const copyQuestionare = ({ questionare, questionares, organization }) => {
    qService.createQuestionare(organization, questionare.group,
        `${questionare.name} - ${locKeyStatic('copy')}`)
        .then((newQuestionare) => {
            newQuestionare.questions = questionare.questions;
            newQuestionare.description = questionare.description;
            newQuestionare.title = questionare.title;
            newQuestionare.thresholds = questionare.thresholds;
            newQuestionare.feedbacks = questionare.feedbacks;
            questionares.modify(R.append(newQuestionare))
            updateQuestionare(newQuestionare)
        });
}

const removeQuestionare = (questionare) => {
    qService.removeQuestionare(questionare.get().id)
        .then(() => questionare.remove())
        .catch(() => pushAlert(locKeyStatic("removeQuestionareFailed"), 5))
}

const showRemoveQuestionare = (questionare) => {
    showYesNoDialog({
        title: locKeyStatic("confirmRemove"),
        message: locKeyStatic("areYouSureRemoveQuestionare", { name: questionare.get().name }),
        parameter: questionare,
        onYes: removeQuestionare
    });
}

const updateQuestionare = (questionareValue) => {
    qService.updateQuestionare(questionareValue).catch(() => {
        pushAlert(locKeyStatic("updateQuestionareFailed"), 5)
    });
}

const addQuestion = (questions) => {
    questions.modify(R.append({ fi: "", en: "", sv: "", ee: "", id: uuidv4(), reply: 'agreeNotAgreeSelect6' }))
}

const addFeedback = (feedbacks) => {
    feedbacks.modify(R.append({ id: uuidv4(), fi: "", en: "", sv: "", ee: "", condition: { type: 'None' } }))
}

const addQuestionFromLibrary = (organizationId, questions) => {
    showLibrarySelector(organizationId, (items) => {
        let newQuestions = []
        const oldQuestions = questions.get()
        for (let question of oldQuestions) {
            if (!items.find(item => item.description.id === question.id)) {
                newQuestions.push(question)
            }
        }
        questions.set(newQuestions)
        questions.modify(R.concat(items.map(item => {
            if (item.key) {
                return {
                    subId: item.uuid,
                    title: { fi: item.key, en: '', sv: '', ee: ''},
                    description: item.description,
                    thresholds: [],
                    questions: item.library.map((i) => i.description)
                }
            }
            return item.description
        })))
    })
}

const addSubQuestionare = (questions) => {
    questions.modify(R.append({
        subId: uuidv4(),
        title: { fi: '', en: '', sv: '', ee: '' },
        description: { fi: '', en: '', sv: '', ee: '' },
        thresholds: [],
        questions: []
    }))
}

const moveUp = (questions, question) => {
    const values = questions.get()
    const q = question.get()
    const index = values.findIndex((currq) => q.id ? q.id === currq.id : q.subId === currq.subId)
    if (index > 0 && index !== -1) {
        questions.modify(R.remove(index - 1, 2))
        questions.modify(R.insert(index - 1, values[index]))
        questions.modify(R.insert(index, values[index - 1]))
    }
}

const moveDown = (questions, question) => {
    const values = questions.get()
    const q = question.get()
    const index = values.findIndex((currq) => q.id ? q.id === currq.id : q.subId === currq.subId)
    if (index !== -1 && index < values.length - 1) {
        questions.modify(R.remove(index, 2))
        questions.modify(R.insert(index, values[index + 1]))
        questions.modify(R.insert(index + 1, values[index]))
    }
}

const StaticQuestionare = ({ organization, questionares, questionare, edit }) => {
    const id = U.view('id')(questionare);
    const group = U.view('group')(questionare);
    const name = U.view('name')(questionare);
    return (<div key={id.get()} className='Questionare'>
        <div className='Group'>{group}</div>
        <div className='Name'>{name}</div>
        <div className='Operations'>
            <div className='Remove' onClick={() => showRemoveQuestionare(questionare)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
            <div className='Copy' onClick={() => copyQuestionare({
                questionare: questionare.get(),
                questionares,
                organization,
            })}><FaCopy /></div>
        </div>
    </div >)
}

const EditSubQuestionare = ({ subQuestionare, questionare, organization }) => {
    const title = U.view('title')(subQuestionare);
    const description = U.view('description')(subQuestionare);
    const thresholds = U.view('thresholds')(subQuestionare);
    const questions = U.view('questions')(subQuestionare);
    const feedbacks = U.view(['feedbacks', L.defaults([])])(subQuestionare);

    const minMaxThreshold = U.atom("")
    questions.observe((qs) => {
        minMaxThreshold.set(questionsRange(qs).join('-'))
    })
    return (<div className='EditQuestionare'>
        <div className='SubHeading'>
            {locKey("modifySubQuestionare")}&nbsp;<ConditionEditorOpen parent={subQuestionare} questionare={questionare} />
            <FaTrash onClick={() => subQuestionare.remove()} className='Remove' />
        </div>
        <div className='Label'>{locKey("title")}</div>
        <div className='Description'>
            <LanguageTextEdit values={title} />
        </div>
        <div className='Label'>{locKey("description")}</div>
        <div className='Description'>
            <LanguageTextEdit values={description} />
        </div>
        <div className='Label'>{locKey("pointLimits")}</div>
        <div className='Thresholds'>
            <Thresholds thresholds={thresholds} minMaxThreshold={minMaxThreshold} />
        </div>
        <div className='Label'>{locKey("questions")}</div>
        <div className='Questions'>
            <QuestionsEdit questions={questions} questionare={questionare} organization={organization} />
        </div>
        <div className='Label'>{locKey("feedbacks")}</div>
        <div className='Feedbacks'>
            <FeedbacksEdit feedbacks={feedbacks} questionare={questionare} organization={organization} />
        </div>
    </div>)
}

const QuestionsEdit = ({ questions, questionare, organization }) => <div className="Questions">
    {U.mapElems((question, index) => {
        const id = U.view('id')(question)
        return (<div key={index}>
            {U.mapValue((value) => value ? <React.Fragment>
                <div className='Heading' key={value.id || value.subId}>
                    {locKey("question")} {index + 1} <ConditionEditorOpen parent={question} questionare={questionare} />
                    <FaTrash onClick={() => question.remove()} className='Remove' />
                    {index > 0 ? <FaArrowUp onClick={() => moveUp(questions, question)} className='MoveUp' /> : ''}
                    <FaArrowDown onClick={() => moveDown(questions, question)} className='MoveDown' />
                </div>
                <hr />
                <LanguageTextEdit values={question} rows={2} />
                <ReplyEditor question={question} organization={organization} />
                <div className="RowGap" />
            </React.Fragment> : <EditSubQuestionare subQuestionare={question} questionare={questionare} organization={organization} />, id)}
        </div>)
    }, questions)}
    <div className="ButtonRow">
        <ActionButtonWide onClick={() =>
            addQuestion(questions)} title={locKey("addQuestion")} />
        <ActionButtonWide onClick={() =>
            addQuestionFromLibrary(organization, questions)} title={locKey("library")} />
        <ActionButtonWide onClick={() => addSubQuestionare(questions)} title={locKey("addSubQuestionare")} />
    </div>
</div>

const FeedbacksEdit = ({ feedbacks, questionare }) => <>
    {U.mapElems((feedback, index) => {
        return (<div className="FeedbackEdit" key={index}>
            <div className={index === 0 ? 'HeadingFirst' : 'Heading'} key={`Feedback${index}`}>
                <span>Palaute {index + 1}<ConditionEditorOpen parent={feedback} questionare={questionare} /></span>
                <FaTrash onClick={() => feedback.remove()} className='Remove' />
            </div>
            <LanguageTextEdit values={feedback} rows={4} />
        </div>)
    }, feedbacks)}
    <ActionButtonWide onClick={() =>
        addFeedback(feedbacks)} title={locKey("addFeedback")} />
</>

const EditQuestionare = ({ questionare, edit }) => {
    const group = U.view('group')(questionare);
    const name = U.view('name')(questionare);
    const title = U.view('title')(questionare);
    const organization = U.view('organization')(questionare);
    const description = U.view('description')(questionare);
    const questions = U.view('questions')(questionare);
    const thresholds = U.view('thresholds')(questionare);
    const feedbacks = U.view(['feedbacks', L.defaults([])])(questionare);
    const minMaxThreshold = U.atom("")
    questions.observe((qs) => {
        minMaxThreshold.set(questionsRange(qs).join('-'))
    })
    return (<div className='EditQuestionare'>
        <h4>{locKey("modifyQuestionare")}</h4>
        <div className='Label'>{locKey("group")}</div><TextEdit value={group} />
        <div className='Label'>{locKey("name")}</div><TextEdit value={name} />
        <div className='Label'>{locKey("organization")}</div>
        <OrganizationSelector organization={organization} />
        <div></div><div></div>
        <div className='Label'>{locKey("title")}</div>
        <div className='Description'>
            <LanguageTextEdit values={title} />
        </div>
        <div className='Label'>{locKey("description")}</div>
        <div className='Description'>
            <LanguageTextEdit values={description} />
        </div>
        <div className='Label'>{locKey("pointLimits")}</div>
        <div className='Thresholds'>
            <Thresholds thresholds={thresholds} minMaxThreshold={minMaxThreshold} />
        </div>
        <div className='Label'>{locKey("questions")}</div>
        <div className='Questions'>
            <QuestionsEdit questions={questions} questionare={questionare} organization={organization} />
        </div>
        <div className='Label'>{locKey("feedbacks")}</div>
        <div className='Feedbacks'>
            <FeedbacksEdit feedbacks={feedbacks} questionare={questionare} />
        </div>
        <div className="Operations">
            <div className='Remove' onClick={() => showRemoveQuestionare(questionare)}><FaTrash /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEdit /></div>
        </div>
    </div >)
}

export const Questionare = ({ organization, questionares, questionare }) => {
    const edit = U.atom(false);
    questionare.skip(1).debounce(1000).observe((questionareValue) => {
        updateQuestionare(questionareValue)
    });
    return (<div>
        {U.mapValue((editValue) => {
            return editValue ?
                <EditQuestionare questionare={questionare} edit={edit} /> :
                <StaticQuestionare questionares={questionares}
                    organization={organization} questionare={questionare} edit={edit} />
        }, edit)}
    </div>)
}

export const QuestionareList = ({ questionaresValue, organization }) => {
    const newName = U.atom("");
    const newGroup = U.atom("");
    const questionares = U.atom(questionaresValue)

    return (<div className='QuestionareList'>
        <div className='Questionare'>
            <div className='GroupHeading'>{locKey("group")}</div>
            <div className='NameHeading'>{locKey("name")}</div>
            <div className='OperationsHeading'>{locKey("functions")}</div>
        </div>
        {U.mapElems((q) => <Questionare key={q.get().id} questionare={q}
            organization={organization} questionares={questionares} />, questionares)}
        <div className='Questionare'>
            <div className='GroupLabel'>{locKey("group")}</div>
            <div className='Label'>{locKey("name")}</div>
            <div className='NewGroup'><TextEdit value={newGroup} /></div>
            <div className='NewName'><TextEdit value={newName} /></div>
            <AddButton onClick={() => createQuestionare(
                { questionares, group: newGroup, name: newName, organization })} />
        </div>
    </div>)
}
