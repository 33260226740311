import * as React from 'karet';
import * as U from 'karet.util';
import { flatten } from 'underscore';
import { orgService } from '../_services/organization.service';
import Select from 'react-select';
import './OrganizationSelector.css';

const customStyles = {
    control: (base, state) => ({
        ...base
    }),
    option: (base, state) => {
        return {
            ...base,
            paddingLeft: state.data.level * 0.5 + 'em',
            fontStyle: state.data.level === 2 ? 'italic' : 'none',
            fontSize: (1.2 - state.data.level * 0.1) + 'em'
        }
    }
};

const treeToList = (root, level) => {
    return flatten([{ value: root.id, label: root.name, level }].concat(
        root.children.map((child) => treeToList(child, level + 1))));
}

export const OrganizationSelector = ({ organization, organizationName }) => {
    const list = U.atom([]);
    orgService.getHome().then((root) => {
        const listValue = treeToList(root, 1);
        list.set(listValue);
    });
    return (<div className='OrganizationSelector'>{U.mapValue((listValue) =>
        <Select styles={customStyles} value={listValue.find((option) => organization.get() === option.value)}
            options={listValue} onChange={(newSelection) => {
                organization.set(newSelection.value);
                if (organizationName) {
                    organizationName.set(newSelection.label)
                }
                list.set(JSON.parse(JSON.stringify(list.get())));
            }} />, list)}</div>);
}