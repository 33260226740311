import * as React from 'karet';
import * as U from 'karet.util';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { evtTarget, locKey } from '../_helpers';
import './TextEdit.css';

export const TextEdit = ({ value, placeHolder }) => {
    return (<input type='text' value={value} placeholder={placeHolder} onChange={(evt) => value.set(evtTarget(evt).value)} />)
}

export const NumberEdit = ({ value }) => {
    return (<input type='number' className='NumberEdit' value={value}
        onChange={(evt) => value.set(parseInt(evtTarget(evt).value))} />)
}

export const NumberEditLimits = ({ value, min, max }) => {
    return (<div className="NumberEditLimits">
        <FaAngleUp />
        <input type='number' className='NumberEdit' min={min} max={max} value={value}
            onChange={(evt) => value.set(evtTarget(evt).value)} />
        <FaAngleDown />
    </div>)
}

export const TextArea = ({ value, rows }) => {
    const enlarged = U.atom(false)
    return (<React.Fragment>{U.mapValue((e) => e ?
        <textarea value={value} rows={rows || 5} onChange={(evt) => value.set(evtTarget(evt).value)}
            onBlur={() => enlarged.set(false)} autoFocus={true} /> :
        <span className='TextAreaStatic' onClick={() => enlarged.set(!enlarged.get())}>
            {U.mapValue((value) => value && value.length > 1 ?
                <div dangerouslySetInnerHTML={{ __html: value }} /> :
                <span className='PlaceHolder'>{locKey("writeHere")}</span>, value)}
        </span>, enlarged)}
    </React.Fragment>)

}