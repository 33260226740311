import React from 'react';
import { userService } from '../_services/user.service';
import { history, locKeyStatic } from '../_helpers';
import { LoginButton } from '../_components/BasicElements';
import './login.scss'
const queryString = require('query-string');

export class ResetPasswordPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordAgain: '',
            submitted: false,
            error: false,
            done: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { password } = this.state;
        const resetHash = queryString.parse(this.props.location.search)["hash"];
        userService.passwordReset(resetHash, password).then(() => {
            this.setState({ done: true })
            setTimeout(() => { history.push('/login') }, 3000)
        }).catch((err) => {
            this.setState({ error: true })
        })
    }

    render() {
        const { password, passwordAgain, submitted, error, done } = this.state;
        const passwordOk = password.length >= 8 &&
            password.match(/[a-z]+/) && password.match(/[A-Z]+/) &&
            password.match(/[0-9]+/)
        const sendOk = passwordOk && password === passwordAgain;
        return (
            <div className="Background">
                <h2>{locKeyStatic("resetPassword")}</h2>
                <img src="images/cauhacaresolutions.png" className="Logo" alt="Cauhacare solutions logo"></img>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div className={'form-group' + (submitted ? ' has-error' : '')}>
                        <label htmlFor="password">{locKeyStatic("password")}</label>
                        <input type="password" className="form-control" name="password"
                            value={password} onChange={this.handleChange} />
                        {(!passwordOk) &&
                            <div className="Help">{locKeyStatic("passwordHelp")}</div>
                        }
                        <label htmlFor="passwordAgain">{locKeyStatic("passwordAgain")}</label>
                        <input type="password" className="form-control" name="passwordAgain"
                            value={passwordAgain} onChange={this.handleChange} />
                        {password !== passwordAgain &&
                            <div className="Help">{locKeyStatic("samePasswordAgain")}</div>
                        }
                        {error &&
                            <div className="Error">{locKeyStatic("resetPasswordFailed")}</div>
                        }
                        {done &&
                            <div className="Done">{locKeyStatic("passwordWasReset")}</div>
                        }
                    </div>
                    <div className="form-group">
                        <LoginButton disabled={!sendOk} title={locKeyStatic("resetPasswordButton")} />
                    </div>
                </form>
            </div>
        );
    }
}
