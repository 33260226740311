
import * as React from 'karet';
import * as U from 'karet.util';
import Moment from 'moment-with-locales-es6';
import { MdError, MdContactPhone, MdRadioButtonUnchecked } from 'react-icons/md'
import loc from "./Localizations.json"
import "./ui.scss"



var initialLang = localStorage.getItem('language') ||
    (navigator.language || navigator.userLanguage).substr(0, 2).toLowerCase()
if (initialLang !== "sv" && initialLang !== "fi" && initialLang !== "en") {
    initialLang = "en"
}

Moment.locale(initialLang)

const _language = U.atom(initialLang)


export const toggleOpenClick = (open, e) => {
    e.preventDefault();
    open.set(!open.get())
}

export const toggleVisibilityStyle = (open) => {
    return U.mapValue((value) => {
        return { display: value ? 'block' : 'none' };
    }, open)
}

export const clone = (value) => {
    return JSON.parse(JSON.stringify(value))
}

export const swapAtomArrayValues = (atom, index1, index2) => {
    atom.modify((values) => {
        if (index1 >= 0 && index1 < values.length && index2 >= 0 && index2 < values.length) {
            const tmp = values[index1];
            values[index1] = values[index2];
            values[index2] = tmp;
        }
    })
}

export const addItemToAtomArray = (atom, item) => {
    const value = clone(atom.get())
    value.push(item);
    atom.set(value);
}

export const localizeText = (value, language) => {
    return U.mapValue((languageValue) => value[languageValue] || value["fi"], language);
}

export const localizeTextWithFormatting = (value, language) => {
    return U.mapValue((languageValue) => {
        const selectedValue = value[languageValue] || value["fi"]
        return selectedValue.split("\\").map((text, index) => {
            return <>
                {index > 0 ? <><br/><br/></> : null}
                <span>{text}</span>
            </>
        })
    }, language);
}

export const formatTime = ((time, defaultValue) => {
    return U.mapValue((timeValue) => timeValue ? Moment(timeValue).local().format("DD.MM.YYYY HH:mm") : defaultValue, time)
})

export const timeFromNow = (time) => {
    return Moment(time).fromNow()
}

export const userItemTypeToIcon = (type) => {
    switch (type) {
        case "Error": return <MdError className="UiError" />
        case "ContactRecommendation": return <MdContactPhone className="UiNotify" />
        default:
            return <MdRadioButtonUnchecked />
    }
}

export const userItemStatusToIcon = (status) => {
    switch (status) {
        case 'New': return locKeyStatic("new");
        case 'Handled': return locKeyStatic("handled");
        default: return '-';
    }
}

export const evtTarget = (e) => {
    return e.srcElement || e.target
}


export const splitBy = (array, f) => {
    if (array.length <= 0) {
        return [[]]
    }
    const groups = []
    let currentGroup = [array[0]]
    let i = 1;
    while (i < array.length) {
        if (f(currentGroup[0]) === f(array[i])) {
            currentGroup.push(array[i])
        } else {
            groups.push(currentGroup)
            currentGroup = [array[i]]
        }
        i++;
    }
    if (currentGroup.length > 0) {
        groups.push(currentGroup)
    }
    return groups
}

export const formatStatus = (status) => {
    return U.mapValue((value) => {
        if (value === 'Created') {
            return locKeyStatic("created");
        } else if (value === 'Sent') {
            return locKeyStatic("sent");
        } else if (value === 'Replied') {
            return locKeyStatic("replied");
        }
        return locKeyStatic("ready")
    }, status)
}

export function setQuestionRepliesFromItem(questionMap, item) {
    for (let i in questionMap.questions) {
        if (questionMap.questions[i].subId) {
            setQuestionRepliesFromItem(questionMap.questions[i], item)
        }
        else {
            const id = questionMap.questions[i].id
            questionMap.questions[i].replies = [item.replies.find((i) => i.id === id).value]
        }
    }
}

export function setLanguage(language) {
    _language.set(language)
    Moment.locale(language)
    localStorage.setItem('language', language)
}

export function getLanguage() {
    return _language.get()
}

export function locText(value) {
    return <>{U.mapValue((languageValue) => nonEmpty(value[languageValue], value["fi"]), _language)}</>
}


export function locKey(key) {
    if (!loc[key])
        return key
    return locText(nonEmpty(loc[key], loc['fi']))
}

export function locKeyStatic(key, replacements) {
    if (!loc[key])
        return key

    const lang = _language.get()
    var text = loc[key][lang] || loc[key]['fi']
    for (const replaceKey in replacements) {
        text = text.replace("{" + replaceKey + "}", replacements[replaceKey])
    }
    return text
}

export function optionLocKey(value, key, current) {
    if (current) {
        return (<option value={value} selected={current.get() === value}>{U.mapValue((languageValue) => loc[key][languageValue], _language)}</option>)
    }
    return (<option value={value}>{U.mapValue((languageValue) => loc[key][languageValue], _language)}</option>)
}

export function locKeyPlain(key) {
    return U.mapValue((languageValue) => loc[key] ? loc[key][languageValue] : key, _language)
}

export function locFunc(func) {
    return U.mapValue(languageValue => func(languageValue), _language)
}
export function getDefaultLanguage() {
    return _language.get()
}

export function locTextDyn(text) {
    const both = U.molecule({ text, lang: _language })
    return U.mapValue(({ text, lang }) => text ? nonEmpty(text[lang], text["fi"]) : "", both)
}

function nonEmpty(value1, value2) {
    return !value1 || value1 === "" ? value2 : value1
}

export function locKeyLang(key, language) {
    return loc[key][language]
}