import * as React from 'react';
import { AppBar, Box, Toolbar, Container, MenuItem, Menu,
    IconButton, Button, Avatar } from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons'
import { useHistory } from 'react-router-dom';
import { userService } from '../_services/user.service';
import { locKeyLang } from '../_helpers/ui'

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    return {
        sx: {
        bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function MenuAppBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory()
  const pages = [
    {right: 'admin', link: '/',  label: 'Organizations'},
    {right: 'runAccess', link: '/replies',  label: 'resultsWord'},
    {right: 'individualRights', link: '/individuals',  label: 'individualResults'},
    {right: 'admin', link: '/events',  label: 'eventsWord'},
    {right: 'libraryManagement', link: '/library',  label: 'libraryWord'},
    {right: 'admin', link: '/system',  label: 'system'},
    {right: 'reserveMeetings', link: '/calendar', label: 'calendar'},
    {right: 'reserveMeetings', link: '/calendar-report', label: 'salesReport'}
  ]

  const { user } = props
  const language = user ? user.language : 'fi'
  return (
    <AppBar position="static">
        <Container maxWidth="xl" style={{backgroundColor: '#387665'}}>
            <Toolbar disableGutters>
                <IconButton edge="start" color="inherit" sx={{ mr: 2 }}>
                    <img width="120" src="/images/cauhacaresolutions.png" />   
                </IconButton>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent="flex-end" marginRight="2.0em">
                    {pages.filter(p => !p.right || user.rights.indexOf(p.right) !== -1).map((p) => (
                    <Button onClick={() => history.push(p.link)} key={p.link} sx={{ my: 2, display: 'block' }}
                        style={{color:'white'}}>
                        {locKeyLang(p.label, language)}
                    </Button>
                    ))}
                </Box>
                <div>
                    <IconButton
                        onClick={handleMenu}
                        color="inherit">
                        <Avatar {...stringAvatar(user.firstName + " " + user.lastName)} />
                    </IconButton>
                    <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    >
                    <MenuItem onClick={() => {handleClose(); history.push('/mypage')}}>{locKeyLang('mypage', language)}</MenuItem>
                    <MenuItem onClick={() => {
                        handleClose();
                        userService.logout().then(() => {
                            window.location.href="/"
                        })
                    }}>{locKeyLang('logout', language)}</MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </Container>
    </AppBar>);
}