import * as React from 'karet';
import * as U from 'karet.util';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Button, TextField } from '@material-ui/core';
import { ExitToApp, Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import loc from './Localizations.json'
import { locText, locKey } from '../_helpers/ui.js';
import './BasicElements.scss'

const useStyles = makeStyles((theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
        leave: {
            margin: theme.spacing(1),
            fontSize: "0.5em",
            width: "13.0em"
        },
        add: {
            fontSize: "0.8em"
        },
        smallAdd: {
            display: "inline-block",
            padding: 0,
            minHeight: 0,
            minWidth: 0,
        },
        textField: {
            width: 200,
        },
    }),
);

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);


export const TaskCheckbox = ({ checked }) => {
    return <>{U.mapValue(value =>
        <GreenCheckbox icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
            checkedIcon={<CheckBoxIcon fontSize="large" />}
            checked={value}
            onChange={() => checked.set(!value)} />, checked)}</>
}

const ActionButtonStyle = withStyles({
    root: {
        color: 'white',
        fontSize: '0.8em',
        backgroundColor: '#54ad95',
        borderRadius: '15px'
    },
})(Button);

const ActionButtonStyleWide = withStyles({
    root: {
        borderRadius: '5px',
        fontSize: '0.8em',
        backgroundColor: '#54ad95',
        '&:hover': {
            backgroundColor: 'green'
        },
        '&:active': {
            backgroundColor: 'green'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        width: "18em"
    },
})(Button);

const LoginButtonStyle = withStyles({
    root: {
        fontSize: '0.8em',
        border: "1px solid white",
        borderRadius: "5px",
        backgroundColor: 'transparent'
    },
})(Button);

const LogoutButtonStyle = withStyles({
    root: {
        marginLeft:'1.0em',
        fontSize: '0.8em',
        border: "1px solid white",
        borderRadius: "15px",
        backgroundColor: 'transparent',
    },
})(Button);

const CloseButtonStyle = withStyles({
    root: {
        fontSize: '0.8em',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red'
        },
        '&:active': {
            backgroundColor: 'red'
        }
    },
})(Button);


export const ActionButton = ({ onClick, title, className, disabled }) =>
    <ActionButtonStyle variant="contained" color="primary" className={className} disabled={disabled}
        onClick={() => onClick()}>
        {title}
    </ActionButtonStyle>

export const ActionButtonWide = ({ onClick, title, className, disabled }) =>
    <ActionButtonStyleWide variant="contained" color="primary" className={className}
        onClick={() => onClick()}>
        {title}
    </ActionButtonStyleWide>

export const CloseButton = ({ onClick, title }) =>
    <CloseButtonStyle variant="contained" color="secondary"
        onClick={() => onClick()}>
        {title}
    </CloseButtonStyle>

export const LeaveButton = ({ onClick }) => {
    const classes = useStyles();
    return <LogoutButtonStyle variant="contained" color="primary" className={classes.leave} size="small"
        onClick={() => onClick()} endIcon={<ExitToApp />}>
        {locText(loc.logout)}
    </LogoutButtonStyle>
}

export const AddButton = ({ onClick, className, title }) => {
    return (
        <ActionButtonStyle size="small" className={className} variant="contained" aria-label="add" onClick={() => onClick()}>
            {title || locKey("add")}
        </ActionButtonStyle >)
}

export const EditButton = ({ onClick, className }) => {
    return (
        <Button color="primary" size="small" className={className} variant="contained" startIcon={<Edit />} aria-label="add" onClick={() => onClick()}>
            {locKey("modify")}
        </Button >)
}

export const SmallAddButton = ({ onClick }) => {
    return (
        <button className="SmallAddButton" onClick={() => onClick()}>
            <AddIcon />
        </button>)
}

export const LoginButton = ({ title }) =>
    <LoginButtonStyle variant="contained" color="primary"
        type="submit">
        {title}
    </LoginButtonStyle>


export const LabelTextEdit = ({ label, value }) => {
    const classes = useStyles();
    return (<>
        {U.mapValue(currentValue => <TextField
            label={label}
            className={classes.textField}
            value={currentValue}
            onChange={(evt) => value.set(evt.target.value)}
            margin="normal"
        />, value)}
    </>)
}