import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { TextEdit } from '../TextEdit';
import { FaTrash, FaCircle } from 'react-icons/fa';
import { LanguageTextEdit } from '../LanguageTextEdit';
import { ColorSelector } from '../Selectors.js';
import { locKey } from '../../_helpers';
import { EditButton, AddButton, CloseButton, ActionButton } from '../BasicElements';
import "./ThresholdEditor.scss"

const _visible = U.atom(false)
const _thresholds = U.atom([])
const _minMaxThreshold = U.atom(null)
const _onSet = U.atom(null)

const addThreshold = (thresholds) => {
    thresholds.modify(R.append({ fi: "", en: "", sv: "", value: 1, color: 'rgb(255,255,0)' }))
}

export const ThresholdEditor = () => {
    return (<React.Fragment>
        {U.mapValue(visibleValue => visibleValue ?
            <div className="ThresholdEditor">
                <div className="TopBar">
                    <h4>{locKey("setThresholds")}</h4>
                </div>
                <div className="ContentBar">
                    <div className="ContentColumn">
                        <div className="Thresholds">
                            {U.mapElems((threshold, index) =>
                                <>
                                    <div className='Heading'>
                                        {locKey("limit")} {index + 1}
                                        <FaTrash onClick={() => threshold.remove()} className='Remove' />
                                    </div>
                                    <hr />
                                    <LanguageTextEdit values={threshold} rows={2} />
                                    <div className='Inputs'>
                                        <div className='ColorLabel'>{locKey("color")}</div>
                                        <div className='ColorLabel'>{locKey("value")}({_minMaxThreshold})</div>
                                        <ColorSelector value={U.view("color")(threshold)} />
                                        <TextEdit value={U.view("value")(threshold)} />
                                    </div>
                                    <br />
                                </>, _thresholds)}
                            <div className="Add">
                                <AddButton onClick={() => addThreshold(_thresholds)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="BottomBar">
                    <div></div>
                    <CloseButton onClick={() => _visible.set(false)} title={locKey("cancel")} />
                    <ActionButton onClick={() => _onSet.get()()} title={locKey("setLimits")} />
                </div>
            </div>
            : null, _visible)}
    </React.Fragment>)
}

export const Thresholds = ({ thresholds, minMaxThreshold }) => {
    return (<div className="Thresholds">{U.mapElems((t, index) =>
        <div className="Threshold" key={index}>
            {U.mapValue((color) => <FaCircle key={color} style={{ color }} />, U.view("color")(t))}
            <div>{U.view("value")(t)}</div>
            <div>{U.view("fi")(t)}</div>
        </div>, thresholds)
    }
        <EditButton onClick={() => showThresholdEditor(thresholds, minMaxThreshold)} />
    </div >)
}

export const showThresholdEditor = (thresholds, minMaxThreshold) => {
    _minMaxThreshold.set(minMaxThreshold.get())
    _thresholds.set(thresholds.get())
    _visible.set(true)
    _onSet.set(() => { thresholds.set(_thresholds.get()); _visible.set(false) })
}