import { apiUrl } from './common';

function getOrganizationCircles(id) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/circle/${id}`, requestOptions)
        .then(handleResponse)
}

function createCircle(organization, uuid, key, parentUuid) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ uuid, parentUuid, key, organization, description: { fi: "", en: "", sv: "" } })
    };
    return fetch(`${apiUrl}/circle`, requestOptions)
        .then(handleResponse)
}

function updateCircle(circle) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(circle)
    };
    return fetch(`${apiUrl}/circle`, requestOptions)
        .then(handleResponse)
}

function removeCircle(uuid) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/circle/${uuid}`, requestOptions)
        .then(handleResponse)
}

function removeLibraryItem(uuid) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    };
    return fetch(`${apiUrl}/libraryitem/${uuid}`, requestOptions)
        .then(handleResponse)
}

function createLibraryItem(type, organization, description, parentCircle) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            uuid: description.id,
            organization: organization.get().id,
            type,
            parentCircle,
            childCircle: null,
            description
        })
    };
    return fetch(`${apiUrl}/libraryitem`, requestOptions)
        .then(handleResponse)
}

function updateLibraryItem(item) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(item)
    };
    return fetch(`${apiUrl}/libraryitem`, requestOptions)
        .then(handleResponse)
}

function handleResponse(response) {
    return response.text().then(text => {
        if (response.status !== 200) {
            throw response.status
        }
        const data = (text && text.length > 0) && JSON.parse(text);
        return data;
    });
}

function getCircleLibraryItems(uuid) {
    const requestOptions = {
        method: 'GET'
    };
    return fetch(`${apiUrl}/libraryitem/circle/${uuid}`, requestOptions)
        .then(handleResponse)
}

function searchLibraryItems(organizationId, search, type) {
    const requestOptions = {
        method: 'GET'
    }
    return fetch(`${apiUrl}/libraryitem/${organizationId}/search/tree?search=${search}&type=${type}`, requestOptions)
        .then(handleResponse)
}

export const libraryService = {
    getOrganizationCircles,
    createCircle,
    removeCircle,
    createLibraryItem,
    getCircleLibraryItems,
    removeLibraryItem,
    updateCircle,
    updateLibraryItem,
    searchLibraryItems
};
