import * as React from 'karet';
import * as U from 'karet.util';
import { runService } from '../_services/run.service';
import { formatTime, timeFromNow, locKeyStatic, locTextDyn } from '../_helpers/ui';
import { QuestionareReplies } from './QuestionareReplies';
import { FaCheck, FaEye, FaMailBulk, FaOpencart, FaSort, FaSortDown, FaSortUp, FaStar, FaWalking } from 'react-icons/fa';
import { DropDown } from './DropDown';
import { Paging } from '../_ui/Paging';
import moment from 'moment-with-locales-es6';
import { QuestionareSelect } from './QuestionareSelector';
import './ItemList.scss';
import { ResultBar, ResultBarChange, ResultBarPercentage } from '../_helpers/visuals';
import { HandlingStatusSelector } from './Selectors';

const StaticItem = ({ item, edit }) => {
    const firstName = U.view('firstName')(item);
    const lastName = U.view('lastName')(item);
    const questionare = U.view('questionareName')(item);
    const created = U.view('updatedAt')(item);
    const total = U.view('total')(item);
    const totalChange = U.view('totalChange')(item);
    const totalChangePercentage = U.view('totalChangePercentage')(item);
    const percentage = U.view('percentage')(item);
    const normPercentage = U.view('normPercentage')(item);
    const handlingStatus = U.view('handlingStatus')(item);
    const id = U.view('id')(item)
    return (<div key={id} className='Item'>
        <div key="name" className='Name'>{firstName}&nbsp;{lastName}</div>
        <div key="questionareName" className='QuestionareName'>{locTextDyn(questionare)}</div>
        <div key="result" className='Result'>
            {U.mapValue((value) =>
                value > 0 ?
                    <ResultBar percentage={percentage.get()} total={value}
                        normPercentage={normPercentage.get()} /> : '-', total)}
        </div>
        <div key="resultChange">
            {U.mapValue(change => change != null ? <ResultBarChange percentage={change} change={totalChange.get()} /> : null, totalChangePercentage)}
        </div>
        <div key="created" className='Created'>{U.mapValue((time) => timeFromNow(time), created)}</div>
        <div key="handlingStatus" className='HandlingStatus'>
            {U.mapValue(value => {
                switch (value) {
                    case 'New':
                        return <FaStar />
                    case 'Open':
                        return <FaOpencart />
                    case 'Progress':
                        return <FaWalking />
                    default:
                        return <FaCheck />
                }
            }, handlingStatus)}
        </div>
        <div key="functions" className='Functions'>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEye /></div>
        </div>
    </div >)
}

const Questionares = ({ questionares }) => {
    return (<React.Fragment>{U.mapElems((questionare, index) =>
        <React.Fragment>
            {U.mapValue((qval) => {
                return <DropDown className="ReplyDropDown"
                    title={`${qval.name} (${moment(qval.updatedAt).format('DD.MM.YYYY')}, tulos ${qval.metrics.total})`}
                    initial={index === 0}>
                    <QuestionareReplies questionMap={U.view('questions')(questionare)}
                        averages={U.view('averages')(questionare)} />
                </DropDown>
            }, questionare)
            }
        </React.Fragment>, questionares)}
    </React.Fragment>)
}

const EditItem = ({ item, organizationId, edit }) => {
    const questionare = U.atom("");
    const updatedAt = U.view("updatedAt")(item);
    const firstName = U.view('firstName')(item);
    const lastName = U.view('lastName')(item);
    const total = U.view('total')(item);
    const totalPercentage = U.view('percentage')(item);
    const normPercentage = U.view('normPercentage')(item);
    const handlingStatus = U.view('handlingStatus')(item);
    const phoneNumber = U.atom("")
    const email = U.atom("")
    const id = U.view('id')(item);
    const questionares = U.atom([])
    runService.getRunItem(organizationId, item.get().itemId).then((fullItem) => {
        questionare.set(fullItem.questionares[0].name)
        phoneNumber.set(fullItem.phoneNumber)
        email.set(fullItem.email)
        questionares.set(fullItem.questionares);
    })
    handlingStatus.skip(1).observe(value => {
        runService.updateItemHandlingStatus(item.get().itemId, value)
    })
    return (<div key={id} className="EditItem">
        <h4>{firstName}&nbsp;{lastName}</h4>
        <div className="Label">{locKeyStatic("result")}</div>
        {U.mapValue(p =>
            <div className="ResultLabel">
                <ResultBar percentage={p} total={total.get()} marginTop={'-0.3em'} />
            </div>, totalPercentage)}
        {U.mapValue(p => p ?
            <React.Fragment>
                <div className="Label">{locKeyStatic("resultNorm")}</div>
                <div className="ResultLabel"><ResultBarPercentage percentage={p} marginTop={'-0.3em'} /></div>
            </React.Fragment> : null, normPercentage)}
        <div className="Label">{locKeyStatic("email")}</div>
        <div className="ResultLabel">
            {U.mapValue(value => <a href={"mailto:" + value}>{value}</a>, email)}
        </div>
        <div className="Label">{locKeyStatic("phoneNumber")}</div>
        <div className="ResultLabel">
            {U.mapValue(value => <a href={"tel:" + value}>{value}</a>, phoneNumber)}
        </div>
        <div className="Label">{locKeyStatic("questionare")}</div><div>
            {questionare}
        </div>
        <div className="Label">{locKeyStatic("status")}</div>
        <div className="ResultLabel">
            <HandlingStatusSelector value={handlingStatus} />
        </div>
        <div className="Label">{locKeyStatic("replied")}</div><div>{formatTime(updatedAt)}</div>
        <div className="ResultLabel">{locKeyStatic("resultsText")}</div>
        <div className="Replies">
            <Questionares questionares={questionares} />
        </div>
        <div className="Functions" >
            <div />
            <div className='Invite' onClick={() => alert()}><FaMailBulk /></div>
            <div className='Edit' onClick={() => edit.set(!edit.get())}><FaEye /></div>
        </div>
    </div >)
}

const Item = ({ organizationId, item }) => {
    const edit = U.atom(false);
    return (<>
        {U.mapValue((editValue) => editValue ?
            <EditItem organizationId={organizationId} item={item} edit={edit} /> :
            <StaticItem organizationId={organizationId} item={item} edit={edit} />, edit)}
    </>)
}

const fetch = (organizationId, sort, questionare, subQuestionare, runId, group, loading, start, count, target) => {
    loading.set(true)
    runService.getMatrixOrganizationItems(organizationId, sort.get(),
        questionare.get(), subQuestionare.get(),
        runId.get(), group.get(),
        start, count).then((itemsValue) => {
            loading.set(false)
            target.set(itemsValue)
        }).catch(() => {
            loading.set(false)
        })
}

const SortColumn = ({ sort, column, title, className }) => <div className={className} onClick={() => {
    if (sort.get().column === column) {
        sort.set({ column, direction: sort.get().direction === "asc" ? "desc" : "asc" })
    } else {
        sort.set({ column, direction: "asc" })
    }
}}>
    {U.mapValue(value => value.column !== column ? <FaSort /> :
        value.direction === "asc" ? <FaSortUp /> : <FaSortDown />, sort)}
    <span>{title}</span>
</div>

export const ItemList = ({ organizationId, runId, group }) => {
    const itemsWrapper = U.atom({ length: 0, items: [] })
    const items = U.view('items')(itemsWrapper)
    const start = U.atom(0)
    const count = U.atom(20)
    const sort = U.atom({ column: 'updatedAt', direction: 'desc' })
    const questionare = U.atom(null)
    const subQuestionare = U.atom(null)
    const loading = U.atom(false)

    return (<div key={"itemList"} className='ItemListMatrix'>
        <div key={'heading'} className='Item'>
            <SortColumn sort={sort} className="NameHeading" column="firstName" title={locKeyStatic("name")} />
            <div className='QuestionareHeading'>
                <QuestionareSelect onSelect={(value) => {
                    if (value && value.length < 10) {
                        subQuestionare.set(null)
                        questionare.set(parseInt(value))
                    } else if (value) {
                        subQuestionare.set(value)
                        questionare.set(null)
                    } else {
                        subQuestionare.set(null)
                        questionare.set(null)
                    }
                }} organization={organizationId} />
            </div>
            <SortColumn sort={sort} className="ResultHeading" column="total" title={locKeyStatic("result")} />
            <SortColumn sort={sort} className="ResultChangeHeading" column="totalChange" title={"+/-"} />
            <SortColumn sort={sort} className="CreatedHeading" column="updatedAt" title={locKeyStatic("replied")} />
            <div className="HandlingStatusHeading">{locKeyStatic("status")}</div>
            <div className='FunctionsHeading'>{locKeyStatic("functions")}</div>
        </div>
        {U.mapValue(value => value ? <div className="Loader" /> : null, loading)}
        {U.mapElems((i, index) => <Item key={index} organizationId={organizationId} item={i} />, items)}
        <Paging start={start} count={count} other={U.molecule({ sort, questionare, subQuestionare, runId })}
            fetch={fetch.bind(this, organizationId, sort, questionare, subQuestionare, runId, group, loading)}
            target={itemsWrapper} />
    </div >)
}
