import { TextField } from '@material-ui/core';
import * as React from 'react';
import { useState, Fragment } from 'react';
import { FaAngleUp, FaAngleDown, FaEdit } from 'react-icons/fa';
import { evtTarget, locKey } from '../_helpers';

export const TextEdit = ({ value, setValue, placeHolder, member, multiline, columns, rows }) => {
    return (<TextField value={value[member]} placeholder={placeHolder} multiline={multiline} style={{minWidth:`${columns}em`}} rows={rows} variant="outlined" size="small"
        onChange={(evt) => setValue({...value, [member]: evtTarget(evt).value})} />)
}

export const TextEditSingle = ({ value, setValue, placeHolder, style }) => {
    return (<TextField value={value} style={style} placeholder={placeHolder} variant="outlined" size="small"
        onChange={(evt) => setValue(evtTarget(evt).value)} />)
}
export const NumberEdit = ({ value, setValue, member, step }) => {
    return (<TextField type='number' size="small" className='NumberEdit' style={{minWidth:'6em'}} inputProps={{step}} value={value[member]} variant="outlined"
        onChange={(evt) => setValue({...value, [member]: parseInt(evtTarget(evt).value)})} />)
}

export const TimeEdit = ({ value, setValue, member }) => {
    return (<TextField type='time' size="small" className='NumberEdit' value={value[member]} variant="outlined"
        onChange={(evt) => setValue({...value, [member]: parseInt(evtTarget(evt).value)})} />)
}

export const NumberEditLimits = ({ value, setValue, min, max }) => {
    return (<div className="NumberEditLimits">
        <FaAngleUp />
        <input type='number' className='NumberEdit' min={min} max={max} value={value}
            onChange={(evt) => setValue(evtTarget(evt).value)} />
        <FaAngleDown />
    </div>)
}

export const TextArea = ({ value, setValue, rows }) => {
    const [enlarged, setEnlarged] = useState(false)
    return (<Fragment>{U.mapValue((e) => e ?
        <textarea value={value} rows={rows || 5} onChange={(evt) => setValue(evtTarget(evt).value)}
            onBlur={() => setEnlarged(false)} autoFocus={true} /> :
        <span className='TextAreaStatic' onClick={() => setEnlarged(!enlarged)}>
            {value && value.length > 1 ?
                <div dangerouslySetInnerHTML={{ __html: value }} /> :
                <span className='PlaceHolder'>{locKey("writeHere")}</span>}
        </span>, enlarged)}
    </Fragment>)

}

export const EditableTextField = ({ value, setValue, member }) => {
    const [edit, setEdit] = useState(false)
    const [text, setText] = useState(value[member])

    React.useEffect(() => {
        setText(value[member])
    }, [value])

    const setEditUpdate = (edit) => {
        if (value !== text) {
            setValue({...value, [member]: text})
        }
        setEdit(edit)
    }
    
    return (<span className="EditTextField">{edit ?
        <input value={text} onKeyUp={(evt) => { if (evt.keyCode === 13) {
            setEdit(false)
            setValue({...value, [member]: text})
        }}}
            onBlur={() => setEditUpdate(false)}
            autoFocus={true}
            onChange={(evt) => setText(evt.target.value)} /> :
        <span onClick={() => setEdit(true)}>{text}</span>}
        <FaEdit className='Edit' onClick={() => {
            setEditUpdate(!edit)
        }} />
    </span>)
}