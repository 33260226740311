import * as React from 'karet';
import * as U from 'karet.util';
import { connect } from 'react-redux';
import { orgService } from '../_services/organization.service';
import { OrganizationTree } from '../_components/OrganizationTree';
import { ProtectedPage } from '../_components/ProtectedPage';
import { RunList } from '../_components/RunList';
import { DropDown } from '../_components/DropDown';
import { locKey, locKeyPlain } from '../_helpers/ui';
import './RepliesPage.scss';

class RepliesPage extends ProtectedPage {
    constructor() {
        super()
        this.state = {
            home: U.atom(null),
            selected: U.atom(null)
        }
    }

    UNSAFE_componentWillMount() {
        super.UNSAFE_componentWillMount()
        if (!this.state.home.get()) {
            this.getHome();
        }
    }

    UNSAFE_componentWillUpdate() {
        super.UNSAFE_componentWillUpdate()
        if (!this.state.home.get()) {
            this.getHome();
        }
    }

    getHome() {
        orgService.getHome().then((home) => {
            this.state.home.set(home)
            return orgService.getOrganization(home.id)
        }).then((org) => {
            if (!this.state.selected.get()) {
                this.state.selected.set(org);
            }
        }).catch(() => {
        })
    }

    render() {
        const { home, selected } = this.state;
        const name = U.view('name')(selected);
        return (
            <div className="RepliesPage">
                {
                    U.mapValue((value) => value ? <OrganizationTree className='OrganizationTree'
                        heading={locKey('Organizations')}
                        root={home}
                        selected={selected} /> : '', home)
                }
                <div className="ResultPage">
                    {U.mapValue((selectedValue) => selectedValue ?
                        <React.Fragment>
                            <h3>{name}</h3>
                            <DropDown title={locKeyPlain("questionares")} initial={true}>
                                <RunList organizationId={selectedValue.id} organization={selected} />
                            </DropDown>
                        </React.Fragment> : '', selected)}
                </div>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return { user: state.authentication.user }
}

const connectedRepliesPage = connect(mapStateToProps)(RepliesPage);
export { connectedRepliesPage as RepliesPage }; 